import React, { useCallback } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import Modal from "react-native-modal"
import Icon from "react-native-vector-icons/AntDesign"
import {
  CreditCardInput,
  SquarePaymentsForm,
} from "react-square-web-payments-sdk"

import { Firebase } from "../../base"

async function init() {}

async function showCardCapture() {
  return { status: "cancelled" }
}

function addUpdateCard(callback) {
  try {
  } catch (error) {
    console.log("CARD FLOW ERROR:", error)
    callback("error", error)
  }
}

function showError(message) {}

async function runPayment(price, summaryLabel) {
  await new Promise(res => setTimeout(res, 1200))
  return { error: new Error("Payment not implemented yet"), result: null }
}

const WebPaymentForm = ({ onClose, onAddCard }) => {
  const squareAppId = Firebase.getConfigValue("square_app_id")
  const squareLocationId = Firebase.getConfigValue("square_location_id")

  const handleResponse = useCallback(
    result => {
      try {
        if (result.status === "OK") {
          onAddCard?.("complete", { nonce: result.token })
        } else {
          onAddCard?.("error")
        }
      } catch (error) {
        console.log("card error", { error })
        onAddCard?.("error")
      }
    },
    [onAddCard],
  )

  return (
    <Modal isVisible>
      <View style={styles.centeredView}>
        <View style={styles.wepPaymentModalContent}>
          <TouchableOpacity onPress={onClose} style={styles.closeButton}>
            <Icon name="close" size={25} />
          </TouchableOpacity>
          <SquarePaymentsForm
            applicationId={squareAppId}
            cardTokenizeResponseReceived={handleResponse}
            locationId={squareLocationId}
          >
            <CreditCardInput text="Add" />
          </SquarePaymentsForm>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  wepPaymentModalContent: {
    backgroundColor: "white",
    padding: 30,
    paddingTop: 45,
    borderRadius: 10,
    maxWidth: 500,
    width: "85%",
  },
  centeredView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    alignItems: "center",
    justifyContent: "center",
  },
})

export default {
  init,
  showCardCapture,
  runPayment,
  addUpdateCard,
  showError,
  WebPaymentForm,
}
