import React from "react"
import { Image, StyleSheet, Text, View } from "react-native"

import Base from "../../../base"

export default function EXTastingNotesRow(props) {
  return (
    <View style={styles.main}>
      <View style={styles.iconContainer}>
        <Image
          source={
            props.icon ||
            require("../../../assets/images/tastingnotes/tastingnotes_plus.png")
          }
          style={styles.icon}
        />
      </View>
      <Text style={styles.title}>{props.text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  iconContainer: {
    alignItems: "flex-start",
  },

  icon: {
    width: 22,
    height: 22,
    resizeMode: "contain",
  },

  title: {
    maxWidth: "100%",
    textAlign: "left",
    fontFamily: Base.fonts.appRegular,
    fontSize: 15,
    color: Base.colours.appBrown,
    marginHorizontal: 16,
  },
})
