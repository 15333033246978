import React from "react"
import { Modal, StyleSheet, View } from "react-native"

import Base from "../../base"

export default function ModalWindow({ children, isVisible, onDismiss }) {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>{children}</View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    backgroundColor: Base.colours.appWhite,
    width: "86%",
    maxWidth: 400,
    padding: 24,
    paddingTop: 24,
    borderRadius: 30,
  },
})
