import axios from "axios"
import moment from "moment"

import { Contentful } from "../../../base"
import LocalStorage from "../../../modules/local-storage"

/**
 *  API HTTP client to perform requests to backend.
 *  Uses axios as HTTP client.
 *  Authorization provides through the JWT token sets to an Authorization header with Bearer method.
 */
export class SubscriptionApi {
  constructor({ baseURL, getToken }) {
    this.apiClient = axios.create({
      baseURL,
      responseType: "json",
      headers: { "Content-Type": "application/json" },
      timeout: 1000 * 25, // 25 sec
    })

    this.apiClient.interceptors.request.use(async preRequestConfig => {
      const { _tokenRequired } = preRequestConfig

      const token = await getToken()
      if (token) {
        preRequestConfig.headers.authorization = "Bearer " + token
      } else if (_tokenRequired && !token) {
        // throw new axios.Cancel("Unauthorized")
      }

      return preRequestConfig
    })

    this.apiClient.interceptors.response.use(
      response => response.data, // extract retrieved data.
      error => {
        // console.warn("Subscription API error", error?.response)
        return null // Promise.reject(error)
      },
    )
  }

  updateBaseUrl = url => {
    this.apiClient.defaults.baseURL = url
  }

  /** Finds ID of current subscriber that can be stored in different places regarding of user authorization status. */
  async getSubscriberId() {
    // Try to retrieve from local storage..
    const maybeSubscriberId = await LocalStorage.getItem("SUBSCRIBER_ID")
    if (maybeSubscriberId) {
      return maybeSubscriberId
    } else {
      // Try to retrieve from API as logged in user.
      let maybeGottenSubscriber
      try {
        const subscriber = await this.getSubscriber()
        maybeGottenSubscriber = subscriber._id
      } catch (error) {}

      if (maybeGottenSubscriber) {
        await LocalStorage.setItem("SUBSCRIBER_ID", maybeGottenSubscriber)
        return maybeGottenSubscriber
      } else {
        // Try to create on API us guest.
        const createdSubscriber = await this.createSubscriber()
        await LocalStorage.setItem("SUBSCRIBER_ID", createdSubscriber._id)
        return createdSubscriber._id
      }
    }
  }

  async getListOfQuestions() {
    return this.apiClient.get("/questions")
  }
  async putSubscriberAnswers(answers) {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.put(`/questions/${subscriberId}`, answers)
  }

  async createSubscriber(subscriberDto) {
    const createdSubscriber = await this.apiClient.post(
      "/subscriber",
      subscriberDto,
    )
    console.log("CREATED SUVSCRIBER: ", createdSubscriber)
    await LocalStorage.setItem("SUBSCRIBER_ID", createdSubscriber._id)
    return createdSubscriber
  }

  async getSubscriber() {
    const data = await this.apiClient.get("/subscriber", {
      _tokenRequired: true,
    })
    if (data?.id) {
      await LocalStorage.setItem("SUBSCRIBER_ID", data?._id)
    }

    return data
  }
  async patchSubscriberFulfillment(fulfillmentData) {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.patch(
      `/subscriber/fulfillment/${subscriberId}`,
      fulfillmentData,
    )
  }
  async attachUserToSubscriber() {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.patch(`/subscriber/attachUser/${subscriberId}`)
  }

  async getCurrentBox() {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.get(`/box/current/${subscriberId}`)
  }
  async putSubscriberBox(wineIds) {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.put(`/box/${subscriberId}`, wineIds)
  }
  async getPastBoxes() {
    return this.apiClient.get("/box/past")
  }
  async getListOfWines() {
    return this.apiClient.get("/wines")
  }
  async checkSubscription() {
    return this.apiClient.get("/subscription/check")
  }
  async createSubscription(winesList) {
    return this.apiClient.post("/subscription", winesList)
  }
  async pauseSubscription() {
    return this.apiClient.patch("/subscriber/pause")
  }
  async unpauseSubscription() {
    return this.apiClient.patch("/subscriber/unpause")
  }
  async cancelSubscription(reason) {
    return this.apiClient.patch("/subscriber/cancel", { reason })
  }
  async submitSubscription() {
    return this.apiClient.patch("/subscriber/submitSubscription")
  }
  async getTasteProfile() {
    const subscriberId = await this.getSubscriberId()
    const tasteProfile = await this.apiClient.get(
      `/taste-profile/${subscriberId}`,
    )

    return tasteProfile
  }
  async updateFrequency(frequency) {
    const subscriberId = await this.getSubscriberId()
    return this.apiClient.patch(`/subscriber/frequency/${subscriberId}`, {
      frequency,
    })
  }
  async identifySubscriber() {
    return await this.apiClient.post(`/events/identify`)
  }
  async registerInterest(registerInterestForm) {
    return await this.apiClient.post(
      `/events/registerInterest`,
      registerInterestForm,
    )
  }
  async getFaq() {
    const faq = await Contentful.getContent(Contentful.SUBSCRIPTION_FAQ)
    return (faq ?? []).sort((a, b) => {
      return a.order > b.order
    })
  }
  async getSubscriptionSettings() {
    await this.apiClient.get("/subscriptionSettings")
  }

  async checkGiftCode(code) {
    return await this.apiClient.get(`/subscriber/giftCard/${code}`)
  }

  async removeGiftCard() {
    const subscriberId = await this.getSubscriberId()
    return await this.apiClient.get(
      `/subscriber/removeGiftCard/${subscriberId}`,
    )
  }

  async saveRating(wine, wineFeedback) {
    return this.apiClient.post("/subscriber/rate", {
      wineSku: wine.sku,
      wineFeedback,
    })
  }

  async getReceiptTransactions(venueId, digits) {
    return this.apiClient.post(`/app/vatTransactions`, {
      last4: digits,
      venueId,
      startDate: moment().subtract(12, "hours").toISOString(),
    })
  }

  async getSquareVenueFromName(venueName) {
    return this.apiClient.get(`/app/squareVenue/${venueName}`)
  }

  async sendVATEmail({ venue, transactions, email }) {
    return this.apiClient.post(`/app/sendVatReceipt`, {
      venue,
      transactions,
      email,
    })
  }

  async createAirshipContact(data) {
    return this.apiClient.post("/app/createContact", data)
  }
}
