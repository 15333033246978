import React, { useMemo } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import StyleSheet from "react-native-media-query"
import Icon from "react-native-vector-icons/Entypo"
import IonIcon from "react-native-vector-icons/Ionicons"

import Base from "../../base"
import Gap from "../common/Gap"

export default function OptionView({
  index,
  answer,
  onPress = () => {},
  onTastingNotesPress = () => {},
  selected = false,
}) {
  const style = useMemo(() => {
    return {
      backgroundColor: selected
        ? Base.colours.appSuperLightRed
        : Base.colours.appWhite,
      borderColor: selected ? Base.colours.appRed : Base.colours.appWhite,
    }
  }, [selected])

  return (
    <TouchableOpacity
      style={[styles.main, style]}
      activeOpacity={0.95}
      onPress={() => onPress(index)}
    >
      {answer?.attributes?.shownumber || <Gap size={40} horizontal />}
      <View style={styles.content}>
        <Text
          style={[
            styles.title,
            { textAlign: answer?.attributes.shownumber ? "left" : "center" },
          ]}
          dataSet={{ media: ids.title }}
        >{`${answer?.attributes?.shownumber ? index + 1 + ". " : ""}${
          answer?.attributes?.title
        }`}</Text>
        {!!answer?.attributes?.subtitle && (
          <Text style={styles.subtitle}>{answer?.attributes.subtitle}</Text>
        )}

        {!!answer?.attributes?.wine?.data && (
          <TouchableOpacity
            style={styles.tastingNotesButton}
            activeOpacity={0.8}
            onPress={() => onTastingNotesPress(answer?.wine.data?.attributes)}
          >
            <Text style={styles.tastingNotesTitle}>Read Tasting Notes</Text>
            <Icon
              name={"chevron-small-right"}
              size={16}
              color={Base.colours.appRed}
            />
          </TouchableOpacity>
        )}
      </View>
      <IonIcon
        name="checkmark-circle"
        size={50}
        color={selected ? Base.colours.appRed : Base.colours.appWhite}
      />
    </TouchableOpacity>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    minHeight: 100,
    borderRadius: 50,
    backgroundColor: Base.colours.appWhite,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 13,
    borderWidth: 4,
    width: "100%",
  },

  image: {
    width: 88,
    height: 88,
    borderRadius: 44,
    resizeMode: "cover",
    backgroundColor: Base.colours.appLightRed,
  },

  content: {
    flex: 1,
    marginLeft: 18,
    marginVertical: 14,
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appBlack,
    fontSize: 40,
    lineHeight: 40,
    "@media (max-width: 960px)": {
      fontSize: 35,
      lineHeight: 35,
    },
  },

  subtitle: {
    marginTop: 8,
    fontFamily: Base.fonts.appBold,
    fontSize: 16,
    color: Base.colours.appBrown,
    lineHeight: 17,
  },

  tastingNotesButton: {
    flexDirection: "row",
    marginTop: 8,
    alignItems: "center",
    width: 145,
  },

  tastingNotesTitle: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 14,
    color: Base.colours.appRed,
    lineHeight: 15,
    marginBottom: 2,
  },
})
