import React from "react"
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"

import Base from "../../../base"
import AnimatedForwardArrow from "./AnimatedForwardArrow"

/**
 *  Constructs button according to passed configuration.
 *  Implements different appearances of a button regarding on pointed "type" property.
 *  Can show animated arrow calls to action.
 */
export default function Button({
  children,
  type, // defines which set of styles will be used.
  disabledColor,
  disabledTextColor,
  disabled = false,
  stretched = true, // should button fill whole available width.
  hidden = false,
  isLoading,
  onPress,
  buttonStyle,
  titleStyle,
  isArrowShowed = false,
  isArrowAnimated = true,
  isArrowAbsolute = true,
  arrowColor = Base.colours.appRed,
  hitSlop,
}) {
  return hidden ? (
    <View />
  ) : (
    <TouchableOpacity
      activeOpacity={disabled ? 1.0 : 0.6}
      pointerEvents={disabled || isLoading ? "none" : "auto"}
      disabled={disabled}
      style={[
        styles.button,
        buttonTypes?.[type]?.buttonStyle,
        disabled && buttonTypes?.[type]?.buttonDisabledStyle,
        !stretched && styles.buttonStretched,
        buttonStyle,
        disabled && disabledColor && { backgroundColor: disabledColor },
        disabled && disabledTextColor && { color: disabledTextColor },
        disabled && { borderWidth: 0 },
      ]}
      hitSlop={hitSlop}
      onPress={disabled ? undefined : onPress}
    >
      {isLoading ? (
        <ActivityIndicator
          size="small"
          color={buttonTypes?.[type]?.titleStyle.color}
        />
      ) : (
        <>
          {children ? (
            <Text
              adjustsFontSizeToFit
              allowFontScaling={false}
              lineBreakMode="tail"
              numberOfLines={1}
              style={[
                styles.title,
                buttonTypes?.[type]?.titleStyle,
                disabled && buttonTypes?.[type]?.titleDisabledStyle,
                !stretched && styles.buttonStretched,
                titleStyle,
              ]}
            >
              {children}
            </Text>
          ) : null}
          <AnimatedForwardArrow
            style={!!isArrowAbsolute && styles.arrowAbsolute}
            hidden={!isArrowShowed}
            color={arrowColor}
            isAnimated={isArrowAnimated}
          />
        </>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    flexGrow: 1,
    flexShrink: 0,
    height: 48,
    minHeight: 48,
    maxHeight: 48,
    minWidth: 48,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 8,
    flexBasis: "auto",
  },
  buttonStretched: {
    flex: 0,
    flexGrow: 0,
  },
  buttonTitleStretched: {
    flex: 0,
    flexGrow: 0,
  },
  title: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 28,
    lineHeight: 30,
    textAlign: "center",
    flexBasis: "auto",
    flexGrow: 1,
    flex: 1,
    paddingHorizontal: 12,
  },
  arrowAbsolute: {
    position: "absolute",
    right: 16,
  },
  squaredInline: {
    width: "auto",
    flexGrow: 0,
    flexShrink: 1,
    height: 40,
    minHeight: 40,
    maxHeight: 40,
    paddingVertical: 4,
    paddingHorizontal: 20,
    alignSelf: "center",
    flex: 0,
    borderRadius: 10,
  },
  squaredInlineTitle: {
    flex: 0,
    lineHeight: 28,
  },
  linkButtonStyle: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    height: 28,
    minHeight: 28,
    maxHeight: 28,
    alignSelf: "center",
    flex: 0,
    borderRadius: 10,
    paddingVertical: 0,
  },
  linkTitleStyle: {
    fontSize: 26,
    lineHeight: 26,
  },
})

const buttonTypes = {
  "rounded-secondary": StyleSheet.create({
    buttonStyle: {
      backgroundColor: "transparent",
      borderColor: Base.colours.appWhite,
      borderRadius: 26,
    },
    titleStyle: {
      color: Base.colours.appWhite,
    },
  }),
  "rounded-primary": StyleSheet.create({
    buttonStyle: {
      backgroundColor: Base.colours.appWhite,
      borderColor: Base.colours.appWhite,
      borderRadius: 26,
      flexBasis: "auto",
    },
    titleStyle: {
      color: Base.colours.appBrown,
      flexBasis: "auto",
    },
  }),
  submit: StyleSheet.create({
    buttonStyle: {
      backgroundColor: Base.colours.appOrange,
      borderColor: Base.colours.appOrange,
      borderRadius: 10,
      maxWidth: 220,
      width: "100%",
      alignSelf: "center",
    },
    titleStyle: {
      color: Base.colours.appWhite,
    },
  }),
  "squared-submit": StyleSheet.create({
    buttonStyle: {
      backgroundColor: Base.colours.appOrange,
      borderColor: Base.colours.appOrange,
      borderRadius: 10,
      alignSelf: "center",
    },
    titleStyle: {
      color: Base.colours.appWhite,
    },
  }),
  "squared-primary": StyleSheet.create({
    buttonStyle: {
      backgroundColor: Base.colours.appWhite,
      borderColor: Base.colours.appWhite,
      borderRadius: 10,
    },
    titleStyle: {
      color: Base.colours.appBrown,
    },
  }),
  "squared-inline": StyleSheet.create({
    buttonStyle: {
      ...styles.squaredInline,
      backgroundColor: Base.colours.appOrange,
      borderColor: Base.colours.appOrange,
    },
    titleStyle: {
      ...styles.squaredInlineTitle,
      color: Base.colours.appWhite,
    },
  }),
  "squared-inline-active": StyleSheet.create({
    buttonStyle: {
      ...styles.squaredInline,
      backgroundColor: Base.colours.appBrown,
      borderColor: Base.colours.appBrown,
    },
    titleStyle: {
      ...styles.squaredInlineTitle,
      color: Base.colours.appWhite,
    },
  }),
  link: {
    buttonStyle: {
      ...styles.linkButtonStyle,
    },
    titleStyle: {
      ...styles.linkTitleStyle,
      color: Base.colours.appOrange,
    },
    titleDisabledStyle: {
      color: "#9a9596",
      opacity: 0.6,
    },
  },

  "cancel-link": {
    buttonStyle: {
      ...styles.linkButtonStyle,
    },
    titleStyle: {
      ...styles.linkTitleStyle,
      color: "#8f8686",
    },
    titleDisabledStyle: {
      color: "#9a9596",
      opacity: 0.6,
    },
  },
  "cancel-link#disabled": {
    titleStyle: {
      color: "#9a9596",
    },
  },
  "squared-inline-brown": StyleSheet.create({
    buttonStyle: {
      ...styles.squaredInline,
      backgroundColor: Base.colours.appBrown,
      borderColor: Base.colours.appBrown,
      paddingHorizontal: 18,
    },
    titleStyle: {
      ...styles.squaredInlineTitle,
      color: Base.colours.appWhite,
      paddingHorizontal: 0,
    },
  }),
  "squared-inline-settings": StyleSheet.create({
    buttonStyle: {
      ...styles.squaredInline,
      backgroundColor: Base.colours.appVeryLightGrey,
      borderColor: Base.colours.appVeryLightGrey,
      paddingHorizontal: 22,
    },
    titleStyle: {
      ...styles.squaredInlineTitle,
      color: Base.colours.appOrange,
      paddingHorizontal: 0,
    },
  }),
  "rounded-surprise-me": StyleSheet.create({
    buttonStyle: {
      backgroundColor: Base.colours.appWhite,
      borderColor: Base.colours.appOrange,
      borderRadius: 10,
    },
    titleStyle: {
      color: Base.colours.appOrange,
    },
  }),
}
