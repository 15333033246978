export { default as API } from "../modules/api"
export { default as Firebase } from "../modules/firebase"
export { default as Storage } from "../modules/store"
export { default as Contentful } from "../modules/contentful"
export { default as Square } from "../modules/square"
export { default as Strings } from "./Strings"
export { default as Klaviyo } from "../modules/klaviyo"
export { default as CMS } from "../modules/cms"

import { useNavBarColour } from "../modules/hooks/useNavBarColour"
import constants from "./Constants"
import functions from "./Functions"
import strings from "./Strings"

export default {
  styles: constants.styles,
  colours: constants.colours,
  fonts: constants.fonts,
  questionTypes: constants.questionTypes,
  functions,
  constants,
  strings,
  useNavBarColour,
}
