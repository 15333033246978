import { useFocusEffect } from "@react-navigation/core"
import { useCallback } from "react"
import changeNavigationBarColor from "react-native-navigation-bar-color"

export const useNavBarColour = colour => {
  const onFocus = useCallback(() => {
    changeNavigationBarColor(colour)
  }, [colour])
  useFocusEffect(onFocus)
}
