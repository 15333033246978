import * as Linking from "expo-linking"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CMS, Storage } from "../../base"
import { CAMPAIGN_STATUS } from "../../base/Constants"

export function useGetCampaign() {
  const [campaign, setCampaign] = useState(null)
  const [loading, setLoading] = useState(false)
  const playedCampaigns = useSelector(state => state.mysteryCampaigns)
  const dispatch = useDispatch()

  const getCampaignFromSlug = useCallback(
    async slug => {
      const response = await CMS.getCampaignFromSlug(slug)
      const _campaign = response?.data[0]
      if (_campaign?.attributes.name) {
        const existingCampaign = playedCampaigns?.filter(
          x => x.campaignid === _campaign?.attributes?.campaignid,
        )[0]
        if (existingCampaign) {
          const newCampaign = {
            ..._campaign.attributes,
            ...existingCampaign,
          }
          setCampaign(newCampaign)
        } else {
          setCampaign(_campaign.attributes)
        }
      }
      setLoading(false)
    },
    [setCampaign, setLoading, playedCampaigns],
  )

  useEffect(() => {
    setLoading(true)
    Linking.getInitialURL().then(url => {
      var _url = url
      if (url.charAt(url.length - 1) === "/") {
        _url = _url.slice(0, -1)
      }
      let slug = _url.substring(_url.lastIndexOf("/") + 1)
      getCampaignFromSlug(slug)
    })
  }, [getCampaignFromSlug])

  const setCampaignStatus = useCallback(
    (status, attributes = {}) => {
      const campaigns = [...playedCampaigns]
      let found = false
      campaigns.forEach(x => {
        if (x.campaignid === campaign.campaignid) {
          found = true
          x.status = status
          Object.keys(attributes).forEach(y => {
            x[y] = attributes[y]
          })
        }
      })
      if (!found) {
        const _campaign = { ...campaign, ...attributes }
        _campaign.status = status
        campaigns.push(_campaign)
      }
      dispatch(Storage.actions.setMysteryCampaigns(campaigns))
    },
    [dispatch, playedCampaigns, campaign],
  )

  const question = useMemo(() => {
    const playedCampaign = playedCampaigns?.filter(
      x => x.campaignid === campaign?.campaignid,
    )[0]
    if (
      campaign &&
      !playedCampaign?.status &&
      !playedCampaign?.chosenOptionId
    ) {
      const _question =
        campaign?.options?.data[
          Math.floor(Math.random() * campaign.options.data.length)
        ]
      setCampaignStatus(CAMPAIGN_STATUS.IN_PROGRESS, {
        chosenOptionId: _question.id,
      })
      return _question
    }
    const existingQuestion = campaign?.options?.data?.filter(
      x => x.id === playedCampaign.chosenOptionId,
    )[0]
    return existingQuestion
  }, [campaign, playedCampaigns, setCampaignStatus])

  return { campaign, question, loading, setCampaignStatus }
}
