import React, { useMemo } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import AntDesign from "react-native-vector-icons/AntDesign"

import Base from "../../base"

export default function CloseButton(props) {
  const insets = useSafeAreaInsets()

  const alignment = { justifyContent: props.back ? "flex-start" : "flex-end" }
  const buttonStyle = useMemo(
    () => ({ alignItems: props.back ? "flex-start" : "flex-end" }),
    [props],
  )
  return (
    <View
      style={[
        styles.main,
        { marginTop: insets.top + 15 },
        props.style,
        alignment,
      ]}
      onPress={props.onPress}
    >
      <TouchableOpacity
        style={[styles.button, buttonStyle, props.buttonStyle]}
        onPress={props.onPress}
      >
        <AntDesign
          name={props.back ? "arrowleft" : "close"}
          size={28}
          color={props.dark ? Base.colours.appBlack : Base.colours.appWhite}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },

  button: {
    justifyContent: "center",
    width: 40,
    height: 40,
  },
})
