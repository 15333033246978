import React, { useCallback } from "react"
import { Helmet } from "react-helmet"
import { ScrollView, Text, View } from "react-native"
import StyleSheet from "react-native-media-query"
import ReactPlayer from "react-player"

import Base from "../../base"
import { AppButton } from "../common"
import Gap from "../common/Gap"
import useModalPrompt from "../subscriptions/hooks/useModalPrompt"

export default function HowToClaimScreen({ navigation, route }) {
  const [HelpModal, { show: showHelpModal }] = useModalPrompt(
    "Help with your reward",
    `Vagabond machines use NFC technology to interact with your phone. This requires least an iPhone 10 or newer or a modern Android device.\n\nIf you're having trouble redeeming please ask a member of staff to help with your discount.\n\n DISCOUNT: ${"50% OFF"}`,
    {
      successText: "OK",
    },
  )

  const needHelp = useCallback(() => {
    showHelpModal()
  }, [showHelpModal])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appVeryLightGrey} />
      </Helmet>
      <ScrollView
        style={styles.main}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
      >
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          HOW TO CLAIM
        </Text>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          Look for the VAGABOND APP sticker above the wine of your choice.
        </Text>
        <ReactPlayer
          url={require("../../assets/images/mysterywine/how_to_claim.mp4")}
          width={Math.min(Base.constants.window.width, 600)}
          height={Math.min(Base.constants.window.width, 600) * 1.03466666}
          loop
          playing
          playsinline
          muted
        />
        <View style={styles.gap} dataSet={{ media: ids.gap }} />
        <Text style={styles.subtitle}>
          Tap your phone to the sticker then follow the instructions, apply your
          discount and pour your wine!
        </Text>
        <AppButton
          titleStyle={styles.helpButtonTitle}
          style={styles.helpButton}
          onPress={needHelp}
          title={"Need Help?"}
          dataSet={ids.helpButton}
          titleDataSet={ids.helpButtonTitle}
        />
      </ScrollView>
      <HelpModal />
    </>
  )
}

const { styles, ids } = StyleSheet.create({
  main: {
    backgroundColor: Base.colours.appVeryLightGrey,
  },

  content: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appRed,
    fontSize: 90,
    lineHeight: 90,
    marginBottom: 30,
    marginTop: 15,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 66,
      lineHeight: 63,
      marginBottom: 20,
    },
  },

  subtitle: {
    fontFamily: Base.fonts.appBold,
    color: Base.colours.appBrown,
    fontSize: 20,
    lineHeight: 23,
    maxWidth: 600,
    marginBottom: 16,
    marginHorizontal: 20,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 18,
      maxWidth: 400,
    },
  },

  player: {
    height: Base.constants.window.width * 1.03466666,
  },

  helpButton: {
    marginTop: 10,
    marginBottom: 35,
    backgroundColor: Base.colours.appLightGrey,
    height: 60,
    "@media (max-width: 960px)": {
      height: 50,
    },
  },

  helpButtonTitle: {
    color: Base.colours.appRed,
    fontSize: 40,
    "@media (max-width: 960px)": {
      fontSize: 30,
    },
  },

  gap: {
    height: 40,
    "@media (max-width: 960px)": {
      height: 20,
    },
  },
})
