import React from "react"
import { StyleSheet } from "react-native"
import * as Animatable from "react-native-animatable"
import Icon from "react-native-vector-icons/AntDesign"

import Base from "../../../base"

/** Arrow icon with animation calls to action. */
export default function AnimatedForwardArrow({
  isAnimated = true,
  hidden = false,
  color = Base.colours.appRed,
  iconName = "arrowright",
  iconSize = 28,
  style,
}) {
  return hidden ? null : (
    <Animatable.View
      style={[styles.container, style]}
      animation={isAnimated && animationConfig[iconName]}
      duration={800}
      iterationCount="infinite"
      easing="ease-out"
    >
      <Icon name={iconName} size={iconSize} color={color} />
    </Animatable.View>
  )
}

const animationConfig = {
  arrowright: {
    0: {
      transform: [{ translateX: 0 }],
    },
    0.5: {
      transform: [{ translateX: 4 }],
    },
    1: {
      transform: [{ translateX: 0 }],
    },
  },
  arrowdown: {
    0: {
      transform: [{ translateY: 0 }],
    },
    0.5: {
      transform: [{ translateY: 4 }],
    },
    1: {
      transform: [{ translateY: 0 }],
    },
  },
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
})
