import axios from "axios"

import Firebase from "../firebase"

const WINES = "wine"
const HOMEITEMS = "appHomeCard"
const VENUES = "location"
const WEBSITE = "website"
const PROMOPAGE = "promoPage"
const SUBSCRIPTION_FAQ = "subscriptionFAQs"
const SOMM_SLIDE = "sommCarouselSlide"
const MYSTERY_WINE_CAMPAIGN = "mysteryWineCampaign"

async function performRequest(contentType = "", query = null) {
  const spaceId = Firebase.getConfigValue("cms_space") ?? "17ja4f67ohug"
  const accessToken =
    Firebase.getConfigValue("cms_token") ??
    "brBLziRW8Pi9a5AQfcTCdMUWX6a7kKxNj9BaYVHuVOs"
  const env = Firebase.getConfigValue("cms_env") ?? "master"
  const baseURL = `https://cdn.contentful.com/spaces/${spaceId}/environments/${env}`
  let url =
    baseURL +
    `/entries?access_token=${accessToken}&content_type=${contentType}&include=4&limit=1000`
  if (query) {
    url += "&" + query
  }

  var params = {
    method: "get",
    url,
  }
  return axios(params)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(error => {
      console.log("Contentful request error: ", error)
      return null
    })
}

async function getContent(
  contentType,
  preferredImageWidth = 1000,
  preferredImageHeight = 1000,
  query = null,
) {
  try {
    let results = await performRequest(contentType, query)
    if (!results || !results.items) {
      return null
    }

    let remaining = results?.total - results.items.length
    let skip = 1000
    while (remaining > 0) {
      console.log("requesting next batch")
      const nextResults = await performRequest(
        contentType,
        `${query ?? ""}&skip=${skip}`,
      )
      if (!nextResults?.items?.length) {
        remaining = 0
      } else {
        results.items = [...results.items, ...nextResults.items]
        results.includes.Asset = [
          ...(results?.includes?.Asset ?? []),
          ...(nextResults?.includes?.Asset ?? []),
        ]
        results.includes.Entry = [
          ...(results?.includes?.Entry ?? []),
          ...(nextResults?.includes?.Entry ?? []),
        ]
        remaining = results?.total - results.items.length
        skip += 1000
      }
    }

    results.items.forEach(item => {
      const keys = Object.keys(item.fields)
      keys.forEach(key => {
        const field = item.fields[key]
        item.fields[key] = processField(
          field,
          results,
          preferredImageWidth,
          preferredImageHeight,
        )
      })
      item.fields.id = item.sys.id
      delete item.sys
    })
    return results.items.map(x => x.fields)
  } catch (error) {
    console.log("Get Content error: ", error)
    return []
  }
}

function processField(field, results, w, h) {
  if (field?.sys?.linkType === "Asset") {
    field.url = getURLForAsset(field?.sys?.id, results, w, h)
  } else if (field?.sys?.linkType === "Entry") {
    let entry = getEntryForLink(field?.sys?.id, results)
    if (entry.fields) {
      const keys = Object.keys(entry.fields)
      keys.forEach(key => {
        const _field = entry.fields[key]
        entry.fields[key] = processField(_field, results, w, h)
      })
    }
    return entry
  } else if (Array.isArray(field)) {
    let newFields = []
    field.forEach(x => {
      if (x.sys?.linkType === "Entry") {
        let entry = getEntryForLink(x.sys?.id, results)
        if (entry) {
          const keys = Object.keys(entry)
          keys.forEach(key => {
            const _field = entry[key]
            entry[key] = processField(_field, results, w, h)
          })
        }
        newFields.push(entry)
      } else if (x.sys?.linkType === "Asset") {
        x.url = getURLForAsset(x?.sys?.id, results, w, h)
        newFields.push(x)
      }
    })
    return newFields
  }
  return field
}

function getURLForAsset(id, response, w, h) {
  if (!response?.includes?.Asset || !id) {
    return ""
  }
  const assets = response?.includes?.Asset
  for (var i = 0; i < assets.length; i++) {
    let asset = assets[i]
    if (asset?.sys?.id === id) {
      const newURL = asset.fields?.file?.url
      if (newURL)
        return (
          "https:" +
          newURL.replace("//downloads", "//images") +
          `?w=${w}&h=${h}`
        )
      return ""
    }
  }
  return null
}

function getEntryForLink(id, response) {
  if (!response?.includes?.Entry || !id) {
    return ""
  }
  const entries = response?.includes?.Entry
  for (var i = 0; i < entries.length; i++) {
    let entry = entries[i]
    if (entry?.sys?.id === id) {
      return {
        ...entry.fields,
        id,
      }
    }
  }
  return null
}

async function getWinesForVenue(venue) {
  try {
    const data = {
      operationName: "winesWithLocationSlug",
      variables: {
        preview: false,
        slug: venue.slug,
      },
      query:
        "query winesWithLocationSlug($preview: Boolean, $slug: String!) {\n  wineWithLocationSlug(preview: $preview, slug: $slug) {\n    ...wineFragment\n    __typename\n  }\n}\n\nfragment wineFragment on wine {\n  contentfulId\n  title\n  region\n  supplier\n  producer\n  country\n  abv\n  style\n  grapes\n  tastingnotes\n  displaytitle1\n  displaytitle2\n  type\n  __typename\n  sku\n  organic\n  vintage\n  fyi\n  pairingideas\n}\n",
    }

    var params = {
      method: "post",
      url: "https://api-production.vagabond-omnifi.co.uk/gql",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    }

    return axios(params)
      .then(response => {
        return response?.data?.data?.wineWithLocationSlug
      })
      .catch(error => {
        console.log(error)
        return null
      })
  } catch (error) {
    console.log("GET WINES FOR VENUE ERROR: ", error)
    return null
  }
}

async function getWineforSKU(sku) {
  try {
    const wine = await getContent(WINES, 1000, 1000, `fields.sku=${sku}`)
    return wine[0]
  } catch (error) {
    console.log("GET WINE FOR SKU ERROR: ", error)
    return null
  }
}

async function getPromoPageForId(pageId) {
  try {
    if (!pageId) {
      return null
    }
    const response = await getContent(
      PROMOPAGE,
      1000,
      1000,
      `fields.pageId=${pageId}`,
    )

    return response?.[0] || null
  } catch (error) {
    console.log(error)
    return null
  }
}

async function getConfig() {
  try {
    const results = await performRequest(WEBSITE, "fields.name=Vagabond")
    if (!results?.items?.length) {
      return null
    }
    const website = results.items[0].fields
    website.id = results.items[0].sys?.id
    return website
  } catch (error) {
    console.log(error)
    return null
  }
}

async function getCampaignFromSlug(slug) {
  try {
    const campaigns = await getContent(
      MYSTERY_WINE_CAMPAIGN,
      1000,
      1000,
      `fields.slug=${slug}`,
    )
    return campaigns[0]
  } catch (error) {
    console.log("GET CAMPAIGN FOR SLUG ERROR: ", error)
    return null
  }
}

export default {
  getContent,
  getWinesForVenue,
  getConfig,
  getWineforSKU,
  getPromoPageForId,
  getCampaignFromSlug,
  WINES,
  VENUES,
  WEBSITE,
  HOMEITEMS,
  PROMOPAGE,
  SUBSCRIPTION_FAQ,
  SOMM_SLIDE,
  MYSTERY_WINE_CAMPAIGN,
}
