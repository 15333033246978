import React, { useCallback, useState } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "react-native"
import * as Animatable from "react-native-animatable"
import Accordion from "react-native-collapsible/Accordion"
import Icon from "react-native-vector-icons/Entypo"

import Base from "../../../base"

/** Renders passed text content as sliding accordion with sections collapsed by default. */
export default function AccordionMenu({
  sections,
  renderContent,
  titleStyle,
  chevronColour = Base.colours.appWhite,
  chevronSize = 25,
  containerStyle,
  headerStyle,
  separatorColor = Base.colours.appRed,
  expandMultiple = false,
  getTitle = section => section.title,
}) {
  const [activeSections, setActiveSections] = useState([])

  const renderHeader = useCallback(
    (section, index, isActive, sections) => {
      const arrowStyle = { rotate: isActive ? "0deg" : "180deg" }
      const dotColor = section.color

      return (
        <View
          style={[
            styles.header,
            headerStyle,
            index === sections.length - 1 && { borderBottomWidth: 0 },
            { borderBottomColor: separatorColor },
          ]}
        >
          <Text style={[styles.headerText, titleStyle]}>
            {getTitle(section)}
          </Text>
          {dotColor ? (
            <View style={[styles.colorDot, { backgroundColor: dotColor }]} />
          ) : null}
          <View style={{ flex: 1 }} />
          <Animatable.View transition="rotate" style={arrowStyle}>
            <Icon
              name="chevron-thin-up"
              size={chevronSize}
              color={chevronColour}
            />
          </Animatable.View>
        </View>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titleStyle, headerStyle],
  )

  return (
    <Accordion
      containerStyle={[styles.accordion, containerStyle]}
      sections={sections}
      activeSections={activeSections}
      onChange={setActiveSections}
      renderHeader={renderHeader}
      renderContent={renderContent}
      underlayColor={Base.colours.appLightGrey}
      expandMultiple={expandMultiple}
      touchableComponent={props => <TouchableOpacity {...props} />}
    />
  )
}

const styles = StyleSheet.create({
  accordion: {},
  header: {
    paddingHorizontal: 24,
    minHeight: 60,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    paddingVertical: 12,
  },
  headerText: {
    color: Base.colours.appBrown,
    fontFamily: Base.fonts.appBold,
    fontSize: 16,
    lineHeight: 24,
    paddingRight: 8,
  },
  colorDot: {
    width: 18,
    height: 18,
    borderRadius: 9,
    marginRight: 8,
    marginBottom: 4,
    borderWidth: 2,
    borderColor: Base.colours.appWhite,
  },
})
