import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native"
import changeNavigationBarColor from "react-native-navigation-bar-color"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useSelector } from "react-redux"

import Base, { Contentful, Strings } from "../../../base"
import useRateWine from "../../../modules/hooks/useRateWine"
import {
  Alert,
  AppButton,
  CloseButton,
  ImageBG,
  RateButton,
  StatusBar,
} from "../../common"
import Row from "./EXTastingNotesRow"
import Scale from "./EXTastingNotesScale"

export default function EXTastingNotesScreen(props) {
  changeNavigationBarColor(Base.colours.appVeryLightGrey)
  const insets = useSafeAreaInsets()
  const profile = useSelector(state => state.profile)
  const [wine, setWine] = useState({})
  const [loading, setLoading] = useState(true)
  let title = wine?.displaytitle1 ?? ""
  title += wine?.displaytitle2 && ` ${wine?.displaytitle2}`

  const sku =
    props.route?.params?.wine?.sku ??
    props.wine?.sku ??
    props.route?.params?.sku

  const getWine = useCallback(async () => {
    setLoading(true)
    const _wine = await Contentful.getWineforSKU(sku)
    if (_wine?.sku) {
      setWine(_wine ?? {})
      setLoading(false)
    } else {
      Alert.show(
        "Hold Up",
        "We were unable to fetch details for this wine. Please try again later",
        "OK",
        closeTouched,
      )
    }
  }, [closeTouched, sku])

  useEffect(() => {
    getWine()
  }, [getWine])

  const closeTouched = useCallback(() => {
    if (Platform.OS === "web" && !props.navigation) {
      props.onDismiss()
      return
    } else if (props?.route?.params?.fromNFC) {
      props.navigation?.goBack()
      props.navigation?.popToTop()
      props.navigation?.goBack()
      props.navigation?.navigate("home")
      return
    }
    props.navigation?.goBack()
  }, [props])

  const buyTouched = useCallback(() => {
    if (wine?.shopifyUrl) {
      Base.functions.openURL(wine?.shopifyUrl)
    }
  }, [wine])

  const { rateWine, getWineRating } = useRateWine({
    errorCopy: {
      title: Strings.TASTING_NOTES_RATE_ERROR_DIALOG_TITLE,
      message: Strings.TASTING_NOTES_RATE_ERROR_DIALOG_MESSAGE,
    },
  })

  const rateTouched = useCallback(
    state => {
      rateWine(wine, state)
    },
    [rateWine, wine],
  )

  const currentRating = useMemo(
    () => getWineRating(wine),
    [wine, getWineRating],
  )

  if (loading) {
    return (
      <ImageBG
        bgID={Platform.OS === "web" ? 3 : 7}
        style={[styles.main, props.style, styles.loadingContainer]}
      >
        <CloseButton dark onPress={closeTouched} />
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={Base.colours.appGrey} />
        </View>
        <StatusBar
          barStyle="dark-content"
          translucent={true}
          backgroundColor={"transparent"}
        />
      </ImageBG>
    )
  }

  return (
    <ImageBG
      bgID={Platform.OS === "web" ? 3 : 7}
      style={[styles.main, props.style]}
    >
      <ScrollView
        style={styles.scrollview}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollContent}
        alwaysBounceVertical={false}
      >
        <CloseButton dark onPress={closeTouched} />
        <Text style={[Base.styles.title, styles.title]}>{title}</Text>
        <View style={styles.subtitleContainer}>
          <Text style={Base.styles.subtitle}>
            {wine?.vintage} / {wine?.abv}%
            {wine?.organic?.length > 0 && ` / ${wine?.organic}`}
          </Text>
          {!!wine && !!profile?.email && (
            <RateButton
              style={styles.rateButton}
              state={currentRating}
              onStateChanged={rateTouched}
              tastingNotes
            />
          )}
        </View>
        <Scale index={1} style={styles.scale} wine={wine} />
        {!!wine?.grapes?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_grapes.png")}
            text={wine?.grapes}
          />
        )}
        {!!wine?.country?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_pin.png")}
            text={`${wine?.country} ${
              wine?.region.length && " > " + wine?.region
            }`}
          />
        )}
        {!!wine?.tastingnotes?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_mouth.png")}
            text={wine?.tastingnotes}
          />
        )}
        {!!wine?.pairingideas?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_cutlery.png")}
            text={wine?.pairingideas}
          />
        )}
        {!!wine?.fyi?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_plus.png")}
            text={wine?.fyi || ""}
          />
        )}
        {!!wine?.locations?.length && (
          <Row
            icon={require("../../../assets/images/tastingnotes/tastingnotes_venue.png")}
            text={
              `${Strings.TASTING_NOTES_AVAILABLE_AT_TITLE}\n` +
              wine?.locations?.map(x => `• ${x.name}`).join("\n")
            }
          />
        )}
      </ScrollView>
      {!!wine?.shopifyUrl?.length && (
        <AppButton
          title={Strings.TASTING_NOTES_BUY_TITLE}
          colour={Base.colours.appWhite}
          style={[styles.buyButton, { marginBottom: 20 + insets.bottom }]}
          onPress={buyTouched}
        />
      )}
      <StatusBar
        barStyle="dark-content"
        translucent={true}
        backgroundColor={"transparent"}
      />
    </ImageBG>
  )
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: Base.colours.appVeryLightGrey,
    flex: 1,
    alignItems: "center",
  },

  scrollview: {
    width: "100%",
    maxWidth: 600,
  },

  closeContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },

  closeButton: {
    width: 40,
    height: 40,
    marginRight: -5,
  },

  title: {
    marginRight: 40,
    textAlign: "left",
  },

  subtitleContainer: {
    marginVertical: 12,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  buyButton: {
    backgroundColor: Base.colours.appOrange,
    marginVertical: 20,
  },

  rateButton: {
    marginVertical: -8,
    marginRight: -8,
  },

  loadingContainer: { paddingHorizontal: 18 },
  loading: { flex: 1, justifyContent: "center", alignItems: "center" },
  scrollContent: { flexGrow: 1, paddingHorizontal: 22 },
  scale: { marginBottom: 12 },
})
