import React from "react"
import { Helmet } from "react-helmet"
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import StyleSheet from "react-native-media-query"
import Icon from "react-native-vector-icons/Octicons"

import Base from "../../base"
import Gap from "../common/Gap"

export default function WaitTimeController({ navigation }) {
  const presets = [0, 5, 10, 15, 20, 30, 40, 50]

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appVeryLightGrey} />
      </Helmet>
      <ImageBackground
        style={styles.main}
        source={require("../../assets/images/waittime/wait_time_background.jpg")}
      >
        <View style={styles.header}>
          <View style={styles.redBar}>
            <Image
              style={styles.logo}
              source={require("../../assets/images/white_logo.png")}
            />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>Wait Time Controller</Text>
              <TouchableOpacity style={styles.connectionButton}>
                <View style={styles.connectionCircle} />
                <Text style={styles.connectionTitle}>1 Screen Connected</Text>
                <Icon
                  name="chevron-right"
                  size={18}
                  color={Base.colours.appWhite}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.content}>
				<Gap size={10} />
          <Text style={styles.sectionTitle}>
						Preset Table Wait Times 
					<Text style={{fontSize: 20}}>  (in minutes)</Text>
					</Text>
          <View style={styles.presetsContainer}>
            {presets.map((x, i) => {
              return (
                <TouchableOpacity key={i} style={styles.presetButton}>
                  <Text style={styles.presetButtonTitle}>{x}</Text>
                </TouchableOpacity>
              )
            })}
          </View>
          <Gap size={20} />
          <Text style={styles.sectionTitle}>Or Set Custom</Text>
          <View style={styles.customContainer}>
            <TouchableOpacity style={styles.customButton}>
              <Icon name="dash" color={Base.colours.appPink} size={70} />
            </TouchableOpacity>

            <TouchableOpacity style={[styles.presetButton, styles.customIndicator]}>
              <Text style={styles.presetButtonTitle}>0</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.customButton}>
              <Icon name="plus" color={Base.colours.appPink} size={60} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.versionNumber}>v1.2.3-a</Text>
        </View>
      </ImageBackground>
    </>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    backgroundColor: Base.colours.appVeryLightGrey,
    resizeMode: "cover",
    justifyContent: "space-between",
    alignItems: "center",
  },

  header: {
    justifyContent: "flex-start",
    width: "100%",
  },

  redBar: {
    marginTop: 20,
    backgroundColor: Base.colours.appPink,
    height: 150,
    width: "70%",
    paddingHorizontal: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logo: {
    width: 150,
    height: 100,
    resizeMode: "contain",
  },

  titleContainer: {
    flex: 1,
    alignItems: "flex-end",
  },

  title: {
    fontFamily: Base.fonts.appBold,
    fontSize: 38,
    color: Base.colours.appWhite,
    marginBottom: 10,
  },

  connectionButton: {
    flexDirection: "row",
    alignItems: "center",
  },

  connectionTitle: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 18,
    color: Base.colours.appWhite,
    marginHorizontal: 6,
  },

  connectionCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: Base.colours.appGreen,
  },

  content: {
    width: "75%",
    flex: 1,
    backgroundColor: Base.colours.appWhite,
    borderRadius: 10,
    marginVertical: 20,
    padding: 16,
    alignItems: "center",
  },

  footer: {
    justifyContent: "flex-start",
    width: "100%",
    paddingHorizontal: 50,
    marginBottom: 20,
  },

  versionNumber: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 15,
    color: Base.colours.appPink,
  },

  sectionTitle: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 35,
    color: Base.colours.appBlack,
    textAlign: "center",
    marginBottom: 15,
  },

  presetsContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
  },

  presetButton: {
    paddingHorizontal: 50,
    paddingVertical: 18,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: Base.colours.appLightGrey,
    marginHorizontal: 10,
    marginVertical: 10,
    justifyContent: "center",
    minWidth: 170,
  },

  presetButtonTitle: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 60,
    textAlign: "center",
    color: Base.colours.appBlack,
  },

	customContainer: {
		flexDirection: "row",
		alignItems: "center",
	},

	customButton: {
		padding: 20
	}
})
