import React from "react"
import { FacebookProvider, Initialize } from "react-facebook"

const FacebookContainer = ({ children }) => {
  return (
    <FacebookProvider appId="275308107049813" version="v12.0">
      <Initialize>{(/* { isReady, api } */) => children}</Initialize>
    </FacebookProvider>
  )
}

export default FacebookContainer
