import { NavigationContainer } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import React, { createRef, useCallback, useEffect, useState } from "react"
import { StyleSheet, View } from "react-native"
import { useDispatch } from "react-redux"

import Base, { Contentful, Firebase, Storage } from "./base"
import { LoadingLogo } from "./views/common"
import DispenseStack from "./views/dispensing"
import TastingNotes from "./views/explore/Results/EXTastingNotesScreen"
import MysteryWineStack from "./views/mystery-wine"
import SUTerms from "./views/signup/SUTerms"
import { subscriptionApi } from "./views/subscriptions/api"
import WaitTimeDisplay from "./views/web-waittime/WaitTimeDisplay"
import WaitTimeController from "./views/web-waittime/WaitTimeController"

export const navigationRef = createRef()

const MysteryWine = createStackNavigator()
const MysteryNavigation = () => {
  return (
    <NavigationContainer>
      <MysteryWine.Navigator screenOptions={Base.constants.defaultNavOptions}>
        <MysteryWine.Screen
          name="main"
          component={MysteryWineStack}
          options={{ headerShown: false, animationEnabled: true }}
        />
        <MysteryWine.Screen
          name="Tasting Notes"
          component={TastingNotes}
          options={{ headerShown: false, animationEnabled: true }}
        />
        <MysteryWine.Screen
          name="Terms and Conditions"
          component={SUTerms}
          options={{ animationEnabled: true }}
        />
      </MysteryWine.Navigator>
    </NavigationContainer>
  )
}

const DispenseNavigator = createStackNavigator()
const DispenseNavigation = () => {
  return (
    <NavigationContainer>
      <DispenseNavigator.Navigator
        screenOptions={Base.constants.defaultNavOptions}
      >
        <DispenseNavigator.Screen
          name="main"
          component={DispenseStack}
          options={{ headerShown: false, animationEnabled: true }}
        />
      </DispenseNavigator.Navigator>
    </NavigationContainer>
  )
}

const WaitTime = createStackNavigator()
const WaitTimeDisplayNavigation = () => {
  return (
    <NavigationContainer>
      <WaitTime.Navigator screenOptions={Base.constants.defaultNavOptions}>
        <WaitTime.Screen
          name="Wait Time Display"
          component={WaitTimeDisplay}
          options={{ headerShown: false, animationEnabled: true }}
        />
      </WaitTime.Navigator>
    </NavigationContainer>
  )
}

const WaitTime2 = createStackNavigator()
const WaitTimeControllerNavigation = () => {
  return (
    <NavigationContainer>
      <WaitTime2.Navigator screenOptions={Base.constants.defaultNavOptions}>
        <WaitTime2.Screen
          name="Wait Time Controller"
          component={WaitTimeController}
          options={{ headerShown: false, animationEnabled: true }}
        />
      </WaitTime2.Navigator>
    </NavigationContainer>
  )
}


export default function Navigation() {
  const [loading, setLoading] = useState(true)
  const [path, setPath] = useState(null)
  const dispatch = useDispatch()

  const setup = useCallback(async () => {
    await Firebase.init()
    const config = await Contentful.getConfig()
    if (config) {
      dispatch(Storage.actions.setConfig(config))
    }
    subscriptionApi.updateBaseUrl(
      Firebase.getConfigValue("subscription_api_url"),
    )

    const comps = window.location.pathname.split("/")
    setPath(comps[1])
    setLoading(false)
  }, [dispatch, setPath, setLoading])

  useEffect(() => {
    setup()
  }, [setup])

  if (loading) {
    return (
      <View style={styles.loading}>
        <LoadingLogo />
      </View>
    )
  }

  switch (path) {
    case "mystery":
      return <MysteryNavigation />
    case "wine":
      return <DispenseNavigation />
    case "gatwick-display":
      return <WaitTimeDisplayNavigation />
    case "gatwick-controller":
      return <WaitTimeControllerNavigation />
    default:
      window.location.href = "https://vagabondwines.co.uk"
      break
  }
}

const styles = StyleSheet.create({
  loading: { flex: 1, justifyContent: "center" },
})
