import React, { useMemo, useRef, useState } from "react"
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import Icon from "react-native-vector-icons/MaterialCommunityIcons"

import Base from "../../base"

export default function TextField(props) {
  const [secure, setSecure] = useState(props.secure ?? false)
  const [inFocus, setInFocus] = useState(false)
  const textfield = useRef()
  const hasText =
    props.data?.value &&
    props.data?.value != null &&
    typeof props.data?.value !== "undefined" &&
    props.data?.value?.length > 0
  function secureToggled() {
    setSecure(!secure)
  }

  const tfStyle = {
    ...styles.tfContainer,
    minHeight: props.data?.multiline ? 100 : 40,
    alignItems: props.data?.multiline ? "flex-start" : "center",
    ...props.style,
  }
  if (props.isValid) {
    tfStyle.backgroundColor = Base.colours.appWhite
  }
  if (props.data?.error) {
    tfStyle.backgroundColor = Base.colours.appLightOrange
  }
  if (props.height) {
    tfStyle.height = props.height
  }

  const lineStyle = useMemo(
    () => ({
      backgroundColor: props.data?.error
        ? Base.colours.appOrange
        : Base.colours.appBrown,
      height: inFocus ? 3 : 2,
    }),
    [props, inFocus],
  )

  return (
    <View style={styles.main}>
      {props.hideTitle ?? (
        <Text
          style={[
            styles.title,
            props.data?.error && { color: Base.colours.appOrange },
          ]}
        >
          {props.data?.title}
        </Text>
      )}
      <TouchableOpacity
        style={[tfStyle, styles.row]}
        onPress={() =>
          props.data?.type === "button"
            ? props.onFocus(props.index ?? 0)
            : textfield.current.focus()
        }
        activeOpacity={1}
      >
        {props.leftView && (
          <TouchableOpacity
            style={[styles.accessoryView, styles.leftView]}
            onPress={props.onLeftViewTouch && props.onLeftViewTouch}
          >
            {props.leftView}
          </TouchableOpacity>
        )}
        <TextInput
          ref={textfield}
          style={[styles.textfield]}
          value={props.data?.value}
          returnKeyType={props.data?.returnKey ?? "default"}
          autoCapitalize={"none"}
          editable={props.data?.editable ?? true}
          selectionColor={Base.colours.appGrey}
          autoCompleteType={"off"}
          autoCorrect={false}
          multiline={props.data?.multiline ?? false}
          keyboardType={
            props.data?.type && props.data?.type !== "button"
              ? props.data?.type
              : "default"
          }
          placeholder={props.data?.placeholder}
          onSubmitEditing={() => {
            setInFocus(false)
            props.onSubmit && props.onSubmit()
          }}
          onChange={({ nativeEvent }) => {
            props.onChange && props.onChange(props.index ?? 0, nativeEvent)
          }}
          secureTextEntry={secure}
          onEndEditing={() => setInFocus(false)}
          onFocus={() => {
            setInFocus(true)
            props.onFocus && props.onFocus(props.index ?? 0)
          }}
          placeholderTextColor={Base.colours.appGrey}
        />
        {props.secure && (
          <TouchableOpacity onPress={secureToggled}>
            <Icon
              name={secure ? "eye-off-outline" : "eye-outline"}
              size={24}
              color={"black"}
              style={styles.inputIcon}
            />
          </TouchableOpacity>
        )}
        {props.rightView && (
          <TouchableOpacity
            style={styles.accessoryView}
            onPress={props.onRightViewTouch && props.onRightViewTouch}
          >
            {props.rightView}
          </TouchableOpacity>
        )}
        {props.isValid && !props.secure && !props.data?.error && (
          <Icon
            name={"check"}
            size={24}
            color={Base.colours.appBrown}
            style={styles.inputIcon}
          />
        )}
        {props.loading && (
          <ActivityIndicator
            size="small"
            color={Base.colours.appSemiLightGrey}
          />
        )}
        {props.showClear && hasText === true && (
          <TouchableOpacity
            style={styles.accessoryView}
            onPress={props.onClear}
          >
            <Icon name={"x"} size={22} color={Base.colours.appSemiLightGrey} />
          </TouchableOpacity>
        )}
        {/* <View style={[styles.line, lineStyle]} /> */}
      </TouchableOpacity>
      {props.data?.error && (
        <Text style={styles.errorTitle}>{props.data.error}</Text>
      )}
      {props.data?.success && (
        <Text style={[styles.errorTitle, { color: Base.colours.appGreen }]}>
          {props.data?.success}
        </Text>
      )}
      {!props.data?.error && !props.data?.success && props.data?.subtitle && (
        <Text style={[styles.errorTitle, { color: Base.colours.appGrey }]}>
          {props.data?.subtitle}
        </Text>
      )}
      {!props.data?.error && !props.data?.success && !props.data?.subtitle && (
        <Text style={styles.errorTitle} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    maxWidth: 400,
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    marginBottom: 8,
    color: Base.colours.appBrown,
  },

  accessoryView: {
    marginLeft: 4,
    height: "100%",
    justifyContent: "center",
  },

  tfContainer: {
    height: 48,
    borderRadius: 30,
    paddingLeft: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Base.colours.appWhite,
  },

  error: {
    borderWidth: 2,
    borderColor: Base.colours.appRed,
  },

  errorTitle: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    color: Base.colours.appRed,
    textAlign: "left",
    marginTop: 8,
    minHeight: 16,
  },

  textfield: {
    flex: 1,
    paddingHorizontal: 8,
    marginLeft: 3,
    marginBottom: 1,
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    backgroundColor: Base.colours.appWhite,
  },

  line: {
    position: "absolute",
    bottom: 0,
    height: 2,
    backgroundColor: Base.colours.appBrown,
    width: "100%",
  },

  row: { flexDirection: "row" },
  leftView: { marginRight: 4 },
  inputIcon: { marginRight: 12 },
})
