import React, { useCallback, useState } from "react"
import { StyleSheet, Text, View } from "react-native"

import { AppButton } from "."
import Base from "../../base"
import FormField from "../subscriptions/components/FormField"
import Gap from "./Gap"
import ModalWindow from "./ModalWindow"

/** Proposes template to create modal dialog component.*/
export default function TextfieldModal({
  title,
  description,
  placeholder,
  disclaimer,
  visible,
  onSuccess,
  onDismiss,
  onCancel,
  text,
  onChangeText = () => null,
  cancelText = "CANCEL",
  successText = "OK",
  textfieldType = "default",
  error,
  returnKeyType = "go",
}) {
  return (
    <ModalWindow isVisible={visible} onDismiss={onDismiss}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{description}</Text>
      <FormField
        style={styles.formField}
        fieldStyle={{ backgroundColor: Base.colours.appVeryLightGrey }}
        type={textfieldType}
        placeholder={placeholder}
        enabled
        onChangeText={onChangeText}
        onSubmit={onSuccess}
        error={error}
        value={text}
        returnKeyType={returnKeyType}
      />
      <Gap size={24} />
      <View style={styles.buttons}>
        {!!cancelText && (
          <AppButton
            style={{
              backgroundColor: Base.colours.appLightGrey,
              width: "auto",
              minWidth: 120,
            }}
            colour={Base.colours.appBrown}
            onPress={() => {
              onCancel()
            }}
            title={cancelText}
          />
        )}
        <AppButton
          style={{
            backgroundColor: Base.colours.appRed,
            width: "auto",
            minWidth: 120,
          }}
          colour={Base.colours.appWhite}
          onPress={() => {
            onSuccess()
          }}
          title={successText}
        />
      </View>
      {!!disclaimer && <Text style={styles.disclaimer}>{disclaimer}</Text>}
    </ModalWindow>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 40,
    lineHeight: 40,
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appBrown,
    textAlign: "center",
    paddingBottom: 24,
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appBrown,
    textAlign: "center",
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },

  formField: {
    flex: 1,
  },

  disclaimer: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    textAlign: "center",
    lineHeight: 17,
    color: Base.colours.appBrown,
    marginTop: 16,
    marginBottom: 8,
  },
})
