import * as Haptics from "expo-haptics"
import { useCallback } from "react"
import { Platform } from "react-native"
import { useDispatch, useSelector } from "react-redux"

import { API, Firebase, Klaviyo, Storage } from "../../base"
import {
  NEGATIVE_RATE,
  NEUTRAL_RATE,
  POSITIVE_RATE,
} from "../../base/Constants"
import { Alert } from "../../views/common"
import { subscriptionApi } from "../../views/subscriptions/api"

const getRateKlaviyoEvent = state => {
  switch (state) {
    case POSITIVE_RATE: {
      return "app_wine_like"
    }
    case NEGATIVE_RATE: {
      return "app_wine_dislike"
    }
    default: {
      return null
    }
  }
}
/** Provides an ability to change user's attitude to a particular wine. */
export default function useRateWine({ errorCopy = {}, onError } = {}) {
  const dispatch = useDispatch()
  const { profile } = useSelector(state => state)

  const rateWine = useCallback(
    async (wine, state, { analyticsEvent, analyticsArgs } = {}) => {
      try {
        if (!wine) {
          return
        }
        if (Platform.OS !== "web") {
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success)
        }
        let response = {}
        // If wine is a subscription wine save rating through subscription rather than through firebase
        if (wine.meta && wine._id) {
          const subsResponse = await subscriptionApi.saveRating(wine, state)
          if (subsResponse) {
            response = await API.getProfile()
          }
        } else {
          response = await API.rateWine(wine, state)
        }
        console.log(response)
        if (response?.error && !!errorCopy) {
          throw new Error(errorCopy.message)
        } else if (response?.result?.vagabondId) {
          dispatch(Storage.actions.setProfile(response?.result))
          Firebase.logEvent(
            analyticsEvent ?? "wine_rated",
            analyticsArgs ?? { sku: wine.sku, state },
          )
          const klaviyoEvent = getRateKlaviyoEvent(state)
          if (klaviyoEvent) {
            Klaviyo.trackEvent(klaviyoEvent)
          }
        }
      } catch (error) {
        Alert.show(errorCopy?.title ?? "Error", error)
        onError?.()
      }
    },
    [dispatch, errorCopy, onError],
  )

  const getWineRating = useCallback(
    wine => {
      const ratings = profile?.ratings ?? {}
      const keys = Object.keys(ratings)
      if (keys.includes(wine?.sku)) {
        const rating = ratings[wine?.sku]
        if (typeof rating === "boolean")
          return rating === true ? POSITIVE_RATE : NEGATIVE_RATE
        else return rating?.rating === true ? POSITIVE_RATE : NEGATIVE_RATE
      }
      return NEUTRAL_RATE
    },
    [profile?.ratings],
  )

  return { rateWine, getWineRating }
}
