import React from "react"
// import Lottie from "react-lottie"
import { Modal, StyleSheet, Text, View } from "react-native"

// import * as beer from "../../assets/animations/beer.json"
// import * as cocktail from "../../assets/animations/cocktail.json"
// import * as redWine from "../../assets/animations/red_wine.json"
// import * as whiteWine from "../../assets/animations/white_wine_large.json"
import Base from "../../base"
import ImageBG from "./ImageBG"

export default function LoadingView(props) {
  // let animation = whiteWine
  // switch (props.animation) {
  //   case "redwine":
  //     animation = redWine
  //     break
  //   case "beer":
  //     animation = beer
  //     break
  //   case "cocktail":
  //     animation = cocktail
  //     break
  // }

  // const lottieOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animation.default,
  // }

  return (
    <Modal animationType="fade" visible={props.visible}>
      <View style={styles.main} pointerEvents="none">
        <ImageBG bgID={props.bgID ?? 2}>
          <View style={[styles.flexContainer, styles.topGap]} />
          <View>
            {/* <Lottie
              options={lottieOptions}
              height={400}
              width={150}
              style={styles.animation}
            /> */}
          </View>
          <View style={styles.flexContainer} />
          <Text style={[Base.styles.subtitle, styles.title]}>
            {props.text ?? "Loading..."}
          </Text>
        </ImageBG>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Base.colours.appBrown,
    bottom: 0,
    zIndex: 5,
  },

  container: {
    width: "100%",
    flex: 1,
  },

  title: {
    color: Base.colours.appWhite,
    marginTop: 20,
    marginBottom: 80,
    fontSize: 18,
    zIndex: 40,
  },

  animation: {
    width: 100,
    height: 300,
    alignSelf: "center",
  },

  flexContainer: { flex: 1 },
  topGap: { marginBottom: 100 },
})
