import React, { useMemo } from "react"
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native"
import { useMediaQuery } from "react-responsive"

import Base from "../../base"

export default function WebLogo(props) {
  const isMobile = useMediaQuery(Base.constants.mobile)

  function onPress() {
    props.navigation?.navigate("home")
  }

  const mainStyle = useMemo(() => ({ width: isMobile ? 80 : 120 }), [isMobile])

  if (Platform.OS !== "web") {
    return <View style={styles.mainNative} />
  }

  return (
    <TouchableOpacity
      style={[styles.main, props.style, mainStyle]}
      onPress={onPress}
    >
      <Image
        style={styles.logo}
        source={require("../../assets/images/light_logo.png")}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    width: 140,
    height: 70,
    position: "absolute",
    left: 25,
    top: 25,
  },

  mainNative: {
    position: "absolute",
    width: 0,
    height: 0,
  },

  logo: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
    shadowOpacity: 0.4,
    shadowRadius: 8,
  },
})
