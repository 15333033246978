import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import Confetti from "react-confetti"
import { Helmet } from "react-helmet"
import {
  Image,
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import { CheckBox } from "react-native-elements"
import StyleSheet from "react-native-media-query"
import IonIcon from "react-native-vector-icons/Ionicons"
import { useDispatch } from "react-redux"

import Base from "../../base"
import { CAMPAIGN_STATUS } from "../../base/Constants"
import { AppButton, VenuePicker } from "../common"
import Gap from "../common/Gap"
import { subscriptionApi } from "../subscriptions/api"
import FormField from "../subscriptions/components/FormField"
import useModalPrompt from "../subscriptions/hooks/useModalPrompt"
import { useGetCampaign } from "./useGetCampaign"

export default function WinningScreen({ navigation, route }) {
  const [loading, setLoading] = useState(false)
  const [playing, setPlaying] = useState(true)
  const [email, setEmail] = useState("")
  const [checked, setChecked] = useState(false)
  const [venue, setVenue] = useState(null)
  const [pickerVisible, setPickerVisible] = useState(false)
  const { campaign, question, setCampaignStatus } = useGetCampaign()

  const [ValidationErrorModal, { show: showValidationErrorModal }] =
    useModalPrompt(
      "Hold Up",
      "You've not entered a valid email address. Please check and try again.",
      {
        successText: "OK",
      },
    )

  const [VenueErrorModal, { show: showVenueErrorModal }] = useModalPrompt(
    "Hold Up",
    "You've not selected your preferred venue. Please go back and choose your favourite Vagabond.",
    {
      successText: "OK",
    },
  )

  const submit = useCallback(async () => {
    if (!venue) {
      showVenueErrorModal()
      return
    } else if (!Base.functions.validateEmail(email)) {
      showValidationErrorModal()
      return
    }

    setLoading(true)
    const contact = await subscriptionApi.createAirshipContact({
      email,
      unitId: venue.airshipId,
      sourceName: "App",
      groupName: "Mystery Wine",
      emailOptIn: checked,
    })

    setLoading(false)

    if (contact?.id) {
      setCampaignStatus(CAMPAIGN_STATUS.COMPLETED, {
        completedAt: moment().format(),
      })
      navigation.navigate("Claim Now")
    } else {
      showValidationErrorModal()
    }
  }, [
    showValidationErrorModal,
    showVenueErrorModal,
    email,
    setLoading,
    setCampaignStatus,
    venue,
    checked,
    navigation,
  ])

  const firstParagraph = useMemo(() => {
    const text =
      question?.attributes?.rightanswertext?.replace(/(\\n)/g, "\n") ?? ""
    const wine = campaign?.wine?.data?.attributes?.title ?? ""
    const split = text.split("{wine}")
    return (
      <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
        {split.map((item, i) => (
          <Text key={i}>
            {item}
            {i !== split.length - 1 && (
              <Text
                style={[styles.subtitle, { fontFamily: Base.fonts.appBold }]}
              >
                {wine}
              </Text>
            )}
          </Text>
        ))}
      </Text>
    )
  }, [campaign, question])

  const secondParagraph = useMemo(() => {
    const text = campaign?.winningsubtitle ?? ""
    const discount = `${campaign?.reward}`
    let paragraph = text.replace("{discount}", discount).replace(/(\\n)/g, "\n")
    if (campaign?.rewarddisclaimer) {
      paragraph += "*"
    }
    return paragraph
  }, [campaign])

  const showTerms = useCallback(() => {
    navigation?.navigate("Terms and Conditions", {
      terms: campaign?.terms,
      showBack: true,
    })
  }, [navigation, campaign])

  const onTextChange = useCallback(
    newText => {
      setEmail(newText)
    },
    [setEmail],
  )

  const checkboxPressed = useCallback(() => {
    setChecked(!checked)
  }, [setChecked, checked])

  const onVenueSelected = useCallback(
    venue => {
      setPickerVisible(false)
      setVenue(venue)
    },
    [setVenue, setPickerVisible],
  )

  if (!question) {
    return (
      <>
        <Helmet>
          <meta name="theme-color" content={Base.colours.appRed} />
        </Helmet>
        <ImageBackground
          imageStyle={{ alignSelf: "flex-end" }}
          source={require("../../assets/images/brush_13.png")}
          style={styles.main}
        />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appRed} />
      </Helmet>
      <ImageBackground
        imageStyle={{ alignSelf: "flex-end" }}
        source={require("../../assets/images/brush_13.png")}
        style={styles.main}
      >
        <Confetti
          style={{ pointerEvents: "none" }}
          numberOfPieces={playing ? 800 : 0}
          recycle={false}
          initialVelocityY={3}
          tweenDuration={25000}
          gravity={0.05}
          confettiSource={{
            x: 0,
            y: -20,
            w: Base.constants.window.width,
            h: 0,
          }}
          onConfettiComplete={confetti => {
            setPlaying(false)
            confetti.reset()
          }}
        />
        <ScrollView
          style={styles.scrollview}
          contentContainerStyle={styles.content}
          showsVerticalScrollIndicator={false}
          scrollEnabled={!pickerVisible}
        >
          <View style={{ flex: 1 }} />
          <View style={styles.header}>
            <Text style={styles.title} dataSet={{ media: ids.title }}>
              {campaign?.winningtitle?.replace(/(\\n)/g, "\n")}
            </Text>
            <Image
              style={styles.cupImage}
              dataSet={{ media: ids.cupImage }}
              source={require("../../assets/images/mysterywine/cup_icon.png")}
            />
            <Gap size={20} />
            {firstParagraph}
            <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
              {secondParagraph}
            </Text>
          </View>
          <View
            style={styles.formContainer}
            dataSet={{ media: ids.formContainer }}
          >
            <TouchableOpacity
              style={styles.venueButton}
              dataSet={{ media: ids.venueButton }}
              onPress={() => setPickerVisible(true)}
            >
              <Text style={styles.venueButtonTitle}>
                {venue?.name ?? "1. Select your Venue"}
              </Text>
              <View style={styles.venueButtonRightContainer}>
                {!!venue && (
                  <Text style={styles.venueButtonChange}>Change</Text>
                )}
                <IonIcon
                  name="chevron-forward"
                  color={Base.colours.appRed}
                  size={22}
                />
              </View>
            </TouchableOpacity>
            <FormField
              style={styles.emailField}
              type={"email-address"}
              placeholder="2. Email Address"
              onChangeText={onTextChange}
              onSubmit={submit}
              value={email}
              returnKeyType={"go"}
              dataSet={ids.emailField}
              fieldStyle={styles.emailFieldInput}
              fieldDataSet={ids.emailFieldInput}
            />
            <CheckBox
              title={campaign?.optindisclaimer}
              uncheckedColor={Base.colours.appWhite}
              checkedColor={Base.colours.appWhite}
              textStyle={styles.checkboxTitle}
              containerStyle={styles.checkbox}
              checkedIcon={
                <IonIcon
                  name="md-checkbox"
                  color={Base.colours.appWhite}
                  size={25}
                />
              }
              uncheckedIcon={
                <IonIcon
                  name="md-square-outline"
                  color={Base.colours.appWhite}
                  size={25}
                />
              }
              wrapperStyle={styles.checkboxContainer}
              onPress={checkboxPressed}
              activeOpacity={1}
              checked={checked}
            />
            <AppButton
              style={styles.button}
              titleStyle={styles.buttonTitle}
              title={campaign?.winningbuttontitle}
              onPress={submit}
              isLoading={loading}
              dataSet={ids.button}
              titleDataSet={ids.buttonTitle}
            />
          </View>
          <View style={{ flex: 1 }} />
          <View style={styles.footer}>
            {!!campaign?.rewarddisclaimer && (
              <Text style={styles.disclaimer}>
                {"*" + campaign.rewarddisclaimer}
              </Text>
            )}
            <TouchableOpacity
              style={styles.termsButton}
              onPress={showTerms}
              activeOpacity={0.9}
            >
              <Text style={styles.termsText}>Terms & Conditions</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
        <ValidationErrorModal />
        <VenueErrorModal />
        <VenuePicker
          visible={pickerVisible}
          title={"SELECT YOUR VENUE"}
          venueQuery={"fields.airshipId[gte]=1"}
          onClose={() => setPickerVisible(false)}
          onVenueSelected={onVenueSelected}
          hideOpeningHours
        />
      </ImageBackground>
    </>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Base.colours.appRed,
  },

  scrollview: {
    height: Base.constants.window.height,
  },

  content: {
    flexGrow: 1,
    paddingBottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  header: {
    width: "100%",
    paddingHorizontal: 20,
    alignItems: "center",
  },

  cupImage: {
    width: 140,
    height: 140,
    resizeMode: "contain",
    "@media (max-width: 960px)": {
      width: 95,
      height: 95,
    },
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appWhite,
    fontSize: 90,
    lineHeight: 90,
    marginTop: 40,
    marginBottom: 40,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 60,
      lineHeight: 58,
      marginBottom: 20,
    },
  },

  subtitle: {
    fontFamily: Base.fonts.appMedium,
    color: Base.colours.appWhite,
    fontSize: 20,
    lineHeight: 23,
    marginBottom: 40,
    marginTop: 20,
    maxWidth: 800,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 16,
      lineHeight: 23,
      marginBottom: 24,
      marginTop: -10,
    },
  },

  formContainer: {
    marginHorizontal: 24,
    maxWidth: 600,
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      maxWidth: 400,
    },
  },

  checkbox: {
    backgroundColor: "transparent",
    marginVertical: 20,
    borderWidth: 0,
    padding: 0,
  },

  checkboxContainer: {
    alignItems: "flex-start",
  },

  checkboxTitle: {
    color: Base.colours.appWhite,
    fontFamily: Base.fonts.appRegular,
    fontSize: 15,
    alignSelf: "center",
    width: "auto",
  },

  footer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
  },

  termsButton: {
    padding: 20,
  },

  termsText: {
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appWhite,
    textDecorationLine: "underline",
  },

  venueButton: {
    width: "100%",
    height: 60,
    borderRadius: 30,
    backgroundColor: Base.colours.appWhite,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 12,
    marginBottom: 16,
    "@media (max-width: 960px)": {
      marginBottom: 10,
      height: 50,
      borderRadius: 25,
    },
  },

  venueButtonTitle: {
    fontFamily: Base.fonts.appBold,
    fontSize: 17.5,
    color: Base.colours.appBrown,
  },

  venueButtonChange: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    color: Base.colours.appBrown,
    marginRight: -2,
    marginBottom: 1,
  },

  venueButtonRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  emailField: {
    width: "100%",
    maxWidth: 800,
  },

  emailFieldInput: {
    height: 60,
    fontSize: 17,
    "@media (max-width: 960px)": {
      height: 50,
    },
  },

  disclaimer: {
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appWhite,
    fontSize: 13,
  },

  button: {
    height: 70,
    paddingHorizontal: 30,
    "@media (max-width: 960px)": {
      height: 55,
    },
  },

  buttonTitle: {
    fontSize: 44,
    "@media (max-width: 960px)": {
      fontSize: 35,
    },
  },
})
