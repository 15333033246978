import moment from "moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { Image, ScrollView, Text, TouchableOpacity } from "react-native"
import StyleSheet from "react-native-media-query"

import Base from "../../base"
import { CAMPAIGN_STATUS } from "../../base/Constants"
import { AppButton, ImageBG } from "../common"
import useModalPrompt from "../subscriptions/hooks/useModalPrompt"
import { useGetCampaign } from "./useGetCampaign"

export default function ClaimViaServerScreen({ navigation, route }) {
  const { setCampaignStatus, campaign, loading } = useGetCampaign()
  const [timeLeft, setTimeLeft] = useState(1000)

  const setClaimed = useCallback(() => {
    setCampaignStatus(CAMPAIGN_STATUS.CLAIMED)
  }, [setCampaignStatus])

  const [RedeemModal, { show: showRedeemModal }] = useModalPrompt(
    "Confirm Claim Reward",
    `This function should only be performed by a server and by proceeding your reward will be redeemed. This is a one time offer and you will not be able to redeem it again in future.\n\nOnly proceed if you are a Vagabond server and have been asked to do so by a guest.\n\n DISCOUNT: ${campaign?.reward}`,
    {
      successText: "Understood, REDEEM NOW",
      onSuccess: setClaimed,
      cancelText: "GO BACK",
      buttonsVertical: true,
    },
  )

  const claimTouched = useCallback(() => {
    showRedeemModal()
  }, [showRedeemModal])

  const showTerms = useCallback(() => {
    navigation?.navigate("Terms and Conditions", {
      terms: campaign?.terms,
      showBack: true,
    })
  }, [navigation, campaign])

  useEffect(() => {
    const now = moment()
    const timeCompleted = moment(campaign?.completedAt)
    setTimeLeft(30 - now.diff(timeCompleted, "minutes"))
  }, [campaign])

  useEffect(() => {
    if (!campaign) return

    if (!timeLeft || timeLeft <= 0) {
      setClaimed()
      return
    }

    const intervalId = setInterval(() => {
      const now = moment()
      const timeCompleted = moment(campaign?.completedAt)
      setTimeLeft(30 - now.diff(timeCompleted, "minutes"))
    }, 1000)
    return () => clearInterval(intervalId)
  }, [timeLeft, campaign, setClaimed])

  const timeText = useMemo(() => {
    if (timeLeft > 30) {
      return ""
    }
    return `within ${timeLeft} ${timeLeft == 1 ? "minute" : "minutes"}`
  }, [timeLeft])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appVeryLightGrey} />
      </Helmet>
      <ImageBG bgID={10} imageStyle={{ opacity: 0.5 }}>
        <ScrollView
          style={styles.main}
          contentContainerStyle={styles.content}
          showsVerticalScrollIndicator={false}
          alwaysBounceHorizontal={false}
        >
          <Text style={styles.title} dataSet={{ media: ids.title }}>
            CLAIM REWARD
          </Text>
          <Image
            style={styles.image}
            dataSet={{ media: ids.image }}
            source={{ uri: campaign?.rewardimage?.data?.attributes?.url }}
          />
          <Text style={styles.subtitle}>
            Show this screen to a server to claim {"\n"}your reward {timeText}
          </Text>
          <AppButton
            titleStyle={styles.claimButtonTitle}
            style={styles.claimButton}
            onPress={claimTouched}
            title={"I'M A SERVER, CLAIM NOW"}
            dataSet={ids.claimButton}
            titleDataSet={ids.claimButtonTitle}
          />

          <TouchableOpacity
            style={styles.termsButton}
            onPress={showTerms}
            activeOpacity={0.9}
          >
            <Text style={styles.termsText}>Terms & Conditions</Text>
          </TouchableOpacity>
        </ScrollView>
      </ImageBG>
      <RedeemModal />
    </>
  )
}

const { styles, ids } = StyleSheet.create({
  main: {
    flex: 1,
  },

  content: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appBrown,
    fontSize: 90,
    lineHeight: 90,
    marginBottom: 20,
    marginTop: 30,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 70,
      lineHeight: 63,
    },
  },

  subtitle: {
    fontFamily: Base.fonts.appBold,
    fontSize: 19,
    color: Base.colours.appBrown,
    lineHeight: 23,
    marginHorizontal: 20,
    marginTop: 20,
    maxWidth: 400,
    textAlign: "center",
  },

  image: {
    width: 380,
    height: 300,
    resizeMode: "contain",
    "@media (max-width: 960px)": {
      width: 280,
      height: 250,
    },
  },

  claimButton: {
    marginTop: 35,
    marginBottom: 35,
    backgroundColor: Base.colours.appRed,
    height: 60,
    "@media (max-width: 960px)": {
      height: 50,
    },
  },

  claimButtonTitle: {
    color: Base.colours.appWhite,
    fontSize: 40,
    "@media (max-width: 960px)": {
      fontSize: 30,
    },
  },

  rewardTextContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  rewardText: {
    fontFamily: Base.fonts.appBold,
    fontSize: 25,
    color: Base.colours.appRed,
    textAlign: "center",
  },

  termsButton: {
    padding: 20,
  },

  termsText: {
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appBrown,
    textDecorationLine: "underline",
  },
})
