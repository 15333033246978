import { Alert, Platform } from "react-native"

function show(title, message, button = "OK", onPress = null) {
  if (Platform.OS === "web") {
    // eslint-disable-next-line no-alert
    alert(`${title}\n${message}`)
    if (onPress !== null) onPress()
    return
  }
  Alert.alert(title, "\n" + message, [{ text: button, onPress }], {
    cancelable: false,
  })
}

function showTwoButton(title, message, negativeButton, positiveButton) {
  if (Platform.OS === "web") {
    // eslint-disable-next-line no-alert
    const res = window.confirm(`${title}\n${message}`)
    if (res) positiveButton.onPress()
    else negativeButton.onPress()
    return
  }
  Alert.alert(title, `\n` + message, [negativeButton, positiveButton], {
    cancelable: false,
  })
}

export default { show, showTwoButton }
