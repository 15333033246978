import { createStackNavigator } from "@react-navigation/stack"
import React from "react"

import ClaimedScreen from "./ClaimedScreen"
import ClaimViaServerScreen from "./ClaimViaServerScreen"
import HowToClaimScreen from "./HowToClaimScreen"
import LandingScreen from "./LandingScreen"
import LosingScreen from "./LosingScreen"
import OptionsScreen from "./OptionsScreen"
import ThanksScreen from "./ThanksScreen"
import WinningScreen from "./WinningScreen"

const Nav = createStackNavigator()
export default () => {
  return (
    <Nav.Navigator>
      <Nav.Screen
        name="Guess the Wine"
        component={LandingScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="Pick An Answer"
        component={OptionsScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="You've Won!"
        component={WinningScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="Sorry Not This Time"
        component={LosingScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="How To Claim"
        component={HowToClaimScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="Claim Now"
        component={ClaimViaServerScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="Thanks for Entering"
        component={ThanksScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="Reward Claimed"
        component={ClaimedScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
    </Nav.Navigator>
  )
}

export { LandingScreen }
