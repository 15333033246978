import React, { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ActivityIndicator, Image, ScrollView, Text, View } from "react-native"
import StyleSheet from "react-native-media-query"

import Base from "../../base"

export default function LandingScreen({ navigation }) {
  const onSubmit = useCallback(() => {}, [])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appBlack} />
      </Helmet>
      <View style={styles.main} />
    </>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    backgroundColor: Base.colours.appBlack,
  },
})
