import { useNavigation } from "@react-navigation/native"
import React, { useCallback, useState } from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { Portal } from "react-native-portalize"
import Icon from "react-native-vector-icons/AntDesign"

import Base, { Firebase } from "../../../base"
import Gap from "../../common/Gap"
import SubscriptionsFaqScreen from "../screens/SubscriptionsFaqScreen"

/** Button to submit current survey's question answer displays on the bottom of survey screen. */
export default function QuestionButton({
  color = Base.colours.appWhite,
  style,
  onPress,
  screenName,
  analyticsEvent,
  analyticsArgs,
}) {
  const navigation = useNavigation()
  const [showing, setShowing] = useState(false)

  const handlePress = useCallback(() => {
    if (onPress) {
      onPress()
    } else {
      if (analyticsEvent) {
        Firebase.logEvent(analyticsEvent, analyticsArgs)
      }
      setShowing(!showing)
    }
  }, [onPress, analyticsEvent, analyticsArgs, showing])

  const onDismiss = useCallback(() => {
    setShowing(false)
  }, [])

  return (
    <TouchableOpacity
      style={[styles.button, style]}
      onPress={handlePress}
      hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
    >
      <Icon
        name="questioncircleo"
        color={color ?? Base.colours.appWhite}
        size={30}
      />
      {showing && (
        <Portal>
          <TouchableOpacity
            style={styles.modalContainer}
            activeOpacity={1.0}
            onPress={() => setShowing(false)}
          >
            <View style={styles.faqContainer}>
              <View style={styles.faqHeader}>
                <Gap horizontal size={40} />
                <Text style={styles.faqTitle}>FAQs</Text>
                <TouchableOpacity
                  style={Base.styles.headerButton}
                  onPress={() => setShowing(false)}
                >
                  <Icon
                    name={"close"}
                    size={28}
                    color={Base.colours.appDarkGrey}
                  />
                </TouchableOpacity>
              </View>
              <SubscriptionsFaqScreen
                dismiss={onDismiss}
                navigation={navigation}
                style={styles.faqScreen}
              />
            </View>
          </TouchableOpacity>
        </Portal>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 8,
    marginTop: 5,
    alignItems: "center",
    justifyContent: "center",
  },

  modalContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
    alignItems: "center",
  },

  faqContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  faqHeader: {
    height: 70,
    width: "100%",
    backgroundColor: Base.colours.appLightGrey,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 6,
  },

  faqScreen: {
    width: "100%",
    height: "100%",
  },

  faqTitle: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 35,
    color: Base.colours.appBrown,
  },
})
