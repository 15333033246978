import { createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

import Firebase from "../firebase"
import LocalStorage from "../local-storage"
import actions from "./Actions"
import reducers from "./Reducers"

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer)
const persistor = persistStore(store)

function clear() {
  persistor.purge()
  LocalStorage.clear()
  Firebase.logout()
}

export default {
  store,
  clear,
  persistor,
  actions,
}
