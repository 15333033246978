import React from "react"
import { Image, StyleSheet, View } from "react-native"

export default function CloseButton(props) {
  return (
    <View style={[styles.main, props.style]}>
      <Image
        style={styles.logo}
        source={require("../../assets/images/dark_logo.png")}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    alignSelf: "center",
    height: 100,
    width: 90,
  },

  logo: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
})
