import { createStackNavigator } from "@react-navigation/stack"
import React from "react"

import LandingScreen from "./LandingScreen"
import TastingNotesScreen from "./TastingNotesScreen"

const Nav = createStackNavigator()
export default () => {
  return (
    <Nav.Navigator>
      <Nav.Screen
        name="Tasting Notes"
        component={TastingNotesScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <Nav.Screen
        name="How To Pour"
        component={LandingScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
    </Nav.Navigator>
  )
}
