/* eslint-disable radix */
import "react-native-get-random-values"

import { getDistance } from "geolib"
import moment from "moment"
import React, { useEffect } from "react"
import { BackHandler, Linking, Platform, Text } from "react-native"
import { useMediaQuery } from "react-responsive"

import Storage from "../modules/store"
import Alert from "../views/common/Alert"
import Strings from "./Strings"

const callNumber = function (number) {
  let tel = number.replace(/\s/g, "")
  Linking.openURL(`tel://${tel}`).catch(error => {
    Alert.alert(
      Strings.NO_PHONE_DIALOG_TITLE,
      Strings.NO_PHONE_DIALOG_MESSAGE.replace("XXX", number),
    )
  })
}

const openURL = function (url) {
  try {
    Linking.openURL(url).catch(_ => {
      Alert.show(Strings.NO_URL_DIALOG_TITLE, Strings.NO_URL_DIALOG_MESSAGE)
    })
  } catch (error) {
    Alert.show(Strings.NO_URL_DIALOG_TITLE, Strings.NO_URL_DIALOG_MESSAGE)
  }
}

const sendEmail = function (address) {
  Linking.openURL(`mailto://${address}`).catch(error => {
    Alert.show(Strings.NO_EMAIL_DIALOG_TITLE, Strings.NO_EMAIL_DIALOG_MESSAGE)
  })
}

const androidFontFix = () => {
  if (Platform.OS !== "android") {
    return
  }

  const oldRender = Text.render
  Text.render = function (...args) {
    const origin = oldRender.call(this, ...args)
    return React.cloneElement(origin, {
      textBreakStrategy: "simple",
    })
  }
}

export function Desktop({ children }) {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
export function Tablet({ children }) {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export function Mobile({ children }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

function validateEmail(email) {
  if (!email) {
    return false
  }
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

function validatePassword(password) {
  if (!password) {
    return false
  }
  const re = new RegExp("^(?=.*[0-9])(?=.*[a-z]).{7,}$")
  return re.test(password)
}

function validateDOB(dob) {
  if (!dob || dob.length < 10) return Strings.DOB_INVALID
  if (parseInt(dob.substring(0, 2)) > 31) return Strings.DOB_INVALID
  if (parseInt(dob.substring(3, 5)) > 12) return Strings.DOB_INVALID
  let momentDOB = moment(dob, "DD/MM/YYYY")
  if (moment().diff(momentDOB, "years") < 18) return Strings.DOB_UNDER_18

  const re = new RegExp("[0-9]{2}/[0-9]{2}/[0-9]{4}$")
  if (re.test(dob)) return null
  return Strings.DOB_INVALID
}

function validatePhone(phone) {
  if (!phone) {
    return false
  }
  // eslint-disable-next-line no-invalid-regexp
  const re = new RegExp("^(+44s?d{10}|0044s?d{10}|0s?d{10})?$")
  return re.test(phone)
}

function dobToReadable(dob) {
  let momentDOB = moment(dob, "YYYY-MM-DD")
  return momentDOB.format("DD/MM/YYYY")
}

function dateToReadable(date) {
  const today = moment()
  const yesterday = moment().subtract(1, "day")
  const aWeekAgo = moment().subtract(7, "day")
  let m = moment(date)

  if (m.isSame(today, "d")) {
    return "today"
  }
  if (m.isSame(yesterday, "d")) {
    return "yesterday"
  }
  if (m.isAfter(aWeekAgo)) {
    return "this week"
  }
}

function useBackButton(handler) {
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handler,
    )

    return () => {
      backHandler.remove()
    }
  }, [handler])
}

function capitalise(s) {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

function interpolateArrays(big, small) {
  if (big.length < small.length) {
    return null
  }
  let result = []
  for (var i = 0; i < big.length; i++) {
    result.push(big[i])
    if (i < small.length) {
      result.push(small[i])
    }
  }
  return result
}

function arrayOfNumbers(max) {
  var array = []
  for (var i = 1; i <= max; i++) {
    array.push(i)
  }
  return array
}

function defaultSection(key, category, superKey) {
  let title = key.replace(/[()]/g, "").replace("Wine ", "")
  title = capitalise(title)

  return {
    collapsed: true,
    key,
    superKey,
    category,
    title,
    subSections: [],
    items: [],
  }
}

function populateMenu(venue, drinks = []) {
  const unsortedWines = []
  const unsortedFood = []
  const unsortedOthers = []

  const filteredDrinks = drinks.filter(x => {
    let locations = x.locations?.map(y => y.id)
    return locations?.includes(venue.id)
  })

  filteredDrinks.forEach(drink => {
    const key = drink.type

    let section = unsortedOthers.filter(x => x.key === key)[0]
    if (key.includes("Wine")) {
      section = unsortedWines.filter(x => x.key === key)[0]
      if (!section) {
        section = defaultSection(key, "wine")
        unsortedWines.push(section)
      }
    } else {
      if (!section) {
        section = defaultSection(key, "others")
        unsortedOthers.push(section)
      }
    }

    let subSection = section.subSections.filter(x => x.key === drink.style)[0]
    if (!subSection) {
      subSection = defaultSection(drink.style, section.category, key)
      section.subSections.push(subSection)
    }
    subSection.items.push(drink)
  })

  const wines = []
  const config = Storage.store.getState().config
  if (config?.wineCategoryOrder) {
    config.wineCategoryOrder.forEach(x => {
      let section = unsortedWines.filter(
        y => y.title.toLowerCase() === x.toLowerCase(),
      )[0]
      if (section) {
        wines.push(section)
      }
    })
  }

  const others = []
  if (config?.otherCategoryOrder) {
    config.otherCategoryOrder.forEach(x => {
      let section = unsortedOthers.filter(
        y => y.title.toLowerCase() === x.toLowerCase(),
      )[0]
      if (section) {
        others.push(section)
      }
    })
  }

  venue.menu?.forEach(item => {
    if (item != null) {
      let section = unsortedFood.filter(x => x.key === item.style)[0]
      if (!section) {
        section = defaultSection(item.style, "food")
        unsortedFood.push(section)
      }
      section.items.push(item)
    }
  })

  const food = []
  if (config?.foodCategoryOrder) {
    config.foodCategoryOrder.forEach(x => {
      let section = unsortedFood.filter(
        y => y.title.toLowerCase() === x.toLowerCase(),
      )[0]
      if (section) {
        food.push(section)
      }
    })
  }

  return [wines, food, others]
}

function distanceToVenue(venue, fromLocation) {
  if (fromLocation == null || venue == null) {
    return null
  }
  const venueLocation = {
    latitude: venue.latlong.lat,
    longitude: venue.latlong.lon,
  }
  return (getDistance(venueLocation, fromLocation) * 0.000621371).toFixed(1)
}

function sortVenues(venues, coordinates) {
  return venues.sort((a, b) => {
    const pA = { latitude: a.latlong.lat, longitude: a.latlong.lon }
    const pB = { latitude: b.latlong.lat, longitude: b.latlong.lon }
    var distA = getDistance(pA, coordinates)
    var distB = getDistance(pB, coordinates)
    return distA - distB
  })
}

function venueOpenStatus(venue) {
  const key = moment().format("dddd").toLowerCase()
  const day = venue?.openingHours?.[key]
  let openingTime = day?.openingTime
  let closingTime = day?.closingTime
  if (!openingTime || !closingTime || day?.closed) {
    return "Currently closed"
  }

  if (moment(openingTime, "HH:mm").diff(moment()) > 0) {
    return `Opens at ${openingTime}`
  }

  return `Open until ${closingTime}`
}

function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    })
  }, {})
}

function sortTransactions(transactions, searchQuery) {
  const months = {}

  transactions.forEach(x => {
    if (x.venuename?.length > 0) {
      const month = moment.unix(x.date).format("MMM YYYY")
      const m = months[month] ?? []
      m.push(x)
      months[month] = m
    }
  })

  Object.keys(months).forEach(key => {
    const ts = months[key]
    let grouped = groupByKey(ts, "venuename")
    months[key] = []
    Object.keys(grouped).forEach(k => {
      const mts = grouped[k]
      const subGrouped = []
      for (var i = 0; i < mts.length; i++) {
        var t = mts[i]
        var found = false
        if (
          searchQuery == null ||
          !searchQuery?.length ||
          (searchQuery?.length > 0 &&
            t.venuename.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          for (var j = 0; j < subGrouped.length; j++) {
            if (
              moment.unix(subGrouped[j].transactions[0].date).date() ===
              moment.unix(t.date).date()
            ) {
              found = true
              subGrouped[j].transactions.push(t)
              break
            }
          }
          if (!found) {
            subGrouped.push({
              venuename: t.venuename,
              title: t.venuename,
              date: t.date,
              transactions: [t],
            })
          }
        }
        if (
          searchQuery?.length > 0 &&
          t.wine.displaytitle1.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          subGrouped.push({
            venuename: t.venuename,
            title: t.wine.displaytitle1,
            date: t.date,
            transactions: [t],
          })
        }
      }
      subGrouped.forEach(x => {
        let price = 0
        x.transactions.forEach(_x => {
          price += Math.abs(_x.cost)
        })
        x.total = price
      })
      months[key] = months[key].concat(subGrouped)
    })
  })

  let sortedList = Object.keys(months).map(key => {
    let data = months[key].sort((a, b) => {
      let dateA = moment.unix(a.date)
      let dateB = moment.unix(b.date)
      return dateA.isBefore(dateB)
    })

    return { title: key, data }
  })

  sortedList = sortedList.sort((a, b) => {
    let dateA = moment(a.title, "MMM YYYY")
    let dateB = moment(b.title, "MMM YYYY")
    return dateA.isBefore(dateB)
  })
  return sortedList
}

function compareVersions(v1, v2, options) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split("."),
    v2parts = v2.split(".")

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0")
    while (v2parts.length < v1parts.length) v2parts.push("0")
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number)
    v2parts = v2parts.map(Number)
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1
    }

    if (v1parts[i] == v2parts[i]) {
      continue
    } else if (v1parts[i] > v2parts[i]) {
      return 1
    } else {
      return -1
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1
  }
  return 0
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
})

const formatPrice = price => currencyFormatter.format(price ?? 0)

function getBaseURL(url) {
  var pathArray = url.split("/")
  var protocol = pathArray[0]
  var host = pathArray[2]
  var base = protocol + "//" + host
  return base
}

function isNumeric(str) {
  if (typeof str !== "string") return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export default {
  callNumber,
  openURL,
  sendEmail,
  androidFontFix,
  validateEmail,
  validatePassword,
  validateDOB,
  validatePhone,
  dobToReadable,
  dateToReadable,
  useBackButton,
  capitalise,
  interpolateArrays,
  arrayOfNumbers,
  populateMenu,
  sortVenues,
  distanceToVenue,
  venueOpenStatus,
  sortTransactions,
  compareVersions,
  formatPrice,
  getBaseURL,
  isNumeric,
  shuffleArray,
}
