import axios from "axios"

import Firebase from "../firebase"
import Store from "../store"

const BASE_URL = "https://a.klaviyo.com/api"

const performRequest = async (endpoint, body = null, headers = {}) => {
  try {
    const baseUrl = BASE_URL
    const url = baseUrl + endpoint

    const user = Store.store.getState().profile
    const token = Firebase.getConfigValue("crm_public_key")
    if (!user?.email) {
      throw new Error("User in not logged in")
    }

    if (!token) {
      throw new Error("Klaviyo public token is not defined")
    }

    const {
      email: $email,
      firstName: $first_name = "",
      lastName: $last_name = "",
      vagabondId: VagabondID,
      dob,
    } = user

    const params = {
      method: "post",
      url,
      headers: {
        Accept: "text/html",
        "Content-Type": "application/x-www-form-urlencoded",
        ...headers,
      },
    }

    const data = {
      ...(body ?? {}),
      token,
      customer_properties: {
        $email,
        $first_name,
        $last_name,
        VagabondID,
        dob,
      },
    }

    params.data = new URLSearchParams({ data: JSON.stringify(data) })

    const response = await axios(params)

    if (response.status !== 200 || !response.data) {
      throw response
    }

    // console.log("KLAVIO Response: SUCCESS", response, data)

    return response.data
  } catch (error) {
    // console.log("KLAVIO REQUEST ERROR: ", error)
  }
}

const trackEvent = async event => {
  try {
    if (!event) {
      return
    }
    return await performRequest("/track", { event })
  } catch (error) {
    console.log(error)
  }
}

export default {
  trackEvent,
}
