import "intl"
import "intl/locale-data/jsonp/en"
import "react-native-gesture-handler"
import "react-native-match-media-polyfill"
import "./polyfills"

import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import { useFonts } from "@use-expo/font"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import { Platform, StatusBar, View } from "react-native"
import changeNavigationBarColor from "react-native-navigation-bar-color"
import { MenuProvider } from "react-native-popup-menu"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { Provider } from "react-redux"

import Base, { Firebase } from "./base"
import FacebookContainer from "./modules/facebook/facebook-container"
import { queryClient, QueryClientProvider } from "./modules/queryClient"
import Storage from "./modules/store/"
import NavStack from "./Navigation"

// const bgImage = require("./assets/images/somm_bg_white.jpg")
const bgImage = require("./assets/images/subscriptions/subs_landing_web.jpg")

// Needed to prevent text cut-off on a lot of android devices
Base.functions.androidFontFix()

// Uncomment below to clear app storage on next launch
// Storage.clear()

export default function App() {
  changeNavigationBarColor(Base.colours.appWhite)

  let [fontsLoaded] = useFonts({
    "hackney-vector": require("./assets/fonts/Hackney-Vector.ttf"),
    northwell: require("./assets/fonts/northwell.ttf"),
    "gilroy-bold": require("./assets/fonts/Gilroy-Bold.otf"),
    "gilroy-medium": require("./assets/fonts/Gilroy-Medium.otf"),
    "gilroy-regular": require("./assets/fonts/Gilroy-Regular.otf"),
    roboto: require("./assets/fonts/RobotoCondensed-Regular.ttf"),
    fontello: require("./assets/fonts/fontello.ttf"),
  })

  if (!fontsLoaded || (Platform.OS === "web" && Firebase.loading)) {
    return <View />
  }

  return (
    <Provider store={Storage.store}>
      <QueryClientProvider client={queryClient}>
        <SafeAreaProvider>
          <MenuProvider>
            <ActionSheetProvider>
              <FacebookContainer>
                <Helmet>
                  <meta name="theme-color" content={Base.colours.appBrown} />
                </Helmet>
                <NavStack />
              </FacebookContainer>
            </ActionSheetProvider>
          </MenuProvider>
        </SafeAreaProvider>
      </QueryClientProvider>
      <StatusBar
        barStyle="light-content"
        translucent={true}
        backgroundColor={"transparent"}
      />
    </Provider>
  )
}
