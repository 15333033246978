import React, { useCallback, useMemo } from "react"
import { Helmet } from "react-helmet"
import { Text } from "react-native"

import Base, { Firebase, Strings } from "../../base"
import SUBaseScreen from "./SUBaseScreen"

export default function SUTerms({ navigation, route }) {
  const backPressed = useCallback(() => {
    navigation?.pop()
  }, [navigation])

  const text = useMemo(() => {
    const configText = Firebase.getConfigValue("terms_and_conditions")
    return (
      route?.params?.terms ??
      configText ??
      "Unable to fetch terms and conditions"
    )
  }, [route])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appBrown} />
      </Helmet>
      <SUBaseScreen
        pageTitle={Strings.TERMS_SCREEN_TITLE}
        onBackPress={backPressed}
        navigation={navigation}
        showBack={true}
        bgImage={require("../../assets/images/brush_10.png")}
      >
        <Text style={Base.styles.subtitle}>{text}</Text>
      </SUBaseScreen>
    </>
  )
}
