import React from "react"
import { StyleSheet, Text, View } from "react-native"

import base from "../../base"

export default function WebFooter(props) {
  return (
    <View style={[styles.main, props.style]}>
      <Text style={styles.placeholder}>Site Footer</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    paddingVertical: 150,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: base.colours.appBrown,
  },

  placeholder: {
    fontFamily: base.fonts.appRegular,
    fontSize: 25,
    color: "white",
  },
})
