export default {
  // GENERAL
  CANCEL_BUTTON: "Cancel",
  OK_BUTTON: "ok",

  NO_PHONE_DIALOG_TITLE: "Support",
  NO_PHONE_DIALOG_MESSAGE:
    "Your device does not support calling.\n\nPlease dial XXX from another phone.",

  NO_URL_DIALOG_TITLE: "Support",
  NO_URL_DIALOG_MESSAGE: "Unable to open URL, please try again later.",

  NO_EMAIL_DIALOG_TITLE: "Support",
  NO_EMAIL_DIALOG_MESSAGE:
    "Your device has no email accounts setup.\n\nPlease add one and try again.",

  DOB_INVALID: "Date of birth is not valid",
  DOB_UNDER_18: "You must 18 years and older to use this app",

  // HOME
  HOME_LOGIN_DIALOG_TITLE: "Hold Up",
  HOME_LOGIN_DIALOG_MESSAGE: "Please log in to access this section",
  HOME_LOGIN_DIALOG_BUTTON: "Log In",

  // MY WINES
  MY_WINES_RATING_ERROR_TITLE: "Hold Up",
  MY_WINES_RATING_ERROR_MESSAGE:
    "Unable to save your rating, please check your connection and try again.",

  MY_WINES_REMOVE_DIALOG_TITLE: "Remove Liked Wine?",
  MY_WINES_REMOVE_DIALOG_MESSAGE:
    "Are you sure you want to remove this wine from your saved wines?",
  MY_WINES_REMOVE_DIALOG_POSITIVE_ACTION: "Yes",
  MY_WINES_REMOVE_DIALOG_NEGATIVE_ACTION: "No",

  MY_WINES_NO_WINES: "YOUR LIKED WINES WILL APPEAR HERE",

  // PROMO WINES
  PROMO_WINES_FETCH_ERROR_TITLE: "Hold Up",
  PROMO_WINES_FETCH_ERROR_MESSAGE:
    "We couldn't find this promo, please check your connection and try again",

  // EXPLORE
  EXPLORE_TITLE_WEB: "Explore",
  EXPLORE_RECOMMEND_BUTTON: "Recommend wines for me",
  EXPLORE_RECOMMEND_SUBTITLE:
    "Answer some questions and we'll recommend some wines just for you",
  EXPLORE_PREVIOUS_BUTTON: "Previous Recommendations",
  EXPLORE_MENU_BUTTON: "View Our Menu",
  EXPLORE_MENU_SUBTITLE:
    "Browse over 100 wines by the glass and bottle, food, beer & other drinks.",

  EXPLORE_QS_MAX_DIALOG_TITLE: "Hold Up",
  EXPLORE_QS_MAX_DIALOG_MESSAGE:
    "You can pick up to XXX answers for this question",
  EXPLORE_QS_SUBMIT_ERROR_DIALOG_TITLE: "Hold Up",
  EXPLORE_QS_SUBMIT_ERROR_DIALOG_MESSAGE:
    "We were unable to submit your answers, please check your connection and try again.",
  EXPLORE_QS_LOADING_WINES: "Finding your wines...",
  EXPLORE_QS_UP_TO_SUBTITLE: "Pick up to XXX answers",
  EXPLORE_QS_EXACTLY_SUBTITLE: "Pick XXX answers",
  EXPLORE_QS_EXACTLY_1_SUBTITLE: "Pick 1 answer",
  EXPLORE_QS_FINISH_BUTTON: "Finish",

  EXPLORE_RESULTS_TITLE: "DRINKS TO EXPLORE",
  EXPLORE_RESULTS_FIND_TEXT: "Find this wine in our XXX section",
  EXPLORE_RESULTS_FIND_TEXT_FALLBACK:
    "Please ask a team member to find this wine for you",
  EXPLORE_RESULTS_KEEP_GOING_TITLE: "Want to keep exploring?",
  EXPLORE_RESULTS_KEEP_GOING_BUTTON: "Answer some more questions",

  // TASTING NOTES
  TASTING_NOTES_BUTTON: "Tasting notes",
  TASTING_NOTES_RATE_ERROR_DIALOG_TITLE: "Hold Up",
  TASTING_NOTES_RATE_ERROR_DIALOG_MESSAGE:
    "Unable to save your rating, please check your connection and try again.",
  TASTING_NOTES_AVAILABLE_AT_TITLE: "Available at: ",
  TASTING_NOTES_BUY_TITLE: "Buy",
  TASTING_NOTES_BUY_DELIVER_BUTTON: "Deliver to my Door",
  TASTING_NOTES_BUY_COLLECT_BUTTON: "Click and Collect",
  TASTING_NOTES_BUY_TABLE_BUTTON: "Order to my Table",
  TASTING_NOTES_BUY_CLOSE_BUTTON: "Close",

  // MENU
  MENU_TITLE: "MENU",
  MENU_SUBTITLE_WINE:
    "Browse our wines here. To Order at Table, please return to the homepage.",
  MENU_SUBTITLE_FOOD:
    "Browse our food here. To Order at Table, please return to the homepage.",
  MENU_SUBTITLE_OTHER:
    "Browse our other drinks here. To Order at Table, please return to the homepage.",

  MENU_SELECT_LOCATION_BUTTON: "Select Location",
  MENU_CURRENT_LOCATION_BUTTON: "Find my nearest",
  MENU_LOCATION_DENIED_DIALOG_TITLE: "Hold Up",
  MENU_LOCATION_DENIED_DIALOG_MESSAGE:
    "Your location access is disabled, please enable location permission in settings to see near by venues.",
  MENU_LOCATION_GO_BUTTON: "VIEW MENUS",
  MENU_TAB_WINE: "wine",
  MENU_TAB_FOOD: "food",
  MENU_TAB_OTHERS: "others",
  MENU_REDUCED_DISCLAIMER:
    "We are operating a reduced menu, please contact the store directly if you would like more information.",

  // DISPENSING

  DISPENSE_NO_CARD_ERROR_DIALOG_TITLE: "Hold Up",
  DISPENSE_NO_CARD_ERROR_DIALOG_MESSAGE:
    "You must first add a payment card to use this feature.",
  DISPENSE_NO_CARD_ERROR_DIALOG_ADD_BUTTON: "Add Card",
  DISPENSE_NO_CARD_ERROR_DIALOG_LATER_BUTTON: "Maybe Later",

  DISPENSE_LANDING_TITLE: "USING YOUR VAGABOND CARD",
  DISPENSE_LANDING_SUBTITLE:
    "We have just made your life at Vagabond that little easier with our tap and pour app, here's how it works.",
  DISPENSE_LANDING_STEP_1:
    "1. Link to a debit or credit card in the profile section.",
  DISPENSE_LANDING_STEP_2:
    "2. Tap your phone on the machine over the 'tap & pour' button",
  DISPENSE_LANDING_STEP_3: "3. Select your drink and measurement",
  DISPENSE_LANDING_STEP_4:
    "4. Have your glass ready as your drink is poured (important!)",
  DISPENSE_LANDING_FOOTER:
    "You can find these instructions again later in the profile section.",
  DISPENSE_LANDING_DONT_SHOW_AGAIN_BUTTON: "Don't show again",
  DISPENSE_LANDING_CLOSE_BUTTON: "Got it",

  DISPENSE_BAD_TAG_DIALOG_TITLE: "Hold Up",
  DISPENSE_BAD_TAG_DIALOG_MESSAGE:
    "We couldn't find the drink for this position, please contact a team member for help.",
  DISPENSE_UNAVAILABLE_DIALOG_MESSAGE:
    "Sorry, this drink is unavaiable right now. Please try another or contact a member of the team for assistance.",

  DISPENSE_NO_NFC_DIALOG_TITLE: "Hold Up",
  DISPENSE_NO_NFC_DIALOG_MESSAGE:
    "We were unable to connect. Please check if your device supports NFC and try again.",

  DISPENSE_NATIVE_NFC_SUBTITLE: `Hold your device against the "tap & pour" sign for your drink of choice`,

  DISPENSE_WINE: "wine",
  DISPENSE_BEER: "beer",
  DISPENSE_SPARKLING_WINE: "sparkling wine",
  DISPENSE_COCKTAIL: "cocktail",

  DISPENSE_LOADING_DRINK: "Finding your XXX...",
  DISPENSE_POURING_DRINK: "POURING YOUR XXX...",
  DISPENSE_POURING_DRINK_LONGER: "JUST A FEW SECONDS...",
  DISPENSE_POURING_DRINK_LONGER2: "CONNECTING TO YOUR BANK, SIT TIGHT",
  DISPENSE_POURING_DRINK_LONGER3: "STILL DOING OUR THING, NOT LONG NOW",
  DISPENSE_POURING_DRINK_LONGER4: "GOOD THINGS COME TO THOSE WHO WAIT",

  DISPENSE_INSTRUCTIONS_TASTING_NOTES: "View tasting notes",
  DISPENSE_INSTRUCTIONS_COCKTAIL:
    "1. GRAB A GLASS\n2. MAYBE SOME ICE\n3. TOP WITH SOME GARNISHES",

  DISPENSE_TITLE: "READY TO POUR",
  DISPENSE_BUTTON: "POUR\nNOW",
  DISPENSE_WINE_SUBTITLE: "PLACE YOUR GLASS UNDER THE DISPENSER AND TAP BELOW",
  DISPENSE_SPARKLING_WINE_SUBTITLE:
    "GRAB A GLASS, TAP BELOW AND FOLLOW INSTRUCTIONS ON MACHINE TO POUR YOUR WINE",
  DISPENSE_BEER_SUBTITLE:
    "GRAB A GLASS, TAP BELOW AND FOLLOW INSTRUCTIONS ON MACHINE TO POUR YOUR BEER",
  DISPENSE_COCKTAIL_SUBTITLE:
    "PLACE YOUR GLASS UNDER THE DISPENSER AND TAP BELOW",

  DISPENSE_RESULT_SUCCESS: "SWEET, ENJOY YOUR DRINK!",
  DISPENSE_RESULT_GENERIC_ERROR: "OH NO, SOMETHING WENT WRONG!",
  DISPENS_RESULT_CONNECTION_ERROR: "BAD CONNECTION. PLEASE CHECK WIfI OR 4G",

  DISPENSE_SELECT_SIZE_DIALOG_TITLE: "Hold Up",
  DISPENSE_SELECT_SIZE_DIALOG_MESSAGE: "Please select a size to dispense.",

  // BARS
  BARS_TITLE: "VENUES",
  BARS_LOCATION_DENIED_DIALOG_TITLE: "Wait a minute...",
  BARS_LOCATION_DENIED_DIALOG_MESSAGE:
    "Your location access is disabled, please enable location permission in settings to see near by venues.",

  BAR_ITEM_DISTANCE_SUFFIX: " miles",
  BAR_ITEM_OPEN_UNTIL_PREFIX: "Open until",
  BAR_ITEM_DETAILS_BUTTON: "View details",
  BAR_ITEM_BOOK_BUTTON: "Book a table",

  // PROFILE
  PROFILE_CARD_UPDATING: "Updating your card...",
  PROFILE_CARD_REMOVING: "Removing your card...",
  PROFILE_CARD_ERROR_DIALOG_TITLE: "Hold Up",
  PROFILE_CARD_ERROR_DIALOG_MESSAGE:
    "We were unable to save your card, please double check your details including card number, expiry and postcode and try again",

  PROFILE_ADD_CARD_BUTTON: "Add\npayment card",
  PROFILE_UPDATE_CARD_BUTTON: "Manage\nmy card",

  PROFILE_HOW_TO_BUTTON: "How to use\nthe card",

  PROFILE_LOGOUT_BUTTON: "Log out",
  PROFILE_LOGOUT_DIALOG_TITLE: "Confirmation",
  PROFILE_LOGOUT_DIALOG_MESSAGE:
    "Are you sure you'd like to log out of the Vagabond app?",
  PROFILE_LOGOUT_DIALOG_POSITIVE_BUTTON: "Yes, Log Out",

  PROFILE_ACCOUNT_TITLE: "MY ACCOUNT",
  PROFILE_FIRSTNAME_FIELD_TITLE: "First name",
  PROFILE_DOB_FIELD_TITLE: "Date of birth",
  PROFILE_EMAIL_FIELD_TITLE: "Email",
  PROFILE_TERMS_BUTTON: "Terms & Conditions",
  PROFILE_PRIVACY_BUTTON: "Privacy Policy",

  PROFILE_VAT_RECEIPT_FILENAME: "Vagabond VAT Receipt",
  PROFILE_VAT_GENERATING: "Generating your VAT receipt...",
  PROFILE_VAT_NUMBER_LABEL: "VAT No",
  PROFILE_VAT_NUMBER: "994142987",

  PROFILE_TRANSACTIONS_TITLE: "DRINKS HISTORY",
  PROFILE_TRANSACTIONS_SEARCH_PLACEHOLDER: "Search wine or venue",
  PROFILE_TRANSACTIONS_SEARCH_CANCEL: "Cancel",

  PROFILE_TRANSACTIONS_NOTES_ERROR_DIALOG_TITLE: "Hold Up",
  PROFILE_TRANSACTIONS_NOTES_ERROR_DIALOG_MESSAGE:
    "Tasting notes are not available for this transaction sorry.",

  PROFILE_TRANSACTIONS_RATING_ERROR_DIALOG_TITLE: "Hold Up",
  PROFILE_TRANSACTIONS_RATING_ERROR_DIALOG_MESSAGE:
    "Unable to save your rating, please check your connection and try again.",
  PROFILE_TRANSACTIONS_RECEIPT_BUTTON: "VAT Receipt",
  PROFILE_NO_TRANSACTIONS_PLACEHOLDER:
    "Once you start using your card you'll be able to see your Vagabond wine history here, no need to take pictures of the labels anymore.",

  PROFILE_SAVING_CARD: "Saving your card...",

  // SIGN UP
  SIGN_UP_LANDING_TITLE: "SIGN UP/LOG IN",
  SIGN_UP_LANDING_SUBTITLE:
    "Complete the steps below to setup your Vagabond account",
  SIGN_UP_EMAIL_FIELD_TITLE: "Email Address",
  SIGN_UP_EMAIL_FIELD_PLACEHOLDER: "Email",
  SIGN_UP_EMAIL_FIELD_ERROR: "Please enter a valid email address",

  SIGN_UP_EMAIL_INVALID_DIALOG_TITLE: "Hold Up",
  SIGN_UP_EMAIL_INVALID_DIALOG_MESSAGE:
    "We were unable to connect to Vagabond. Please check your connection and try again.",

  SIGN_UP_EMAIL_UNCHECKABLE_DIALOG_TITLE: "Hold Up",
  SIGN_UP_EMAIL_UNCHECKABLE_DIALOG_MESSAGE:
    "We were unable to verify your email is valid, please double check it before registering.",
  SIGN_UP_EMAIL_UNCHECKABLE_DIALOG_BUTTON: "Continue",

  SIGN_UP_LANDING_NEXT_BUTTON: "Continue",
  SIGN_UP_LANDING_TERMS_BUTTON: "T&Cs",

  LOGIN_TITLE: "LOG IN",
  LOGIN_SUBTITLE:
    "Welcome back! Please enter your password below to log into your Vagabond account",
  LOGIN_EMAIL_FIELD_TITLE: "Email Address",
  LOGIN_EMAIL_FIELD_PLACEHOLDER: "Email",
  LOGIN_PASSWORD_FIELD_TITLE: "Password",
  LOGIN_PASSWORD_FIELD_PLACEHOLDER: "Password",
  LOGIN_PASSWORD_FIELD_ERROR: "Please enter a password to login",

  LOGIN_FAILED_DIALOG_TITLE: "Hold Up",
  LOGIN_FAILED_DIALOG_MESSAGE:
    "We were unable to log you in, please check your connection and try again.",
  LOGIN_FAILED_INCORRECT_CREDS_DIALOG_MESSAGE:
    "Your password was incorrect, please check it and try again or select forgotten password.",

  LOGIN_RESET_PASSWORD_BUTTON: "Forgotten password",
  LOGIN_RESET_PASSWORD_FAILED_DIALOG_TITLE: "Hold Up",
  LOGIN_RESET_PASSWORD_FAILED_DIALOG_MESSAGE:
    "We were unable to reset your password. Please check your connection and try again.",
  LOGIN_RESET_PASSWORD_SUCCESS:
    "If there is an account associated with XXX you will receive an email with a reset password link",

  LOGIN_BUTTON: "Log In",
  LOGIN_TERMS_BUTTON: "T&Cs",
  LOGIN_LOADING: "Logging you in...",

  PRIVACY_SCREEN_TITLE: "PRIVACY POLICY",
  TERMS_SCREEN_TITLE: "TERMS & CONDITIONS",

  REGISTER_TITLE: "SIGN UP",
  REGISTER_NEXT_BUTTON: "Next",

  REGISTER_NAME_FIELD_TITLE: "First name",
  REGISTER_NAME_FIELD_PLACEHOLDER: "Name",
  REGISTER_NAME_FIELD_ERROR: "First name is missing",

  REGISTER_LAST_NAME_FIELD_TITLE: "Last name",
  REGISTER_LAST_NAME_FIELD_PLACEHOLDER: "Name",
  REGISTER_LAST_NAME_FIELD_ERROR: "Last name is missing",

  REGISTER_DOB_FIELD_TITLE: "Date of birth",
  REGISTER_DOB_FIELD_PLACEHOLDER: "DD/MM/YYYY",
  REGISTER_DOB_PICKER_TITLE: "Choose your date of birth",

  REGISTER_PASSWORD_FIELD_TITLE: "Password",
  REGISTER_PASSWORD_FIELD_PLACEHOLDER: "Password",
  REGISTER_PASSWORD_FIELD_ERROR: "Please enter a password to login",

  REGISTER_EMAIL_FIELD_TITLE: "Email address",
  REGISTER_EMAIL_FIELD_PLACEHOLDER: "Email",
  REGISTER_PASSWORD_SUBTITLE:
    "Password needs to be at least 8 characters, including 1 number and 1 capital letter",

  REGISTER_CONFIRM_PASSWORD_FIELD_TITLE: "Confirm Password",
  REGISTER_CONFIRM_PASSWORD_FIELD_PLACEHOLDER: "Confirm Password",

  REGISTER_FAILED_DIALOG_TITLE: "Hold Up!",
  REGISTER_FAILED_DIALOG_MESSAGE:
    "We were unable to sign you up. Please check your connection and try again.",

  REGISTER_PASSWORDS_DONT_MATCH: "Passwords do not match",

  REGISTER_OPT_IN_DISCLAIMER:
    "If you would like us to let you know when new wines arrive that we think you would like tap here",
  REGISTER_CONFIRM_BUTTON: "Done",
  REGISTER_LOADING: "Signing you up...",

  // ONBOARDING
  ONBOARDING_WELCOME_TITLE: "Welcome to the\nVagabond app",
  ONBOARDING_WELCOME_TAP_AND_POUR_SUBTITLE:
    'To dispense drinks in venue directly from your phone and other features, please click on "Let’s Tap and Pour"',
  ONBOARDING_WELCOME_TAP_AND_POUR_CTA: "Let’s Tap and Pour!",
  ONBOARDING_WELCOME_SUBSCRIPTIONS_SUBTITLE:
    'To go to our fun and unique wine subscription service, Vagabond SOMM, please click on "Take me to Subscription"',
  ONBOARDING_WELCOME_SUBSCRIPTIONS_CTA: "Take me to Subscription",
  ONBOARDING_DISPENSING: "Once you find your drink, \ntap here to dispense",
  ONBOARDING_EXPLORE:
    "   Need wine inspiration? \nClick here to browse our current range\n  or get recommendations just for you",
  ONBOARDING_BARS:
    "Click here to view our venues, \nopening times and make bookings",
  ONBOARDING_PROFILE:
    "Go here to register or update \nyour payment method. \nYou can see your \ndrinks history here too",
  ONBOARDING_CARD:
    "      Click here to add or update\n     your payment method",
  ONBOARDING_TRANSACTION_SESSION:
    "         Tap on a session to view\n    your drinks history, tasting notes \nor generate VAT receipts",
  ONBOARDING_TASTING_NOTES:
    "Click here to view more \ninformation about the wine",
  ONBOARDING_BUY:
    "Click here to order a bottle: \n•  to your table \n•  to get delivered to your door",
  ONBOARDING_RATE:
    "Love or hate? Click here \n  to like or dislike a drink. \nYou can view your liked drinks \n    on the homepage",
  ONBOARDING_VAT:
    "Click here to download a PDF \nversion of a VAT receipt \nfrom your visit",
  ONBOARDING_SUBSCRIPTION:
    "        Sign up and manage\n  your Vagabond SOMM\n  by-the-glass Wine Subscription",

  // BUY WINE

  BUY_VENUE_SELECTOR_TITLE: "Select a venue to order from",

  BUY_NO_WINE_DIALOG_TITLE: "Hold Up",
  BUY_NO_WINE_DIALOG_MESSAGE:
    "Sorry, ordering is not available for this wine. Please ask a team member for an alternative recommendation.",

  BUY_DELIVER_ERROR_DIALOG_TITLE: "Hold Up",
  BUY_DELIVER_ERROR_DIALOG_MESSAGE:
    "Sorry this wine is currently unavailable for home delivery.",

  BUY_ORDER_IN_VENUE_ERROR_DIALOG_TITLE: "Hold Up",
  BUY_ORDER_IN_VENUE_ERROR_DIALOG_MESSAGE:
    "Sorry this wine is currently not available to order at XXX, please ask a team member for an alternative recommendation.",

  BUY_VENUE_OAT_ERROR_DIALOG_TITLE: "Hold Up",
  BUY_VENUE_OAT_ERROR_DIALOG_MESSAGE:
    "Sorry ordering online is currently unavailable at XXX, please ask a member of staff or try again later.",

  // SUBSCRIPTION

  SUB_GENERAL_PAGE_TITLE: "SUBSCRIPTIONS",

  SUB_LANDING_TITLE: "VAGABOND SOMM",
  SUB_LANDING_SUBTITLE:
    "Confidently explore the world of wine with Vagabond SOMM, the world’s first truly personalised wine subscription service. \n\nAt just £25 per box, you'll receive five 100ml bottles of wine to a schedule you choose.  Mini bottles make it fun and easy to explore wine, or have a guilt-free glass mid week. \n\nBetter still, each wine will be chosen specifically for you.  Simply like/dislike wines in the app and our picks will get better and better.  \n\nOr you can ignore us and pick your own wines.  We don’t mind!",
  SUB_LANDING_EXISTING_BUTTON: "Already a subscriber?",
  SUB_LANDING_LOG_IN: "Log in",
  SUB_LANDING_NEW_BUTTON: "Let's get started!",

  SUB_PRE_SURVEY_RUN_SURVEY: "Let's go!",
  SUB_PRE_SURVEY_DESCRIPTION:
    "Now let’s learn a bit about you. \n\nFirst, we’ll ask you a bunch of questions about wine.  What traits do you like, how adventurous are you?  That sort of thing. \n\nNext, how often do you want your SOMM boxes delivered – once or twice a month? \n\nFinally, the boring admin stuff like email address and card details.",

  SUB_MY_SUB_TITLE: "MY SUBSCRIPTION",
  SUB_MY_SUB_UPCOMING_BUTTON: "Next Box",
  SUB_MY_SUB_PAST_BUTTON: "Previous Boxes",
  SUB_MY_SUB_PROFILE_BUTTON: "My Wine Profile",
  SUB_MY_SUB_LIKED_BUTTON: "My Liked Wines",

  SUB_MY_SUB_FLIGHT_BOX_TITLE: "GIVE THE GIFT OF WINE",
  SUB_MY_SUB_FLIGHT_BOX_SUBTITLE:
    "Know someone who'd love to receive a Vagabond SOMM subscription?",
  SUB_MY_SUB_FLIGHT_BOX_BUTTON: "Gift a Subscription",
  SUB_MY_SUB_FLIGHT_BOX_ERROR_DIALOIG_TITLE: "Sorry",
  SUB_MY_SUB_FLIGHT_BOX_ERROR_DIALOIG_SUBTITLE:
    "We're working as quickly as we can to introduce this new feature to the app, but it isn't available yet.",

  SUB_UPCOMING_BOX_TITLE: "YOUR NEXT BOX",
  SUB_UPCOMING_BOX_SUBTITLE:
    "Here are the wines in your next box. You have until midnight on Sunday prior to box dispatch to make changes.",
  SUB_UPCOMING_BOX_NO_BOX:
    "Your next box isn't quite ready yet.  We'll let you know when it's available to view",
  SUB_UPCOMING_BOX_EDIT_BUTTON: "Edit your selection",
  SUB_UPCOMING_BOX_CONFIRM_BUTTON: "Confirm",

  SUB_PAST_BOXES_TITLE: "PREVIOUS BOXES",
  SUB_PAST_BOXES_NONE:
    "There aren't any previous boxes.  Once you start receiving boxes, this is where you go to see previous boxes you've had.",
  SUB_PAST_BOXES_LIKED_BUTTON: "See my liked wines",

  SUB_WINE_PROFILE_TITLE: "YOUR WINE PROFILE",
  SUB_WINE_PROFILE_SUBTITLE: "Does this still sound like you?",
  SUB_WINE_PROFILE_EDIT_BUTTON: "Change Profile",
  SUB_WINE_PROFILE_CONFIRM_DIALOG_TITLE:
    "Would you like to modify your current profile?",
  SUB_WINE_PROFILE_CONFIRM_DIALOG_SUBTITLE:
    "FYI, this may change your upcoming boxes",

  SUB_MANAGE_SUB_TITLE: "MANAGE SUBSCRIPTION",
  SUB_MANAGE_SUB_CANCEL_BUTTON: "Cancel Subscription",
  SUB_MANAGE_SUB_PAUSE_BUTTON: "Skip next box",
  SUB_MANAGE_SUB_UNPAUSE_BUTTON: "Unskip next box",

  SUB_WINE_SELECTOR_ADD_WINE_LABEL: "Add a wine from the menu, or",
  SUB_WINE_SELECTOR_SURPRISE_ME_LABEL: "Surprise Me!",
  SUB_CANCEL_SUB_TITLE: "CANCEL SUBSCRIPTION",
  SUB_CANCEL_SUB_SUBTITLE: "Are you sure? We'd hate to see you go.",
  SUB_CANCEL_PAUSE_SUBTITLE:
    "We'd hate to see you go.\nDid you know you can skip your next box?",
  SUB_CANCEL_SUB_CONTINUE_BUTTON: "Cancel Subscription",
  SUB_CANCEL_SUB_KEEP_BUTTON: "Keep Subscription",

  SUB_CANCEL_SUB_REASON_SUBTITLE:
    "Please select a reason. \nThis will help make our service better.",
  SUB_CANCEL_SUB_REASON_CONFIRM_BUTTON: "Continue",

  SUB_CANCEL_SUB_CONFIRMED_TITLE: "Your subscription \nhas been cancelled",
  SUB_CANCEL_SUB_CONFIRMED_SUBTITLE:
    "We're sorry to see you go, but thanks for giving us a try.\n",
  SUB_CANCEL_SUB_CONFIRMED_REACTIVATE_EMAIL_PREFIX: "Please email\n",
  SUB_CANCEL_SUB_CONFIRMED_REACTIVATE_EMAIL: "subscription@vagabondwines.co.uk",
  SUB_CANCEL_SUB_CONFIRMED_REACTIVATE_EMAIL_SUFFIX:
    "\nto reactivate the service, and hopefully we'll see you again!",
  SUB_CANCEL_SUB_CONFIRMED_BUTTON: "Okay",

  SUB_PAUSE_SUB_TITLE: "SKIP NEXT BOX",
  SUB_PAUSE_SUB_UNPAUSE_TITLE: "UNSKIP NEXT BOX",
  SUB_PAUSE_SUB_SUBTITLE:
    "Need a break from tasting wine? You can skip your next box by clicking below.",
  SUB_PAUSE_SUB_UNPAUSE_SUBTITLE:
    "You chose to skip your next box. You can undo this by clicking below",
  SUB_PAUSE_SUB_CURRENT_PREFIX: "Your next box will be dispatched on",
  SUB_PAUSE_SUB_CURRENT_PREFIX_UNPAUSED: "Your dispatch date is:",
  SUB_PAUSE_SUB_NEXT_PREFIX:
    "If you chose to skip your box, the new dispatched date will be:",
  SUB_PAUSE_SUB_NEXT_UNPAUSE_PREFIX:
    "If you chose to unskip your next box, the new dispatched date will be:",
  SUB_PAUSE_SUB_BUTTON_TITLE: "Skip next box",
  SUB_PAUSE_SUB_BUTTON_CURRENT_TITLE: "Current dispatch date",
  SUB_PAUSE_SUB_BUTTON_NEXT_TITLE: "Dispatch date after skipping box",

  SUB_PAUSED_SUCCESS_SUBTITLE: "Your subscription is now paused",
  SUB_UNPAUSED_SUCCESS_SUBTITLE: "You have unskipped your box",
  SUB_PAUSED_SUCCESS_BUTTON: "Okay",

  SUB_QUESTIONS_TITLE: "QUESTION ",
  SUB_QUESTIONS_FINISH_BUTTON: "Finish",
  SUB_QUESTIONS_SUBMIT_ERROR:
    "An issue occurred and we are unable to continue setting up your account, please contact vagabond at info@vagabond.co.uk and we will be able to help further.",

  SUB_QUESTIONS_SUMMARY_TITLE: "YOUR SUMMARY",
  SUB_QUESTIONS_SUMMARY_SUBTITLE: "Does this sound like you?",
  SUB_QUESTIONS_SUMMARY_EDIT_BUTTON: "Hmm, not quite right",
  SUB_QUESTIONS_SUMMARY_NEXT_BUTTON: "Yes, sounds like me",
  SUB_QUESTIONS_SUMMARY_EXISTING_ANSWERS_DIALOG_TITLE:
    "Great. We’ll use your updated profile to pick some amazing wines for your future boxes",
  SUB_QUESTIONS_SUMMARY_EXISTING_ANSWERS_DIALOG_SUBTITLE:
    "Although your next box has already been packed so we can’t make any changes to that one.",

  SUB_SUGGESTED_WINES_TITLE: "Your upcoming wine box",
  SUB_SUGGESTED_WINES_EDIT_BUTTON: "Let's edit",
  SUB_SUGGESTED_WINES_NEXT_BUTTON: "Sounds good",

  SUB_EDIT_WINES_TITLE: "SELECT YOUR WINES",
  SUB_EDIT_WINES_SUBTITLE: `You can select wines using our handy little browsing tool below. \nOr, if you'd prefer we pick wines for your empty positions, please select "surprise me!"`,
  SUB_EDIT_WINES_NO_RESULTS:
    "We're so sorry.  We don't currently have any wines that match your search.  Please try again.\nAnd fear not, we're always adding new wines.  ",
  SUB_EDIT_WINES_CLEAR_BUTTON: "Clear",
  SUB_EDIT_WINES_SURPRISE_BUTTON: "Surprise Me!",
  SUB_EDIT_WINES_BOX_FULL_TITLE: "Sorry, your box is already full.",
  SUB_EDIT_WINES_BOX_FULL_SUBTITLE:
    "Please remove a wine first if you'd like to add another.",
  SUB_EDIT_WINES_BOX_NOT_FULL_TITLE:
    "You have one or more wines to add to you box still",
  SUB_EDIT_WINES_BOX_NOT_FULL_SUBTITLE:
    "We can add wines we think you'll like, or you can keep browsing.",

  SUB_FREQUENCY_TITLE: "FREQUENCY OF DELIVERIES",
  SUB_FREQUENCY_SUBTITLE:
    "You can choose to receive your deliveries once or twice a month.",
  SUB_FREQUENCY_CONTACT_INFO:
    "We'll send you an email when your box has been dispatched",
  SUB_FREQUENCY_MONTHLY_BUTTON: "Every four weeks",
  SUB_FREQUENCY_BI_WEEKLY_BUTTON: "Every two weeks",
  SUB_FREQUENCY_DONE_BUTTON: "Continue",

  SUB_DELIVERY_ADDRESS_TITLE: "DELIVERY",
  SUB_DELIVERY_ADDRESS_SUBTITLE:
    "Please add the delivery address for your subscription.",
  SUB_DELIVERY_ADDRESS_SEARCH_FIELD_TITLE: "Search Address",
  SUB_DELIVERY_ADDRESS_SEARCH_FIELD_PLACEHOLDER:
    "Postcode or First Line of Address",
  SUB_DELIVERY_ADDRESS_SEARCH_EMPTY: "No results found...",

  SUB_DELIVERY_ADDRESS_ENTER_MANUALLY: "Or enter manually",

  SUB_DELIVERY_ADDRESS_LINE_1_FIELD_TITLE: "Address Line 1 *",
  SUB_DELIVERY_ADDRESS_LINE_1_FIELD_PLACEHOLDER: "Address Line 1",

  SUB_DELIVERY_ADDRESS_LINE_2_FIELD_TITLE: "Address Line 2",
  SUB_DELIVERY_ADDRESS_LINE_2_FIELD_PLACEHOLDER: "Address Line 2",

  SUB_DELIVERY_ADDRESS_CITY_FIELD_TITLE: "City *",
  SUB_DELIVERY_ADDRESS_CITY_FIELD_PLACEHOLDER: "City",

  SUB_DELIVERY_ADDRESS_POSTCODE_FIELD_TITLE: "Postcode *",
  SUB_DELIVERY_ADDRESS_POSTCODE_FIELD_PLACEHOLDER: "Postcode",

  SUB_DELIVERY_ADDRESS_FIRST_NAME_FIELD_TITLE: "First name *",
  SUB_DELIVERY_ADDRESS_FIRST_NAME_FIELD_PLACEHOLDER: "First name",

  SUB_DELIVERY_ADDRESS_LAST_NAME_FIELD_TITLE: "Last name *",
  SUB_DELIVERY_ADDRESS_LAST_NAME_FIELD_PLACEHOLDER: "Last name",

  SUB_DELIVERY_ADDRESS_PHONE_FIELD_TITLE: "Phone Number *",
  SUB_DELIVERY_ADDRESS_PHONE_FIELD_PLACEHOLDER: "Mobile or Home Phone Number",

  SUB_DELIVERY_ADDRESS_NOTES_FIELD_TITLE: "Delivery Notes",
  SUB_DELIVERY_ADDRESS_NOTES_FIELD_PLACEHOLDER:
    "Add optional delivery notes (e.g. Gate code is 123)",

  SUB_DELIVERY_ADDRESS_REQUIRED_DISCLAIMER: "(*) indicates a required field",
  SUB_DELIVERY_ADDRESS_NEXT_BUTTON: "Continue",
  SUB_DELIVERY_ADDRESS_POSTCODE_FIELD_INVALID: "Postcode",
  SUB_DELIVERY_ADDRESS_PHONE_FIELD_INVALID: "Phone Number",

  SUB_SUMMARY_TITLE: "SUMMARY",
  SUB_SUMMARY_TOTAL: "Total for this box",
  SUB_SUMMARY_CONFIRM_BUTTON: "Let's do this",
  SUB_SUMMARY_PER_BOX_PRICE: "Subscription box",
  SUB_SUMMARY_ADDITIONAL_PREMIUM_FEE: "Premium top ups (this box only)",
  SUB_SUMMARY_GIFT_CARD_DISCOUNT: "Gift Card",
  SUB_SUMMARY_TOTAL_BOX_PRICE: "Total for this box",
  SUB_SUMMARY_SUBMIT_DESCRIPTION: "Don't worry you can cancel at any time.",
  SUB_SUMMARY_SUBMIT_HELP: `By selecting "Start Subscription" you're signing up for a Vagabond SOMM box subscription. Each box will cost £25 unless you add some premium wines, and will be delivered to the address and at the frequency stated above. You can cancel at any point in the app by visiting "Manage my Subscription"`,
  SUB_DELIVERY_DISPATCH_DATE_HELP:
    "We dispatch each week on Wednesdays. If you have any queries please contact subscription@vagabondwines.co.uk. All deliveries are free of charge.",

  SUB_TERMS_TITLE: "TERMS & CONDITIONS",

  SUB_ORDER_CONFIRMED_TITLE: "ORDER CONFIRMATION",
  SUB_ORDER_CONFIRMED_SUBTITLE:
    "Congratulations.  You're all set and ready for your Vagabond SOMM wine subscription service.  We hope you discover great new wines!",
  SUB_ORDER_CONFIRMED_BUTTON: "Okay",

  SUB_FAQS_TITLE: "FAQS",
  SUB_FAQS_SUBTITLE:
    "Everything you need to know about the Vagabond SOMM subscription",
  SUB_FAQ_TERMS: "T&Cs",

  // SubscriptionProhibition.SubscriptionProhibition
  SUB_PROHIBITION_MODAL_TITLE: "Sorry, our beta test is fully subscribed",
  SUB_PROHIBITION_MODAL_DESCRIPTION:
    "Please register your email if you'd like to be contacted when our subscription service is ready for prime time",
  SUB_PROHIBITION_MODAL_EMAIL_PLACEHOLDER: "Your email",
  SUB_PROHIBITION_MODAL_CANCEL: "No thanks",
  SUB_PROHIBITION_MODAL_SUBMIT: "Let me know",

  SUB_CONFIRM_CLOSE_JOURNEY_TITLE: "Are you sure you want to exit?",
  SUB_CONFIRM_CLOSE_JOURNEY_SUBTITLE: "Your progress will not be saved",
  SUB_CONFIRM_CLOSE_SUMMARY_SUBTITLE:
    "Your progress will be lost and you won’t be charged.",
}
