import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import Icon from "react-native-vector-icons/AntDesign"

import Base, { API, Contentful } from "../../base"
import Gap from "./Gap"

export default function VenuePicker({
  visible = false,
  onVenueSelected,
  style,
  useDistance = false,
  title = "SELECT A VENUE",
  onClose,
  hideOpeningHours,
  venueQuery,
  showClose = true,
}) {
  const [venues, setVenues] = useState([])
  const [animatedIn, setAnimatedIn] = useState(false)

  const closeTouched = useCallback(() => {
    setAnimatedIn(false)
    onClose()
  }, [setAnimatedIn, onClose])

  const getVenues = useCallback(async () => {
    const _venues = await Contentful.getContent(
      Contentful.VENUES,
      1000,
      1000,
      venueQuery,
    )
    if (_venues?.length > 0) {
      _venues.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      setVenues(_venues)
    }
  }, [setVenues, venueQuery])

  useEffect(() => {
    getVenues()
  }, [getVenues])

  return (
    <>
      <Helmet>
        <meta
          name="theme-color"
          content={animatedIn ? "#5b201c" : Base.colours.appRed}
        />
      </Helmet>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={closeTouched}
        onShow={() => setAnimatedIn(true)}
        onDismiss={() => setAnimatedIn(false)}
      >
        <View style={[styles.header, { backgroundColor: "#00000099" }]}>
          <View style={styles.whiteBack} />
          <ScrollView
            style={[styles.main, style]}
            contentContainerStyle={styles.container}
            showsVerticalScrollIndicator={false}
          >
            <View style={styles.card}>
              {!!showClose && (
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={closeTouched}
                  activeOpacity={0.8}
                  hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                >
                  <Icon name={"close"} color={Base.colours.appRed} size={28} />
                </TouchableOpacity>
              )}
              <Text style={styles.title}>{title}</Text>
              {(venues ?? []).map((venue, index) => {
                return (
                  <TouchableOpacity
                    style={styles.venueRow}
                    key={index}
                    onPress={() => onVenueSelected(venue)}
                    activeOpacity={0.9}
                  >
                    <Text style={styles.venueIndex}>{index + 1}.</Text>
                    <View style={styles.venueInfo}>
                      <Text style={styles.venueTitle}>{venue?.name}</Text>
                      <Text style={styles.venueDistance}>
                        {useDistance ? "XX miles away" : venue?.postcode}
                        {hideOpeningHours
                          ? ""
                          : ", " + Base.functions.venueOpenStatus(venue)}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )
              })}
              <Gap size={40} />
            </View>
          </ScrollView>
        </View>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },

  container: {
    flexGrow: 1,
  },

  header: {
    height: "100%",
    width: "100%",
  },

  whiteBack: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%",
    backgroundColor: Base.colours.appWhite,
  },

  card: {
    backgroundColor: Base.colours.appWhite,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    marginTop: 100,
    paddingHorizontal: 24,
    alignItems: "center",
  },

  closeButton: {
    position: "absolute",
    right: 22,
    top: 22,
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 44,
    lineHeight: 44,
    color: Base.colours.appBrown,
    marginTop: 34,
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: "center",
  },

  venueRow: {
    flexDirection: "row",
    borderBottomColor: Base.colours.appVeryLightGrey,
    borderBottomWidth: 1,
    paddingVertical: 10,
    width: "100%",
  },

  venueIndex: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 27,
    lineHeight: 27,
    color: Base.colours.appBrown,
    marginRight: 10,
    marginTop: 3,
  },

  venueInfo: {
    flex: 1,
  },

  venueTitle: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 30,
    lineHeight: 30,
    color: Base.colours.appRed,
    marginBottom: 6,
  },

  venueDistance: {
    fontFamily: Base.fonts.appMedium,
    fontSize: 16,
    color: Base.colours.appBrown,
  },
})
