import { QueryClient, setLogger } from "react-query"

import Alert from "../views/common/Alert"

export { QueryClientProvider } from "react-query"

/** Initialized react-query client instance. */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // store responses at least 3 sec in the cache.
      staleTime: 3000,
      // Disable automatic refetching.
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      // Show alert with error message on error received by default.
      // Put onError: () => null in a hook if you want avoid showing the default popup.
      onError: error => Alert.show(error.name, error.message),
    },
    mutations: {
      // Show alert with error message on error received by default.
      // Put onError: () => null in a hook if you want avoid showing the default popup
      onError: error => Alert.show(error.name, error.message),
    },
  },
})

// Use console as a logger.
setLogger({
  log: console.log,
  warn: console.warn,
  error: console.error,
})

export const clearAndResetQueries = (queries = []) => {
  queries?.forEach(query => {
    queryClient.resetQueries(query)
    queryClient.removeQueries(query)
  })
}
