import moment from "moment"
import React, { useCallback, useMemo, useRef, useState } from "react"
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import Icon from "react-native-vector-icons/MaterialCommunityIcons"

import Base from "../../../base"
import DateInputWeb from "./DateInputWeb"

const FormField = React.forwardRef(
  (
    {
      value,
      multiline,
      isValid,
      style,
      fieldStyle,
      error,
      enabled = true,
      type = "default",
      success,
      leftView,
      onLeftViewTouched,
      rightView,
      onRightViewTouched,
      returnKeyType,
      editable,
      secure,
      placeholder,
      loading,
      showClearButton,
      title,
      subtitle,
      onFocus,
      onChange,
      onChangeText,
      onSubmit,
      onClear,
      onBlur,
      index,
      dataSet,
      fieldDataSet,
    },
    ref,
  ) => {
    const [_secure, setSecure] = useState(secure ?? false)
    const [inFocus, setInFocus] = useState(false)

    const hasText = useMemo(() => {
      value != null && typeof value !== "undefined" && value?.length > 0
    }, [value])

    const secureToggled = useCallback(() => {
      setSecure(!_secure)
    }, [_secure, setSecure])

    const rowStyle = useMemo(() => {
      const _style = {
        alignItems: multiline ? "flex-start" : "center",
      }
      if (isValid) {
        _style.backgroundColor = Base.colours.appWhite
      }
      if (!enabled) {
        _style.opacity = 0.5
      }
      return _style
    }, [isValid, enabled, multiline])

    return (
      <View style={[styles.main, style]} dataSet={{ media: dataSet }}>
        {!!title && <Text style={[styles.title]}>{title}</Text>}
        <TouchableOpacity
          style={[styles.row, rowStyle]}
          onPress={() =>
            type === "button" ? onFocus(index ?? 0) : ref?.current?.focus()
          }
          activeOpacity={1}
        >
          {!!leftView && (
            <TouchableOpacity
              style={[styles.accessoryView, styles.leftView]}
              onPress={onLeftViewTouched && onLeftViewTouched}
            >
              {leftView}
            </TouchableOpacity>
          )}
          {type === "date" && Platform.OS === "web" ? (
            <DateInputWeb
              value={moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")}
              style={styles.datePicker}
              onChange={onChangeText}
            />
          ) : (
            <TextInput
              ref={ref}
              style={[styles.textfield, fieldStyle]}
              value={value}
              returnKeyType={returnKeyType ?? "default"}
              autoCapitalize={"none"}
              dataSet={{ media: fieldDataSet }}
              editable={editable ?? enabled ?? false}
              selectionColor={Base.colours.appGrey}
              autoCompleteType={"off"}
              autoCorrect={false}
              multiline={multiline ?? false}
              keyboardType={
                type && type !== "button" && type !== "date" ? type : "default"
              }
              placeholder={placeholder}
              onSubmitEditing={() => {
                setInFocus(false)
                onSubmit && onSubmit()
              }}
              onChangeText={onChangeText}
              onBlur={onBlur}
              onChange={({ nativeEvent }) => {
                onChange && onChange(index ?? 0, nativeEvent)
              }}
              secureTextEntry={_secure}
              onEndEditing={() => setInFocus(false)}
              onFocus={() => {
                setInFocus(true)
                onFocus && onFocus(index ?? 0)
              }}
              placeholderTextColor={Base.colours.appGrey}
            />
          )}
          {!!secure && (
            <TouchableOpacity onPress={secureToggled}>
              <Icon
                name={_secure ? "eye-off-outline" : "eye-outline"}
                size={24}
                color={"black"}
                style={styles.inputIcon}
              />
            </TouchableOpacity>
          )}
          {!!rightView && (
            <TouchableOpacity
              style={styles.accessoryView}
              onPress={onRightViewTouched && onRightViewTouched}
            >
              {rightView}
            </TouchableOpacity>
          )}
          {!!isValid && !secure && !error && (
            <Icon
              name={"check"}
              size={24}
              color={Base.colours.appBrown}
              style={styles.inputIcon}
            />
          )}
          {!!loading && (
            <ActivityIndicator
              size="small"
              color={Base.colours.appSemiLightGrey}
            />
          )}
          {!!showClearButton && hasText && (
            <TouchableOpacity style={styles.accessoryView} onPress={onClear}>
              <Icon
                name={"x"}
                size={22}
                color={Base.colours.appSemiLightGrey}
              />
            </TouchableOpacity>
          )}
        </TouchableOpacity>
        {!!error && <Text style={styles.errorTitle}>{error}</Text>}
        {!!success && (
          <Text style={[styles.errorTitle, { color: Base.colours.appGreen }]}>
            {success}
          </Text>
        )}
        {!error && !success && !!subtitle && (
          <Text style={[styles.errorTitle, { color: Base.colours.appGrey }]}>
            {subtitle}
          </Text>
        )}
      </View>
    )
  },
)

export default FormField

const styles = StyleSheet.create({
  main: {
    width: "100%",
    maxWidth: 500,
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    marginBottom: 8,
    color: Base.colours.appBrown,
  },

  accessoryView: {
    marginLeft: 4,
    height: "100%",
    justifyContent: "center",
  },

  errorTitle: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    color: Base.colours.appRed,
    textAlign: "center",
    marginTop: 8,
    minHeight: 16,
  },

  textfield: {
    flex: 1,
    paddingHorizontal: 14,
    marginLeft: 3,
    marginBottom: 1,
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    height: 50,
    borderRadius: 100,
  },

  line: {
    position: "absolute",
    bottom: 0,
    height: 2,
    backgroundColor: Base.colours.appBrown,
    width: "100%",
  },

  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Base.colours.appWhite,
    borderRadius: 100,
    width: "100%",
  },
  leftView: { marginRight: 4 },
  inputIcon: { marginRight: 12 },

  datePicker: {
    borderColor: "transparent",
    marginLeft: 16,
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    color: Base.colours.appBlack,
    elevation: 0,
    shadowOpacity: 0,
  },
})
