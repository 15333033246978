import axios from "axios"
import * as Facebook from "expo-facebook"
import moment from "moment"
import { Platform } from "react-native"

import Firebase from "../firebase"
import Store from "../store"

const CryptoJS = require("crypto-js")

const GET = "get"
const POST = "post"
const PATCH = "patch"
const DELETE = "delete"

async function performRequest(method, endpoint, body = null, headers = {}) {
  try {
    const baseUrl = Firebase.getConfigValue("api_url") // "http://0.0.0.0:5000/vagabond-app-b5c6c/europe-west1/api"
    const url = baseUrl + endpoint

    var params = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    }
    if (body !== null) {
      params.data = JSON.stringify(body)
    }

    const user = Firebase.getUser()
    if (user) {
      const idToken = await user.getIdToken()
      params.headers.authorization = "Bearer " + idToken
      console.log("ID_TOKEN:", idToken)
    }

    // console.log('REQUEST PARAMS: ', params)
    return axios(params)
      .then(response => {
        // console.log('RESPONSE: ', JSON.stringify(response.data));
        if (response) {
          return response.data
        }
        return null
      })
      .catch(error => {
        console.log("API request error", { ...error })
        return null
      })
  } catch (error) {
    // console.log("REQUEST ERROR: ", error)
  }
}

async function emailCheck(email) {
  try {
    return await performRequest(POST, "/v2/emailCheck", { email })
  } catch (error) {
    return { error }
  }
}

async function getProfile() {
  try {
    let profile = await performRequest(GET, "/v2/profile", null)
    return { profile, error: !profile?.email && "No profile returned" }
  } catch (error) {
    return { error }
  }
}

async function setProfile(body) {
  try {
    let profile = await performRequest(POST, "/v2/profile", body)
    return { profile, error: !profile?.email && "No profile returned" }
  } catch (error) {
    return { error }
  }
}

async function register(user, body) {
  try {
    const saveResponse = await setProfile(body)
    if (saveResponse.profile) {
      console.log("REGISTERED PROFILE: ", saveResponse.profile)
      const profile = await registerCheckAccount(user)
      return { profile, error: !profile?.email && "No profile returned" }
    }
    console.log("NO PROFILE ERROR: ", saveResponse)
  } catch (error) {
    return { error }
  }
}

let errorCount = 0
async function registerCheckAccount(user) {
  if (errorCount > 5) {
    errorCount = 0
    console.log("ERROR COUNT REACHED, FAILING")
    return { error: "Get profile request failed multiple times" }
  }
  console.log("waiting...")
  await new Promise(resolve => setTimeout(resolve, 2000))
  const response = await getProfile(user)
  console.log("get profile: ", response.profile)
  if (response.profile?.vagabondId) {
    return response.profile
  }
  console.log("INCREASING ERROR COUNT.")
  errorCount++
  return await registerCheckAccount(user)
}

async function getExploreQuestions() {
  try {
    let response = await performRequest(GET, "/v1/explore")
    return { questions: response }
  } catch (error) {
    return { error }
  }
}

async function getPreviousAnswers() {
  try {
    let response = await performRequest(GET, "/v1/explore/recommendations")
    return {
      results: response,
      error: response.results ? null : "No results returned",
    }
  } catch (error) {
    return { error }
  }
}

async function saveExploreQuestions(questions) {
  try {
    const answers = questions.map(q => {
      return {
        id: q.id,
        sliderValue: q.sliderValue ?? 0.0,
        answers: q.answers.map(a => a.id),
      }
    })

    let response = await performRequest(POST, "/v1/explore", answers)
    return {
      results: response,
      error: response.results ? null : "No results returned",
    }
  } catch (error) {
    return { error }
  }
}

async function rateWine(wine, rating) {
  try {
    const body = {
      sku: wine.sku,
      rating,
    }
    let response = await performRequest(POST, "/v2/rateWine", body)
    return {
      result: response,
      error: response == null ? "No user returned" : null,
    }
  } catch (error) {
    return { error }
  }
}

async function shopifyURLForWine(wine) {
  try {
    const response = await performRequest(POST, "/v2/shopifyURL", {
      sku: wine.sku,
    })
    return {
      url: response?.url,
      error: response?.url == null ? "No url returned" : null,
    }
  } catch (error) {
    return { error }
  }
}

async function wi5URLForWine(sku, venueId) {
  try {
    const response = await performRequest(POST, "/v2/wi5URL", { sku, venueId })
    return {
      menuUrl: response?.menuUrl,
      error: response?.menuUrl == null ? "No url returned" : null,
    }
  } catch (error) {
    return { error }
  }
}

async function wi5URLForVenue(venueId) {
  try {
    const response = await performRequest(POST, "/v2/wi5VenueURL", { venueId })
    return {
      menuUrl: response?.menuUrl,
      error: response?.menuUrl == null ? "No url returned" : null,
    }
  } catch (error) {
    return { error }
  }
}

async function drinkScanned(tag) {
  try {
    const body = {
      venueId: tag[0],
      dispenserId: tag[1],
      positionId: tag[2],
      type: tag[3],
    }
    let response = await performRequest(POST, "/v1/drinkScanned", body)
    return {
      drink: response?.drink,
      measures: response?.measures,
      error: response?.drink == null ? "No drink returned" : null,
    }
  } catch (error) {
    return { error }
  }
}

async function dispenseDrink(data) {
  try {
    const body = {
      venueId: data?.tag[0],
      dispenserId: data?.tag[1],
      positionId: data?.tag[2],
      type: data?.tag[3],
      volume: `${data?.measure?.volume ?? 0}`,
      volumeId: `${data?.measure?.id ?? 1}`,
    }

    const response = await performRequest(POST, "/v1/dispense", body)
    if (response?.transactionid) {
      errorCount = 0
      const receipt = await checkDispenseProgress(response.transactionid)
      return { receipt, error: null }
    }
    return { error: "No transactionid", code: 1 }
  } catch (error) {
    return { error, code: 0 }
  }
}

async function checkDispenseProgress(transactionid) {
  if (errorCount > 15) {
    errorCount = 0
    console.log("ERROR COUNT REACHED, FAILING")
    return { error: "Max attempts reached", code: 2 }
  }
  console.log("waiting...")
  await new Promise(resolve => setTimeout(resolve, 1000))
  const response = await performRequest(POST, "/v1/checkDispense", {
    transactionid,
  })
  console.log("check dispense response: ", response)
  if (response?.data?.result === 1 || response?.data?.result === -1) {
    return response
  }
  console.log("INCREASING ERROR COUNT.")
  errorCount++
  return await checkDispenseProgress(transactionid)
}

async function addCard(nonce) {
  try {
    const response = await performRequest(POST, "/v1/addCard", { nonce })
    if (response.data) {
      const profile = await getProfile()
      return profile
    }
    return { error: "No square card returned" }
  } catch (error) {
    return { error }
  }
}

async function removeCard() {
  try {
    const response = await performRequest(GET, "/v2/removeCard")
    if (response) {
      return response
    }
    return { error: "Unable to remove card" }
  } catch (error) {
    return { error }
  }
}

async function getTransactions() {
  try {
    const response = await performRequest(GET, "/v2/transactions")
    return {
      transactions: response?.length > 0 ? response : null,
      error: response?.length > 0 ? null : "No transactions returned",
    }
  } catch (error) {
    return { error }
  }
}

async function getSquareTransactions(transactions) {
  try {
    const response = await performRequest(POST, `/v2/squaretransactions`, {
      transactions,
    })
    return response?.transactions
  } catch (error) {
    console.log("ERROR: ", error)
    return null
  }
}

async function addressSearch(query) {
  try {
    const response = await performRequest(POST, `/v2/addressSearch`, {
      query,
    })
    return response?.suggestions
  } catch (error) {
    console.log(error)
    return []
  }
}

async function getAddress(id) {
  try {
    const response = await performRequest(POST, `/v2/getAddress`, {
      id,
    })
    return response
  } catch (error) {
    console.log(error)
    return null
  }
}

async function logAPIEvent(event) {
  try {
    const user = Store.store.getState().profile
    const response = await performRequest(POST, `/v2/logEvent`, {
      event: `${event} - ${user?.vagabondId ?? "Unauthorised"}`,
    })
    return response?.success
  } catch (error) {
    console.log("ERROR: ", error)
    return null
  }
}

async function logFacebookAdEventWeb(
  subscriber,
  event_name = "Event",
  custom_data = {},
) {
  try {
    const client_user_agent = navigator?.userAgent
    const cookie = document?.cookie.split("; ").reduce((prev, current) => {
      const [name, ...value] = current.split("=")
      prev[name] = value.join("=")
      return prev
    }, {})
    const fbp = cookie?._fbp

    let utf16leEmail = CryptoJS.enc.Utf16LE.parse(subscriber?.email ?? "")
    let utf16Sha256Email = CryptoJS.SHA256(utf16leEmail)
    const emailSHA = utf16Sha256Email.toString(CryptoJS.enc.Hex)

    let utf16leName = CryptoJS.enc.Utf16LE.parse(subscriber?.dfisplayName ?? "")
    let utf16Sha256Name = CryptoJS.SHA256(utf16leName)
    const nameSHA = utf16Sha256Name.toString(CryptoJS.enc.Hex)

    const data = [
      {
        event_name,
        event_time: moment().unix(),
        user_data: {
          fn: [nameSHA],
          em: [emailSHA],
          client_user_agent,
          fbp,
        },
        custom_data,
        opt_out: true,
      },
    ]
    const accessToken = Firebase.getConfigValue("fb_pixel_access_token")
    const pixelId = Firebase.getConfigValue("fb_pixel_id")
    const url =
      "https://graph.facebook.com/v13.0/" +
      pixelId +
      "/events?access_token=" +
      accessToken

    var params = {
      method: "post",
      url,
      data: { data },
    }

    return axios(params)
      .then(response => {
        if (response) {
          return response.data
        }
        return null
      })
      .catch(error => {
        console.log("FB Pixel event error", error)
        return null
      })
  } catch (error) {
    console.log("FB Pixel event error", error)
    return null
  }
}

async function logFacebookAdEventNative(eventName = "Event", parameters = {}) {
  if (Platform.OS === "ios") {
    var permission = await Facebook.getPermissionsAsync()
    if (!permission.granted && permission.canAskAgain) {
      console.log("ASKING FB PERMISSION")
      permission = await Facebook.requestPermissionsAsync()
      if (permission.granted) {
        console.log("FB PERMISSION GRANTED")
        await Facebook.setAdvertiserTrackingEnabledAsync(true)
        await Facebook.setAutoLogAppEventsEnabledAsync(true)
        await Facebook.setAdvertiserIDCollectionEnabledAsync(true)
      }
    }
    if (permission.granted) {
      const result = await Facebook.logEventAsync(eventName, parameters)
      console.log("FB EVENT SENT", eventName, parameters)
    }
  }
}

export default {
  emailCheck,
  getProfile,
  setProfile,
  register,
  getExploreQuestions,
  saveExploreQuestions,
  getPreviousAnswers,
  rateWine,
  shopifyURLForWine,
  wi5URLForWine,
  wi5URLForVenue,
  drinkScanned,
  dispenseDrink,
  addCard,
  getTransactions,
  getSquareTransactions,
  addressSearch,
  getAddress,
  removeCard,
  logAPIEvent,
  logFacebookAdEventWeb,
  logFacebookAdEventNative,
}
