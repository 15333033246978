import React from "react"
import { Helmet } from "react-helmet"
import { Image, ScrollView, Text } from "react-native"
import StyleSheet from "react-native-media-query"

import Base from "../../base"
import { ImageBG } from "../common"
import Gap from "../common/Gap"

export default function ThanksScreen({ navigation, route }) {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appVeryLightGrey} />
      </Helmet>
      <ImageBG bgID={10} imageStyle={{ opacity: 0.5 }}>
        <ScrollView
          style={styles.main}
          contentContainerStyle={styles.content}
          showsVerticalScrollIndicator={false}
          alwaysBounceHorizontal={false}
        >
          <Text style={styles.title} dataSet={{ media: ids.title }}>
            THANKS FOR ENTERING!
          </Text>
          <Image
            style={styles.image}
            dataSet={{ media: ids.image }}
            source={require("../../assets/images/mysterywine/cheers_icon.png")}
          />
          <Text style={styles.subtitle}>
            We'll let you know when the next Mystery Wine competition is ready
            for tasting.
          </Text>
          <Gap size={50} />
        </ScrollView>
      </ImageBG>
    </>
  )
}

const { styles, ids } = StyleSheet.create({
  main: {
    flex: 1,
  },

  content: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appRed,
    fontSize: 90,
    lineHeight: 90,
    marginBottom: 20,
    marginTop: 30,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: 66,
      lineHeight: 63,
    },
  },

  subtitle: {
    fontFamily: Base.fonts.appBold,
    fontSize: 18,
    color: Base.colours.appBrown,
    lineHeight: 23,
    marginHorizontal: 20,
    maxWidth: 400,
    marginBottom: 16,
    textAlign: "center",
  },

  image: {
    width: 300,
    height: 300,
    margin: 10,
    resizeMode: "contain",
    "@media (max-width: 960px)": {
      width: 200,
      height: 200,
    },
  },
})
