import React, { useMemo } from "react"
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"

import Base from "../../base"

export default function AppButton({
  colour,
  disabled = false,
  style,
  containerStyle,
  onPress,
  outline,
  dim = false,
  title,
  titleStyle,
  isLoading = false,
  loadingColour,
  children,
  dataSet,
  titleDataSet,
}) {
  const bgColour = useMemo(() => {
    return {
      backgroundColor: outline
        ? "transparent"
        : colour ?? Base.colours.appWhite,
    }
  }, [outline, colour])

  const titleColour = useMemo(() => {
    if (colour) {
      return colour
    }
    return outline ? Base.colours.appWhite : Base.colours.appBrown
  }, [colour, outline])

  const outlineStyle = useMemo(() => {
    return outline
      ? {
          backgroundColor: "transparent",
          borderWidth: 2,
          borderColor: titleColour,
        }
      : {}
  }, [outline, titleColour])

  const opacity = useMemo(() => {
    let _opacity = disabled ? 0.6 : 1.0
    if (dim) {
      _opacity = 0.7
    }
    return _opacity
  }, [disabled, dim])

  return (
    <View
      style={[containerStyle, { opacity }]}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <TouchableOpacity
        activeOpacity={isLoading ? 1.0 : 0.7}
        style={[styles.main, bgColour, outlineStyle, style]}
        dataSet={{ media: dataSet }}
        onPress={isLoading || onPress}
      >
        {isLoading ? (
          <ActivityIndicator
            size="small"
            color={loadingColour ?? titleStyle?.color ?? titleColour}
          />
        ) : (
          <Text
            style={[styles.title, { color: titleColour }, titleStyle]}
            dataSet={{ media: titleDataSet }}
          >
            {title?.replace("\\n", "\n")}
          </Text>
        )}
        {children}
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 20,
    height: 50,
    backgroundColor: Base.colours.appWhite,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 30,
    lineHeight: 30,
    textAlign: "center",
    color: Base.colours.appBrown,
    paddingHorizontal: 5,
    marginBottom: Platform.OS === "web" ? 5 : 0,
  },
})
