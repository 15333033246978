import React, { useCallback } from "react"
import {
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import Base, { Strings } from "../../../base"
import ScreenActivityIndicator from "../../common/ScreenActivityIndicator"
import { useGetFaqQuery } from "../api/hooks"
import AccordionMenu from "../components/AccordionMenu"
import FooterBox from "../components/FooterBox"
import ScreenDescription from "../components/ScreenDescription"
import { SUBSCRIPTION_SCREENS } from "../subscription.constants"

export default function SubscriptionsFaqScreen({ navigation, style, dismiss }) {
  const { data: faq, isLoading } = useGetFaqQuery()
  Base.useNavBarColour(Base.colours.appVeryLightGrey)

  const renderContent = useCallback(
    (section, index, isActive, sections) => (
      <Text style={styles.contentText}>{section.detail}</Text>
    ),
    [],
  )

  const termsPressed = useCallback(() => {
    if (Platform.OS === "web") {
      dismiss()
    }
    navigation.push(SUBSCRIPTION_SCREENS.TERMS)
  }, [navigation, dismiss])

  if (isLoading) {
    return (
      <View style={styles.loadingLayout}>
        <ScreenActivityIndicator />
      </View>
    )
  }

  return (
    <>
      <ScrollView
        style={[styles.layout, style]}
        contentContainerStyle={styles.layoutContent}
      >
        <ScreenDescription titleStyle={styles.title}>
          {Strings.SUB_FAQS_SUBTITLE}
        </ScreenDescription>
        <SafeAreaView style={styles.body} edges={["left", "right"]}>
          <AccordionMenu
            sections={faq}
            renderContent={renderContent}
            headerStyle={styles.row}
            separatorColor={Base.colours.appLightGrey}
            chevronColour={Base.colours.appGrey}
            chevronSize={16}
          />
        </SafeAreaView>
        <FooterBox style={styles.footer}>
          <Text style={styles.footerLink} onPress={termsPressed}>
            {Strings.SUB_FAQ_TERMS}
          </Text>
        </FooterBox>
      </ScrollView>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" />
    </>
  )
}

const styles = StyleSheet.create({
  layout: {
    backgroundColor: Base.colours.appVeryLightGrey,
  },
  loadingLayout: {
    backgroundColor: Base.colours.appVeryLightGrey,
    justifyContent: "center",
    alignItems: "center",
  },
  layoutContent: {
    paddingVertical: 8,
  },
  body: {
    flex: 1,
  },
  title: {
    paddingTop: 8,
    fontFamily: Base.fonts.appMedium,
    color: Base.colours.appBrown,
    lineHeight: 20,
    textAlign: "center",
    fontSize: 15,
  },
  contentText: {
    color: Base.colours.appBrown,
    fontFamily: Base.fonts.appRegular,
    marginVertical: 16,
    fontSize: 16,
    lineHeight: 20,
    marginHorizontal: 24,
  },
  footer: {
    marginHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  footerLink: {
    color: Base.colours.appBrown,
    fontFamily: Base.fonts.appRegular,
    fontSize: 16,
    lineHeight: 24,
    textDecorationLine: "underline",
  },
  row: {
    borderBottomWidth: 1,
  },
})
