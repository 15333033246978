import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native"
import changeNavigationBarColor from "react-native-navigation-bar-color"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import Base, { Contentful, Strings } from "../../base"
import { AppButton, CloseButton, ImageBG, StatusBar } from "../common"
import Gap from "../common/Gap"
import Row from "./TastingNotesRow"
import Scale from "./TastingNotesScale"

export default function TastingNotesScreen({
  navigation,
  route,
  profile,
  showClose,
}) {
  changeNavigationBarColor(Base.colours.appWhite)
  const insets = useSafeAreaInsets()
  const [measures, setMeasures] = useState({})
  const [wine, setWine] = useState(null)
  const [loading, setLoading] = useState(true)

  const getWine = useCallback(async () => {
    setLoading(true)
    let SKU = route?.params?.sku
    if (Platform.OS === "web" && window) {
      const comps = window.location.pathname.split("/")
      if (comps[1] === "wine" && comps.leng === 4) {
        SKU = comps[comps.length - 1]
      } else {
        const device_id = comps[comps.length - 2]
        const position = comps[comps.length - 1]
      }
    }
    console.log("SKU:", SKU)
    const _wine = await Contentful.getWineforSKU(SKU)
    if (_wine?.sku) {
      setWine(_wine)
      setLoading(false)
    }
  }, [route, setWine, setLoading])

  useEffect(() => {
    getWine()
  }, [getWine])

  const closeTouched = useCallback(() => {}, [])

  const buyOnlineTouched = useCallback(() => {
    if (wine?.shopifyUrl) {
      Base.functions.openURL(wine?.shopifyUrl)
    }
  }, [wine])

  // const pourTouched = useCallback(() => {

  // }, [])

  // const pourButtonTitle = useMemo(() => {

  // })

  const title = useMemo(() => {
    let _title = wine?.displaytitle1 ?? ""
    _title += wine?.displaytitle2 && ` ${wine?.displaytitle2}`
    return _title
  }, [wine])

  if (loading) {
    return (
      <ImageBG
        bgID={Platform.OS === "web" ? 3 : 7}
        style={[styles.main, styles.loadingContainer]}
      >
        <CloseButton dark onPress={closeTouched} />
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={Base.colours.appGrey} />
        </View>
        <StatusBar
          barStyle="dark-content"
          translucent={true}
          backgroundColor={"transparent"}
        />
      </ImageBG>
    )
  }

  return (
    <ImageBG bgID={Platform.OS === "web" ? 3 : 7} style={[styles.main]}>
      <ScrollView
        style={styles.scrollview}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollContent}
        alwaysBounceVertical={false}
      >
        {showClose ? (
          <CloseButton dark onPress={closeTouched} />
        ) : (
          <Gap size={40} />
        )}
        <Text style={[Base.styles.title, styles.title]}>{title}</Text>
        <Text style={styles.subtitle}>
          {wine?.vintage} / {wine?.abv}%
          {wine?.organic?.length > 0 && ` / ${wine?.organic}`}
        </Text>
        <Scale index={1} style={styles.scale} wine={wine} />
        {!!wine?.grapes?.length && (
          <Row
            icon={require("../../assets/images/tastingnotes/tastingnotes_grapes.png")}
            text={wine?.grapes}
          />
        )}
        {!!wine?.country?.length && (
          <Row
            icon={require("../../assets/images/tastingnotes/tastingnotes_pin.png")}
            text={`${wine?.country} ${
              wine?.region.length && " > " + wine?.region
            }`}
          />
        )}
        {!!wine?.tastingnotes?.length && (
          <Row
            icon={require("../../assets/images/tastingnotes/tastingnotes_mouth.png")}
            text={wine?.tastingnotes}
          />
        )}
        {!!wine?.pairingideas?.length && (
          <Row
            icon={require("../../assets/images/tastingnotes/tastingnotes_cutlery.png")}
            text={wine?.pairingideas}
          />
        )}
        {!!wine?.fyi?.length && (
          <Row
            icon={require("../../assets/images/tastingnotes/tastingnotes_plus.png")}
            text={wine?.fyi || ""}
          />
        )}
      </ScrollView>
      <View style={styles.footer}>
        <AppButton
          title={"hello"}
          colour={Base.colours.appWhite}
          style={[styles.buyButton, { marginBottom: 20 + insets.bottom }]}
          onPress={buyOnlineTouched}
        />
      </View>
      <StatusBar
        barStyle="dark-content"
        translucent={true}
        backgroundColor={"transparent"}
      />
    </ImageBG>
  )
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: Base.colours.appVeryLightGrey,
    flex: 1,
    alignItems: "center",
  },

  scrollview: {
    width: "100%",
    maxWidth: 600,
  },

  closeContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },

  closeButton: {
    width: 40,
    height: 40,
    marginRight: -5,
  },

  title: {
    marginRight: 40,
    textAlign: "center",
    lineHeight: 50,
    width: "100%",
    marginBottom: 15,
  },

  subtitle: {
    marginBottom: 15,
    fontFamily: Base.fonts.appBold,
    color: Base.colours.appBrown,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    paddingHorizontal: 20,
  },

  buyButton: {
    backgroundColor: Base.colours.appOrange,
    marginVertical: 20,
  },

  rateButton: {
    marginVertical: -8,
    marginRight: -8,
  },

  loadingContainer: { paddingHorizontal: 18 },
  loading: { flex: 1, justifyContent: "center", alignItems: "center" },
  scrollContent: { flexGrow: 1, paddingHorizontal: 22 },
  scale: { marginBottom: 12 },
})
