import React from "react"
import { createElement } from "react-native-web"

export default function DateInputWeb({ value, onChange, style }) {
  return createElement("input", {
    type: "date",
    value,
    style,
    onChange: event => onChange(event.target.value),
  })
}
