import axios from "axios"
import moment from "moment"

/**
 *  API HTTP client to perform requests to backend.
 *  Uses axios as HTTP client.
 *  Authorization provides through the JWT token sets to an Authorization header with Bearer method.
 */
class CmsApi {
  constructor({ baseURL, token }) {
    this.apiClient = axios.create({
      baseURL,
      responseType: "json",
      headers: { "Content-Type": "application/json", Authorization: token },
      timeout: 1000 * 25, // 25 sec
    })

    this.apiClient.interceptors.response.use(
      response => response.data, // extract retrieved data.
      error => {
        console.warn("CMS API error", error?.response)
        return null
      },
    )
  }

  async getLocations() {
    return this.apiClient.get("/locations?populate=%2A")
  }

  async getWines() {
    try {
      let page = 1
      const pageSize = 500
      let objects = []
      let completed = false
      while (!completed) {
        let response = await this.apiClient.get(
          `/wines?populate=%2A&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        )
        if (
          !response ||
          !response?.data?.data ||
          response.data.data.length === 0
        ) {
          completed = true
        }
        objects = objects.concat(response.data.data)
        page += 1
      }
      return objects
    } catch (error) {
      console.log("Error fetching CMS wines", error)
      return []
    }
  }

  async getCampaigns() {
    return this.apiClient.get("/mystery-wine-campaigns?populate=%2A")
  }

  async getCampaignFromSlug(slug) {
    return this.apiClient.get(
      `/mystery-wine-campaigns?populate=deep&filters[slug][$eq]=${slug}`,
    )
  }
}

const CMS = new CmsApi({
  baseURL:
    "https://ibp84elofa.execute-api.eu-west-2.amazonaws.com/production/api",
  token:
    "Bearer 8db1d6df815f2286fb17cfa61039cbf8240817f12d1a96cf1338cdcf20d6eff5e45b7671d11139d73d6f5a20389cd71f7360ccb9b7bca49e9e13f6c34e1dacef597cb2d0f1b3d2db6ef9696589e26a39638919898dbe860918521387b2feb98e03dbb7d600d854469b8376fde4bf5abdf066a429baf7f81cea25f0d92040bab3",
})

export default CMS
