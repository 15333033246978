import React, { useCallback, useMemo, useState } from "react"
// import Lottie from "react-lottie-player"
import { StyleSheet, TouchableOpacity, View } from "react-native"

import {
  NEGATIVE_RATE,
  NEUTRAL_RATE,
  POSITIVE_RATE,
} from "../../base/Constants"

// const history_thumb = require("../../assets/animations/history_thumb.json")
// const tnotes_thumb = require("../../assets/animations/tnotes_thumb.json")

// const animationFrames = {
//   [NEUTRAL_RATE]: [33, 63],
//   [POSITIVE_RATE]: [0, 20],
//   [NEGATIVE_RATE]: [20, 33],
// }

// const states = [NEUTRAL_RATE, POSITIVE_RATE, NEGATIVE_RATE]

export default function RateButton(props) {
  // const { tastingNotes, state, onStateChanged, changeBeforeAnimation } = props

  // const thumbIcon = useMemo(
  //   () => (tastingNotes ? tnotes_thumb : history_thumb),
  //   [tastingNotes],
  // )

  // const [currentState, setCurrentState] = useState(state ?? NEUTRAL_RATE)
  // const [touchable, setTouchable] = useState(true)
  // const [currentSegment, setCurrentSegment] = useState()

  // const nextState = useMemo(() => {
  //   let index = states.findIndex(item => currentState === item) + 1
  //   if (index >= states.length) {
  //     index = 0
  //   }

  //   return states[index]
  // }, [currentState])

  // const changeState = useCallback(async () => {
  //   try {
  //     if (!touchable) {
  //       return
  //     }
  //     setTouchable(false)

  //     const newState = nextState

  //     if (changeBeforeAnimation && onStateChanged) {
  //       await onStateChanged(newState)
  //     }

  //     setCurrentSegment(animationFrames[newState])
  //   } catch (error) {
  //     console.log(error)
  //     setTouchable(true)
  //   }
  // }, [touchable, nextState, changeBeforeAnimation, onStateChanged])

  // const onAnimationFinish = useCallback(async () => {
  //   try {
  //     if (!touchable) {
  //       const newState = nextState
  //       setCurrentState(newState)
  //       if (!changeBeforeAnimation && onStateChanged) {
  //         await onStateChanged(newState)
  //       }
  //       setTouchable(true)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     setTouchable(true)
  //   }
  // }, [changeBeforeAnimation, nextState, onStateChanged, touchable])

  return (
    <TouchableOpacity
      style={[
        styles.main,
        props.style,
        props.width && { width: props.width },
        props.height && { height: props.height },
      ]}
      // onPress={changeState}
      activeOpacity={1.0}
    >
      <View style={styles.animationWrap}>
        {/* <Lottie
          loop={false}
          goTo={!currentSegment ? animationFrames[currentState][1] : undefined}
          play={!touchable && !!currentSegment}
          animationData={thumbIcon}
          segments={!!currentSegment && currentSegment}
          style={animationStyle}
          onComplete={() => onAnimationFinish()}
        /> */}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    width: 45,
    height: 38,
    justifyContent: "center",
    backgroundColor: "transparent",
    alignItems: "center",
    marginBottom: 0,
  },

  animationWrap: {
    borderRadius: 10,
    overflow: "hidden",
    width: 45,
    height: 38,
    pointerEvents: "none",
  },
})

const animationStyle = {
  backgroundColor: "transparent",
  width: "100%",
  height: "100%",
  alignSelf: "center",
}
