import React, { useEffect, useState } from "react"
import { Image, Platform, StyleSheet, View } from "react-native"

import Base from "../../base"

export default function ImageBG({ bgID, style, center, imageStyle, children }) {
  const maxWidth = Math.min(Base.constants.window.width, 800)
  const [size, setSize] = useState({ width: maxWidth, height: 200 })
  const [source, setSource] = useState(null)

  useEffect(() => {
    let newSource = source
    switch (bgID ?? 1) {
      case 1:
        newSource = require("../../assets/images/brush_1.png")
        break
      case 2:
        newSource = require("../../assets/images/brush_2.png")
        break
      case 3:
        newSource = require("../../assets/images/brush_3.png")
        break
      case 4:
        newSource = require("../../assets/images/brush_4.png")
        break
      case 5:
        newSource = require("../../assets/images/brush_5.png")
        break
      case 6:
        newSource = require("../../assets/images/brush_6.png")
        break
      case 7:
        newSource =
          Platform.OS === "web"
            ? require("../../assets/images/brush_7.web.png")
            : require("../../assets/images/brush_7.png")
        break
      case 8:
        newSource =
          Platform.OS === "web"
            ? require("../../assets/images/brush_8.web.png")
            : require("../../assets/images/brush_8.png")
        break
      case 9:
        newSource = require("../../assets/images/brush_9.png")
        break
      case 10:
        newSource = require("../../assets/images/brush_10.png")
        break
      case 11:
        newSource = require("../../assets/images/brush_11.png")
        break
      case 12:
        newSource = require("../../assets/images/subscriptions/brushStroke_4.png")
      case 13:
        newSource = require("../../assets/images/brush_13.png")
        break
    }
    setSource(newSource)
    if (Platform.OS === "web") {
      Image.getSize(newSource, (width, height) => {
        setSize({ width, height })
      })
    } else {
      let dims = Image.resolveAssetSource(newSource)
      setSize({ width: dims.width, height: dims.height })
    }
  }, [bgID, source])

  const centerStyle = {
    height: "100%",
  }
  return (
    <View style={[styles.main, style]}>
      <Image
        style={[
          styles.image,
          { width: maxWidth, height: (maxWidth / size.width) * size.height },
          center && centerStyle,
          imageStyle,
        ]}
        source={source}
      />
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    height: "100%",
    flex: 1,
    backgroundColor: "transparent",
  },

  image: {
    resizeMode: "contain",
    position: "absolute",
    bottom: 0,
    top: undefined,
    right: 0,
    opacity: 0.8,
  },
})
