import React, { useEffect, useRef, useState } from "react"
import { Animated, Easing, StyleSheet, View } from "react-native"

export default function Collapsible(props) {
  const content = useRef()
  const mounted = useRef()

  const [collapsed, setCollapsed] = useState(props.collapsed)
  const height = useRef(new Animated.Value(props.collapsedHeight)).current
  const [contentHeight, setContentHeight] = useState(0)

  function onContentLayout(event) {
    const newHeight = event.nativeEvent.layout.height
    if (!collapsed && newHeight !== contentHeight) {
      update(true)
    }
    setContentHeight(newHeight)
  }

  function update(force = false) {
    if (!force && props.collapsed === collapsed) return
    const duration = props.duration ?? 200
    const collapsedHeight = props.collapsedHeight
    const toValue = props.collapsed ? collapsedHeight : contentHeight

    Animated.timing(height, {
      easing: props.easing ?? Easing.linear,
      toValue,
      duration: duration,
      useNativeDriver: props.useNativeDriver ?? false,
    }).start(() => setCollapsed(props.collapsed))
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      update()
    }
  })

  return (
    <Animated.View
      style={[styles.main, props.style, { height }]}
      pointerEvents={!props.enablePointerEvents ? "none" : "auto"}
    >
      <View
        style={[styles.content, { minHeight: props.collapsedHeight }]}
        ref={content}
        onLayout={onContentLayout}
      >
        {props.children}
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    overflow: "hidden",
  },

  content: {
    width: "100%",
  },
})
