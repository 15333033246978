import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet"
import { StyleSheet, Text, View } from "react-native"

import Base from "../../../base"
import { AppButton } from "../../common"
import ModalWindow from "../../common/ModalWindow"
import Button from "../components/Button"

const DEFAULT_CONFIG = {
  onSuccess: () => null,
  onCancel: () => null,
  successText: "OK",
}

/** Proposes template to create modal dialog component.*/
export default function useModalPrompt(title, description, config) {
  const {
    onSuccess,
    onCancel,
    cancelText,
    successText,
    disclaimerText,
    buttonsVertical,
  } = Object.assign({}, DEFAULT_CONFIG, config)
  const [isVisible, setIsVisible] = useState(false)
  const show = () => setIsVisible(true)
  const hide = () => setIsVisible(false)

  const ModalComponent = useCallback(
    () =>
      isVisible ? (
        <>
          <Helmet>
            <meta name="theme-color" content={Base.colours.appBlack} />
          </Helmet>
          <ModalWindow isVisible={isVisible} onDismiss={hide}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
            <View
              style={[
                styles.buttons,
                { flexDirection: buttonsVertical ? "column" : "row" },
              ]}
            >
              {!!cancelText && (
                <AppButton
                  style={{
                    backgroundColor: Base.colours.appLightGrey,
                    width: "auto",
                    minWidth: 120,
                    marginVertical: 5,
                  }}
                  colour={Base.colours.appBrown}
                  onPress={() => {
                    hide()
                    onCancel()
                  }}
                  title={cancelText}
                />
              )}
              <AppButton
                style={{
                  backgroundColor: Base.colours.appRed,
                  width: "auto",
                  minWidth: 120,
                  marginVertical: 5,
                }}
                colour={Base.colours.appWhite}
                onPress={() => {
                  hide()
                  onSuccess()
                }}
                title={successText}
              />
            </View>
            {!!disclaimerText && (
              <Text style={styles.disclaimer}>{disclaimerText}</Text>
            )}
          </ModalWindow>
        </>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible, title, description],
  )

  return [ModalComponent, { show, hide }]
}

const styles = StyleSheet.create({
  title: {
    fontSize: 40,
    lineHeight: 40,
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appBrown,
    textAlign: "center",
    paddingBottom: 24,
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: Base.fonts.appRegular,
    color: Base.colours.appBrown,
    textAlign: "center",
    paddingBottom: 30,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  disclaimer: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    color: Base.colours.appBrown,
    marginVertical: 10,
  },
})
