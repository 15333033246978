import Firebase from "../../../modules/firebase"
import { SubscriptionApi } from "./SubscriptionApi"

export const subscriptionApi = new SubscriptionApi({
  baseURL: Firebase.getConfigValue("subscription_api_url"), // Firebase.getConfigValue("subscription_api_url"), // "https://subscriptions.vagabondwines.co.uk"  "https://subscriptions-uat.vagabond-omnifi.co.uk", "http://192.168.0.29:4002", //
  getToken: async () => {
    const user = Firebase.getUser()
    return user ? await user.getIdToken() : undefined
  },
})
