import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import Modal from "react-native-modal"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import Base, { Strings } from "../../base"

export default function ActionSheet(props) {
  const insets = useSafeAreaInsets()

  function getButton(button, index) {
    return (
      <TouchableOpacity
        key={index}
        style={styles.button}
        onPress={() => props.onAction(button, index)}
      >
        <Text
          style={[
            styles.buttonTitle,
            index === props.destructiveButtonIndex && styles.redTitle,
          ]}
        >
          {button}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <Modal
      isVisible={props.visible}
      useNativeDriverForBackdrop
      statusBarTranslucent
      onBackdropPress={props.onAction}
    >
      <View style={[styles.container, { marginBottom: insets.bottom + 10 }]}>
        {props.title && <Text style={styles.title}>{props.title}</Text>}
        {props.buttons?.map((button, i) => getButton(button, i))}
        <View style={Base.styles.line} />
        {getButton(
          props.cancelTitle ?? Strings.CANCEL_BUTTON,
          props.buttons?.length,
        )}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Base.colours.appWhite,
    width: "100%",
    paddingHorizontal: 16,
    position: "absolute",
    borderRadius: 10,
    bottom: 0,
  },

  button: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 30,
    paddingVertical: 15,
  },

  buttonTitle: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 17,
    width: "100%",
    textAlign: "center",
    color: Base.colours.appBlack,
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 13,
    color: Base.colours.appSemiLightGrey,
    width: "100%",
    textAlign: "center",
    paddingBottom: 17,
    paddingTop: 20,
  },

  redTitle: { color: "red" },
})
