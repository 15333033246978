import React from "react"
import { StyleSheet, Text, View } from "react-native"

import Base from "../../../base"

/** Header copy informs base information about a functionality presented on a screen. */
export default function ScreenDescription({ children, style, titleStyle }) {
  return (
    <View style={[styles.header, style]}>
      <Text style={[styles.title, titleStyle]}>{children}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    width: "100%",
    minHeight: 48,
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  title: {
    fontFamily: Base.fonts.appTitle,
    fontSize: 35,
    lineHeight: 37,
    color: Base.colours.appWhite,
    paddingHorizontal: 24,
    paddingVertical: 8,
  },
})
