import "firebase/analytics"
import "firebase/auth"
import "firebase/remote-config"

import firebase from "firebase/app"

import { clearAndResetQueries } from "../queryClient"

const firebaseConfig = {
  apiKey: "AIzaSyD7kQolM-UIB7MAnWNZt0r0s9oLEv1YAXY",
  authDomain: "vagabond-app-b5c6c.firebaseapp.com",
  databaseURL: "https://vagabond-app-b5c6c.firebaseio.com",
  projectId: "vagabond-app-b5c6c",
  storageBucket: "vagabond-app-b5c6c.appspot.com",
  messagingSenderId: "1026100309804",
  appId: "1:1026100309804:web:75f1ee48176aa34e860689",
}

const app = firebase.initializeApp(firebaseConfig)
const remoteConfig = app.remoteConfig()
const analytics = app.analytics()

async function init() {
  await remoteConfig.fetch()
  await remoteConfig.activate()

  await new Promise(resolve => {
    firebase.auth().onAuthStateChanged(function (user) {
      resolve(user)
    })
  })

  return true
}

async function registerForPush() {
  return null
}

async function login(email, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      console.log("User account signed in!")
      clearAndResetQueries(["getSubscriber"])
      return { success: true }
    })
    .catch(error => {
      console.log("LOGIN FIREBASE ERROR: ", error.code)
      return { success: false, error: error.code }
    })
}

async function register(email, password) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(() => {
      console.log("User account created & signed in!")
      clearAndResetQueries(["getSubscriber"])
      return true
    })
    .catch(error => {
      console.log("REGISTER FIREBASE ERROR: ", error.code)
      return false
    })
}

async function logout() {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      clearAndResetQueries([
        "getSubscriber",
        "getCurrentBox",
        "getTasteProfile",
        "getPastBoxes",
      ])

      return true
    })
}

async function forgotPassword(email) {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      console.log("Password reset email sent")
      return true
    })
    .catch(error => {
      console.log(error.code)
      return false
    })
}

function getUser() {
  return app.auth().currentUser
}

function addUserStateListener(listener) {
  return firebase.auth().onAuthStateChanged(listener)
}

function logEvent(name, args) {
  analytics.logEvent(name, args)
}

function logScreenView(name) {
  logEvent("screen_view", { firebase_screen: name })
}

function getConfigValue(key) {
  const value = remoteConfig.getValue(key)._value
  return value || ""
}

async function getPushToken() {
  return null
}

export default {
  init,
  registerForPush,
  logEvent,
  logScreenView,
  getConfigValue,
  getPushToken,
  login,
  register,
  logout,
  getUser,
  forgotPassword,
  addUserStateListener,
}
