import React from "react"
import { ActivityIndicator, StyleSheet } from "react-native"

import Base from "../../base"

export default function ScreenActivityIndicator({
  hidden = false,
  size = "large",
  color = Base.colours.appBrown,
}) {
  return hidden ? null : (
    <ActivityIndicator
      style={styles.activityIndicator}
      size={size}
      color={color}
    />
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
})
