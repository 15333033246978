import React from "react"
import {
  Image,
  ImageBackground,
  Text,
  View,
} from "react-native"
import StyleSheet from "react-native-media-query"
import { Helmet } from "react-helmet"
import AnimatedText from 'react-animated-text-content';

import Base from "../../base"

export default function WaitTimeDisplay({ navigation }) {

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={Base.colours.appVeryLightGrey} />
      </Helmet>
      <ImageBackground
        style={styles.main}
        source={require("../../assets/images/waittime/wait_time_background.jpg")}
      >
        <View />
        <View style={styles.copy}>
          <Text style={styles.title}>TABLE{`\n`}WAIT TIME</Text>
          <Text style={styles.subtitle}>Around</Text>
          <Text style={styles.time}>35</Text>
          <Text style={styles.subtitle}>Minutes</Text>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <Image
              style={styles.logo}
              source={require("../../assets/images/waittime/new_logo.png")}
            />
            <Text style={styles.versionNumber}>v1.3.2-a</Text>
          </View>
        </View>
      </ImageBackground>
    </>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    backgroundColor: Base.colours.appVeryLightGrey,
    resizeMode: "cover",
    justifyContent: "space-between",
    alignItems: "center",
  },

	copy: {
    alignItems: "center",
	},

	title: {
		fontFamily: Base.fonts.appTitle,
		fontSize: 270,
		lineHeight: 250,
		color: Base.colours.appBrown,
		textAlign: "center",
		marginBottom: 100,
	},

	subtitle: {
		fontFamily: Base.fonts.appMedium,
		fontSize: 110,
		color: Base.colours.appBrown,
		textAlign: "center",
	},

	time: {
		fontFamily: Base.fonts.appMedium,
		fontSize: 700,
		lineHeight: 600,
		letterSpacing: 35,
		color: Base.colours.appPink,
		textAlign: "center",
	},

	footer: {
		flexDirection: "row",
		width: "100%",
		padding: 60,
	},

	logo: {
		width: 125,
		height: 70,
		resizeMode: "contain",
		marginBottom: 20,
	},

	versionNumber: {
		fontFamily: Base.fonts.appMedium,
		fontSize: 14,
		color: Base.colours.appPink,
	},


  spinner: {},
})
