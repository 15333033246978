import React, { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ActivityIndicator, Image, ScrollView, Text, View } from "react-native"
import StyleSheet from "react-native-media-query"
import { Easing } from "react-native-reanimated"
import Carousel from "react-native-reanimated-carousel"

import Base, { Firebase } from "../../base"
import { CAMPAIGN_STATUS } from "../../base/Constants"
import useWindowResize from "../../modules/hooks/useWindowResize"
import { AppButton } from "../common"
import Gap from "../common/Gap"
import QuestionButton from "../subscriptions/components/QuestionButton"
import { useGetCampaign } from "./useGetCampaign"
import Icon from "react-native-vector-icons/Feather"

export default function LandingScreen({ navigation }) {
  const { campaign, loading: isLoading } = useGetCampaign()
  const screenSize = useWindowResize()

  const onSubmit = useCallback(() => {
    navigation?.navigate("Pick An Answer", { campaign })
  }, [campaign, navigation])

  const showTerms = useCallback(() => {
    navigation?.navigate("Terms and Conditions", {
      terms: campaign?.terms,
      showBack: true,
    })
  }, [navigation, campaign])

  useEffect(() => {
    let target = null
    switch (campaign?.status) {
      case CAMPAIGN_STATUS.ANSWERED:
        target = campaign?.won ? "You've Won!" : "Sorry Not This Time"
        break
      case CAMPAIGN_STATUS.COMPLETED:
        target = campaign?.won ? "Claim Now" : "Thanks for Entering"
        break
      case CAMPAIGN_STATUS.CLAIMED:
        target = "Reward Claimed"
      default:
        break
    }
    if (target) {
      navigation?.navigate(target)
    }
  }, [campaign, navigation])

  if (isLoading) {
    return (
      <View style={[styles.main, { justifyContent: "center" }]}>
        <ActivityIndicator size={"large"} color={Base.colours.appWhite} />
      </View>
    )
  }

  if (screenSize[0] > 1024)
  return (
    <View style={styles.notMobile}>
      <Icon
        name={"smartphone"}
        size={110}
        color={Base.colours.appWhite}
      />
      <Text style={styles.notMobileTitle}>
        Sorry this feature is only available on mobile,{`\n`}
        Please try again on your phone.
      </Text>
    </View>
  )

  return (
    <>
      {/* <Helmet>
        <meta name="theme-color" content={Base.colours.appBrown} />
      </Helmet> */}
      <ScrollView
        style={styles.main}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
      >
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          {campaign?.title}
        </Text>
        <View>
          <Carousel
            loop
            pagingEnabled={false}
            snapEnabled={false}
            autoPlay
            enabled={false}
            autoPlayInterval={0}
            height={Base.constants.window.height * 0.32}
            width={(2739 / 774) * (Base.constants.window.height * 0.32)}
            style={{
              width: Base.constants.window.width,
              minWidth: Base.constants.window.width,
              maxWidth: Base.constants.window.width,
            }}
            data={[0, 1, 2, 3]}
            withAnimation={{
              type: "timing",
              config: {
                duration: 18000,
                delay: 0,
                easing: Easing.linear,
              },
            }}
            renderItem={i => {
              return (
                <Image
                  style={styles.bottles}
                  source={require("../../assets/images/mysterywine/bottles_scrolling.png")}
                />
              )
            }}
          />
          <Image
            style={styles.bottle}
            source={require("../../assets/images/mysterywine/red_bottle.png")}
          />
        </View>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          {campaign?.subtitle?.replace(/(\\n)/g, "\n")}
        </Text>
        <View style={styles.row}>
          <Gap horizontal size={15} />
          <AppButton
            style={styles.button}
            onPress={onSubmit}
            dataSet={ids.button}
            titleStyle={styles.buttonTitle}
            title={campaign?.startbuttontitle}
          />
        </View>
      </ScrollView>
      <View style={styles.header}>
        <QuestionButton onPress={showTerms} color={Base.colours.appRed} />
      </View>
    </>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    backgroundColor: Base.colours.appBrown,
  },

  bottles: {
    backgroundColor: Base.colours.appBrown,
    minWidth: (2739 / 774) * (Base.constants.window.height * 0.32),
    width: (2739 / 774) * (Base.constants.window.height * 0.32),
    height: Base.constants.window.height * 0.32,
    resizeMode: "contain",
  },

  bottle: {
    width: "100%",
    height: Base.constants.window.height * 0.35,
    resizeMode: "contain",
    position: "absolute",
    left: 0,
    top: -7,
  },

  header: {
    top: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingHorizontal: 20,
    position: "absolute",
  },

  content: {
    flexGrow: 1,
    width: "100%",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    textAlign: "center",
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appWhite,
    fontSize: 100,
    maxWidth: 800,
    lineHeight: 105,
    marginBottom: 38,
    marginHorizontal: 40,
    marginTop: 15,
    "@media (max-width: 960px)": {
      fontSize: 60,
      lineHeight: 60,
    },
  },

  subtitle: {
    textAlign: "center",
    fontFamily: Base.fonts.appMedium,
    color: Base.colours.appWhite,
    fontSize: 22,
    marginTop: 60,
    marginBottom: 16,
    marginHorizontal: 33,
    lineHeight: 24,
    "@media (max-width: 960px)": {
      marginTop: 42,
      fontSize: 18,
      lineHeight: 18,
    },
  },

  button: {
    height: 90,
    marginTop: 30,
    width: 400,
    "@media (max-width: 960px)": {
      height: 70,
      marginTop: 15,
      width: 220,
    },
  },

  buttonTitle: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appRed,
    fontSize: 50,
    marginBottom: 13,
    "@media (max-width: 960px)": {
      fontSize: 44,
    },
  },

  spinner: {},

  notMobile: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Base.colours.appBrown,
  },

  notMobileTitle: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appWhite,
    fontSize: 38,
    textAlign: "center",
    marginTop: 15,
  },
})
