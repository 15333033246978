import { useKeyboard } from "@react-native-community/hooks"
import React from "react"
import { StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

/** Styled footer of the screen. */
export default function FooterBox({ children, style }) {
  const { keyboardShown } = useKeyboard()

  return keyboardShown ? null : (
    <View style={[styles.footerBox, style]}>
      <SafeAreaView
        style={[styles.footerBox, style]}
        edges={["left", "bottom", "right"]}
      >
        {children}
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  footerBox: {
    padding: 8,
  },
})
