import React from "react"
import {
  StyleSheet,
  Text,
  Touchable,
  TouchableOpacity,
  View,
} from "react-native"
import Modal from "react-native-modal"

import { colours, fonts } from "../../base/Constants"
import AppButton from "./AppButton"
import Gap from "./Gap"

const Dialog = ({
  title,
  subtitle,
  description,
  rejectTitle = "Cancel",
  onReject,
  confirmTitle = "OK",
  onConfirm,
  onDismiss,
}) => {
  return (
    <Modal isVisible animationIn="fadeIn" animationOut="fadeOut">
      <TouchableOpacity
        style={styles.background}
        activeOpacity={1.0}
        onPress={onDismiss}
      >
        <View style={styles.container}>
          {!!title && <Text style={styles.title}>{title}</Text>}
          {!!subtitle && <Text style={styles.description}>{subtitle}</Text>}
          {!!(title || subtitle) && <Gap size={24} />}
          {!!description && (
            <>
              <Text style={styles.description}>{description}</Text>
              <Gap size={18} />
            </>
          )}
          {!!onReject && (
            <AppButton
              style={styles.button}
              titleStyle={styles.buttonTitle}
              title={rejectTitle}
              onPress={onReject}
            />
          )}
          {!!onConfirm && (
            <AppButton
              style={styles.button}
              titleStyle={styles.buttonTitle}
              title={confirmTitle}
              onPress={onConfirm}
            />
          )}
        </View>
      </TouchableOpacity>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "90%",
    maxWidth: 400,
    backgroundColor: colours.appWhite,
    borderRadius: 30,
    paddingVertical: 30,
    paddingHorizontal: 40,
    alignSelf: "center",
  },

  background: {
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
  },
  title: {
    fontSize: 30,
    fontFamily: fonts.appTitle,
    color: colours.appBlack,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    color: colours.appDarkGrey,
    fontFamily: fonts.appRegular,
    textAlign: "center",
  },
  button: {
    marginTop: 12,
    width: "100%",
    backgroundColor: colours.appDarkRed,
    height: 50,
  },
  buttonTitle: {
    fontSize: 28,
    lineHeight: 32,
    marginBottom: 4,
    color: colours.appWhite,
  },
})

export default Dialog
