export const SET_PROFILE = "SET_PROFILE"
export const SET_EXPLORE_QS = "SET_EXPLORE_QS"
export const NFC_INTRO_SHOWN = "NFC_INTRO_SHOWN"
export const SET_EXPLORE_RESULTS = "SET_EXPLORE_RESULTS"
export const SET_EXPLORE_MORE_QUESTIONS = "SET_EXPLORE_MORE_QUESTIONS"
export const SET_VENUES = "SET_VENUES"
export const SET_WINES = "SET_WINES"
export const SET_CONFIG = "SET_CONFIG"
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION"
export const SET_TRANSACTIONS = "SET_TRANSACTIONS"
export const RESET_PROFILE = "RESET_PROFILE"
export const SET_ONBOARDING_SHOWN = "SET_ONBOARDING_SHOWN"
export const SET_SUBS_GIFT_CARD = "SET_SUBS_GIFT_CARD"
export const SET_DEFAULT_JOURNEY = "SET_DEFAULT_JOURNEY"
export const SET_START_JOURNEY = "SET_START_JOURNEY"
export const SET_MYSTERY_CAMPAIGNS = "SET_MYSTERY_CAMPAIGNS"

function setProfile(profile) {
  return { type: SET_PROFILE, profile }
}

function setNFCIntroShown(shown) {
  return { type: NFC_INTRO_SHOWN, shown }
}

function setExploreQuestions(questions) {
  return { type: SET_EXPLORE_QS, questions }
}

function setExploreResults(results) {
  return { type: SET_EXPLORE_RESULTS, results }
}

function setExploreMoreQuestions(more) {
  return { type: SET_EXPLORE_MORE_QUESTIONS, more }
}

function setVenues(venues) {
  return { type: SET_VENUES, venues }
}

function setWines(wines) {
  return { type: SET_WINES, wines }
}

function setConfig(config) {
  return { type: SET_CONFIG, config }
}

function setCurrentLocation(location) {
  return { type: SET_CURRENT_LOCATION, location }
}

function setTransactions(transactions) {
  return { type: SET_TRANSACTIONS, transactions }
}

function resetProfile() {
  return { type: RESET_PROFILE }
}

function setOnboardingShown(shown) {
  return { type: SET_ONBOARDING_SHOWN, shown }
}

function setSubsGiftCard(giftCard) {
  return { type: SET_SUBS_GIFT_CARD, giftCard }
}

function setDefaultJourney(journey) {
  return { type: SET_DEFAULT_JOURNEY, journey }
}

function setStartJourney(journey) {
  return { type: SET_START_JOURNEY, journey }
}

function setMysteryCampaigns(campaigns) {
  return { type: SET_MYSTERY_CAMPAIGNS, campaigns }
}

export default {
  setProfile,
  setNFCIntroShown,
  setExploreQuestions,
  setExploreResults,
  setExploreMoreQuestions,
  setVenues,
  setWines,
  setConfig,
  setCurrentLocation,
  setTransactions,
  resetProfile,
  setOnboardingShown,
  setSubsGiftCard,
  setDefaultJourney,
  setStartJourney,
  setMysteryCampaigns,
}
