import React, { useCallback, useMemo, useRef, useState } from "react"
import { ScrollView, Text, TouchableOpacity, View } from "react-native"
import StyleSheet from "react-native-media-query"
import Icon from "react-native-vector-icons/AntDesign"

import Base from "../../base"
import { CAMPAIGN_STATUS } from "../../base/Constants"
import { AppButton } from "../common"
import QuestionButton from "../subscriptions/components/QuestionButton"
import OptionView from "./OptionView"
import { useGetCampaign } from "./useGetCampaign"

export default function OptionsScreen({ navigation, route }) {
  const [isLoading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(10)
  const scrollView = useRef()
  const { campaign, question, setCampaignStatus } = useGetCampaign()

  const showTerms = useCallback(() => {
    navigation?.navigate("Terms and Conditions", {
      terms: campaign?.terms,
      showBack: true,
    })
  }, [navigation, campaign])

  const showTastingNotes = useCallback(
    wine => {
      navigation?.navigate("Tasting Notes", { wine })
    },
    [navigation],
  )

  const optionSelected = useCallback(
    index => {
      if (index === selectedOption) {
        setSelectedOption(10)
      } else {
        setSelectedOption(index)
        scrollView?.current?.scrollToEnd({ animated: true })
      }
    },
    [selectedOption, setSelectedOption],
  )

  const shuffledAnswers = useMemo(() => {
    return Base.functions.shuffleArray(
      question?.attributes?.answers?.data ?? [],
    )
  }, [question])

  const submit = useCallback(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      const answer = question?.attributes.answers?.data[selectedOption]
      const isCorrect =
        answer.id === question?.attributes?.rightanswer?.data?.id
      setCampaignStatus(CAMPAIGN_STATUS.ANSWERED, {
        won: isCorrect,
      })
      const target = isCorrect ? "You've Won!" : "Sorry Not This Time"
      navigation?.navigate(target)
    }, 1000)
  }, [setLoading, question, navigation, selectedOption, setCampaignStatus])

  if (!question) {
    return <View style={styles.main} />
  }

  return (
    <View style={styles.main}>
      <ScrollView
        ref={scrollView}
        style={styles.scrollview}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
      >
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          {question?.attributes?.title}
        </Text>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          {question?.attributes?.subtitle}
        </Text>
        <View style={styles.options} dataSet={{ media: ids.options }}>
          {shuffledAnswers.map((x, i) => {
            return (
              <OptionView
                key={i}
                answer={x}
                index={i}
                onTastingNotesPress={showTastingNotes}
                onPress={optionSelected}
                selected={selectedOption === i}
              />
            )
          })}
        </View>
        <View style={styles.footer}>
          <AppButton
            style={styles.button}
            title={question?.attributes?.buttontitle}
            onPress={submit}
            titleStyle={styles.buttonTitle}
            colour={Base.colours.appRed}
            disabled={
              selectedOption > question?.attributes?.answers?.data?.length
            }
            isLoading={isLoading}
            loadingColour={Base.colours.appWhite}
            dataSet={ids.button}
            titleDataSet={ids.buttonTitle}
          />
        </View>
      </ScrollView>
      <View style={styles.header}>
        <TouchableOpacity
          style={styles.backButton}
          onPress={() => navigation.goBack()}
          activeOpacity={0.8}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Icon name={"arrowleft"} color={Base.colours.appRed} size={31} />
        </TouchableOpacity>
        <View style={{ flex: 1 }} />
        <QuestionButton onPress={showTerms} color={Base.colours.appRed} />
      </View>
    </View>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Base.colours.appBrown,
  },

  scrollview: {
    height: Base.constants.window.height,
  },

  content: {
    flexGrow: 1,
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  header: {
    width: "100%",
    paddingHorizontal: 20,
    alignItems: "center",
    position: "absolute",
    flexDirection: "row",
    top: 0,
    left: 0,
  },

  backButton: {
    paddingTop: 14,
  },

  title: {
    fontFamily: Base.fonts.appTitle,
    color: Base.colours.appWhite,
    textAlign: "center",
    fontSize: 80,
    lineHeight: 70,
    marginBottom: 20,
    marginTop: 50,
    paddingHorizontal: 14,
    "@media (max-width: 960px)": {
      fontSize: 55,
      lineHeight: 53,
      marginBottom: 10,
    },
  },

  subtitle: {
    fontFamily: Base.fonts.appMedium,
    color: Base.colours.appWhite,
    textAlign: "center",
    fontSize: 20,
    lineHeight: 23,
    marginBottom: 22,
    paddingHorizontal: 14,
    "@media (max-width: 960px)": {
      fontSize: 16,
      marginBottom: 12,
    },
  },

  options: {
    paddingHorizontal: 14,
    maxWidth: 700,
    width: "100%",
    "@media (max-width: 960px)": {
      maxWidth: "none",
    },
  },

  footer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
  },

  button: {
    height: 70,
    paddingHorizontal: 40,
    "@media (max-width: 960px)": {
      height: 55,
      paddingHorizontal: 20,
    },
  },

  buttonTitle: {
    color: Base.colours.appWhite,
    fontSize: 44,
    "@media (max-width: 960px)": {
      fontSize: 35,
    },
  },
})
