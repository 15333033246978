import {
  NFC_INTRO_SHOWN,
  RESET_PROFILE,
  SET_CONFIG,
  SET_CURRENT_LOCATION,
  SET_DEFAULT_JOURNEY,
  SET_EXPLORE_MORE_QUESTIONS,
  SET_EXPLORE_QS,
  SET_EXPLORE_RESULTS,
  SET_MYSTERY_CAMPAIGNS,
  SET_ONBOARDING_SHOWN,
  SET_PROFILE,
  SET_START_JOURNEY,
  SET_SUBS_GIFT_CARD,
  SET_TRANSACTIONS,
  SET_VENUES,
  SET_WINES,
} from "./Actions"

const initialState = {
  profile: {},
  nfcIntroShown: false,
  exploreQuestions: [],
  exploreResults: [],
  exploreMoreQuestions: false,
  venues: [],
  wines: [],
  config: {},
  transactions: [],
  onboardingShown: false,
  mysteryCampaigns: [],
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE: {
      const profile = action?.profile ? { ...action.profile } : {}

      profile.firstName = profile.firstName ?? profile.displayName ?? ""
      profile.lastName = profile.lastName ?? ""

      return { ...state, profile }
    }
    case NFC_INTRO_SHOWN:
      return { ...state, nfcIntroShown: action.shown }
    case SET_EXPLORE_QS:
      return { ...state, exploreQuestions: action.questions }
    case SET_EXPLORE_RESULTS:
      return { ...state, exploreResults: action.results }
    case SET_EXPLORE_MORE_QUESTIONS:
      return { ...state, exploreMoreQuestions: action.more }
    case SET_VENUES:
      return { ...state, venues: action.venues }
    case SET_WINES:
      return { ...state, wines: action.wines }
    case SET_CONFIG:
      return { ...state, config: action.config }
    case SET_CURRENT_LOCATION:
      return { ...state, location: action.location }
    case SET_TRANSACTIONS:
      return { ...state, transactions: action.transactions }
    case RESET_PROFILE:
      return {
        ...initialState,
        wines: state.wines,
        venues: state.venues,
        onboardingShown: state.onboardingShown,
      }
    case SET_ONBOARDING_SHOWN: {
      return { ...state, onboardingShown: !!action.shown }
    }
    case SET_SUBS_GIFT_CARD:
      return {
        ...state,
        giftCard: action.giftCard,
      }
    case SET_DEFAULT_JOURNEY:
      return {
        ...state,
        defaultJourney: action.journey,
      }
    case SET_START_JOURNEY:
      return {
        ...state,
        startJourney: action.journey,
      }
    case SET_MYSTERY_CAMPAIGNS:
      return {
        ...state,
        mysteryCampaigns: action.campaigns,
      }
    default:
      return state
  }
}
