import React from "react"
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native"
import Icon from "react-native-vector-icons/AntDesign"

export const colours = {
  appBlue: "#0899aa",
  appDarkBlue: "#084c61",
  appLightRed: "#F9423A77",
  appSuperLightRed: "#FFF0EF",
  appRed: "#F9423A",
  appTranslucentWhite: "#ffffff25",
  appDarkRed: "#e93323",
  appGreen: "#419d78",
  appBrown: "#453536", // rgb 69 53 54
  appLightBrown: "#6A5D5E", // #blackgrape80
  appDarkBrown: "#362929",
  appCream: "#efe6d1",
  appOrange: "#f9423a", // rgb 249 66 58
  appLightOrange: "#f9423a16",
  appLightOrangeLessTranslucent: "#f9423a77",
  appVeryDarkGrey: "#1a1a1a",
  appDarkGrey: "#606060",
  appGrey: "#5b5b5b",
  appSemiLightGrey: "#8d8d8d",
  appLightGrey: "#dad7d7", // rgb 218 215 215
  appVeryLightGrey: "#eceaea", // rgb 236 234 234
  appSuperLightGrey: "#f7f7f7",
  appBlack: "#121212",
  appWhite: "white",
  appPlaceholder: "#8f8686",
  facebookBlue: "#4267B2",
  appPink: "#C53850",
}

export const fonts = {
  appTitle: "hackney-vector",
  appCurly: "northwell",
  appBold: "gilroy-bold",
  appMedium: "gilroy-medium",
  appRegular: "gilroy-regular",
  appReceipt: "roboto",
}

export const questionTypes = {
  singleChoice: "single_choice",
  multipleChoice: "multiple_choice",
  positiveNegative: "positive_negative",
  slider: "slider",
}

const styles = StyleSheet.create({
  title: {
    fontSize: Platform.OS === "web" ? 55 : 35,
    textAlign: Platform.OS === "web" ? "center" : "left",
    color: colours.appBlack,
    fontFamily: fonts.appTitle,
  },

  subtitle: {
    fontFamily: fonts.appRegular,
    fontSize: 16,
    lineHeight: 24,
    textAlign: Platform.OS === "web" ? "center" : "left",
    color: colours.appBrown,
  },

  body: {
    fontFamily: fonts.appRegular,
    color: colours.appLightGrey,
    textAlign: "center",
    lineHeight: 20,
    fontSize: 16,
  },

  main: {
    backgroundColor: colours.appBlack,
  },

  headerButton: {
    marginLeft: 16,
    marginRight: 8,
    minWidth: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },

  headerTextButton: {
    fontFamily: fonts.appRegular,
    color: "white",
    fontSize: 14,
  },
})

function defaultNavOptions(nav, modal = true, onBackPressed = null) {
  return {
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: colours.appBrown,
      height: Platform.OS === "web" ? 100 : 116,
      shadowColor: "transparent",
      elevation: 0,
      shadowOpacity: 0,
      borderBottomWidth: 0,
    },
    headerTintColor: "white",
    headerTitle: "",
    headerTitleStyle: {
      fontFamily: fonts.appTitle,
      fontSize: 38,
      marginBottom: 3,
    },
    headerForceInset: { top: "never", bottom: "never" },

    cardStyle: {
      backgroundColor: colours.appBrown,
      flex: 1,
    },
    headerLeft: () => {
      return (
        <TouchableOpacity
          style={styles.headerButton}
          onPress={() => {
            onBackPressed ? onBackPressed() : nav.pop()
          }}
        >
          <Icon
            name={modal ? "close" : "arrowleft"}
            size={31}
            color={colours.appRed}
          />
        </TouchableOpacity>
      )
    },
    headerRight: null,
  }
}

function lightNavOptions(nav, modal = false) {
  let options = defaultNavOptions(nav, modal)
  options.headerStyle.backgroundColor = colours.appGrey
  return options
}

export default {
  window: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  mobile: { maxWidth: 767 },
  smallPhone: { maxWidth: 350 },
  tablet: { minWidth: 768, maxWidth: 1023 },
  colours,
  fonts,
  questionTypes,
  defaultNavOptions,
  lightNavOptions,
  styles,
}

export const POSITIVE_RATE = "positive"
export const NEGATIVE_RATE = "negative"
export const NEUTRAL_RATE = "neutral"
export const CAMPAIGN_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  ANSWERED: "ANSWERED",
  COMPLETED: "COMPLETED",
  CLAIMED: "CLAIMED",
}
