import SimplerDatePicker from "@cawfree/react-native-simpler-date-picker"
import moment from "moment"
import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"

import Base from "../../base"
import AppButton from "./AppButton"
import Gap from "./Gap"

export default function DatePicker(props) {
  return (
    <TouchableOpacity style={styles.datePicker}>
      <View style={styles.datePickerModal}>
        <Text style={styles.dobTitle}>{props.title}</Text>
        <View style={styles.dobContainer}>
          <SimplerDatePicker
            minDate={props.minDate && moment(props.minDate)}
            maxDate={props.maxDate && moment(props.maxDate)}
            onDatePicked={date => props.onDateChanged(1, date)}
          />
        </View>
        <Gap size={20} />
        <AppButton
          style={styles.button}
          title={"Save"}
          onPress={props.onClose}
        />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  datePicker: {
    elevation: 100,
    zIndex: 100,
    marginTop: -50,
    position: "absolute",
    width: Base.constants.window.width,
    height: Base.constants.window.height + 60,
    backgroundColor: "#000000cc",
    justifyContent: "center",
    alignItems: "center",
  },

  datePickerModal: {
    maxWidth: 500,
    width: "95%",
    backgroundColor: "white",
    marginBottom: 160,
    paddingVertical: 30,
  },

  dobTitle: {
    width: "100%",
    marginVertical: 22,
    fontFamily: Base.fonts.appRegular,
    textAlign: "center",
    fontSize: 18,
  },

  dobContainer: {
    marginHorizontal: 10,
  },
  button: { margin: 12, alignSelf: "center" },
})
