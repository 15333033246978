/** How many wines must be exist in the subscription box. */
export const SUBSCRIPTION_BOX_WINES_LIMIT = 5

/** What the basic price of each wine in subscription box. */
export const SUBSCRIPTION_BASIC_WINE_RRP = 5

/** Screens */
export const SUBSCRIPTION_SCREENS = {
  MAIN: "subscriptions",
  FAQ: "subscriptions-faq",
  PRE_SURVEY: "subscription-pre-survey",
  PRE_SURVEY_EMAIL: "subscription-pre-survey-email",
  SURVEY: "subscription-survey",
  SURVEY_SUMMARY: "subscription-survey-summary",
  SUGGESTED_WINES: "subscription-suggested-wines",
  SELECT_WINES: "select-subscription-wines",
  MY_SUBSCRIPTION: "my-subscription",
  UPCOMING_SUBSCRIPTION_BOX: "upcoming-subscription-box",
  PAST_BOXES: "past-subscription-boxes",
  WINE_PROFILE: "subscription-wine-profile",
  MANAGE_SUBSCRIPTION: "manage-subscription",
  CANCEL_SUBSCRIPTION: "cancel-subscription",
  CANCEL_SUBSCRIPTION_REASON: "cancel-subscription-reason",
  CANCEL_SUBSCRIPTION_SUCCESS: "cancel-subscription-success",
  PAUSE_SUBSCRIPTION: "pause-subscription",
  PAUSE_SUBSCRIPTION_SUCCESS: "pause-subscription-success",
  CHOOSE_DELIVERY_FREQUENCY: "choose-subscription-delivery-frequency",
  CHOOSE_DELIVERY_TYPE: "choose-subscription-delivery-type",
  DELIVERY_FORM: "subscription-delivery-form",
  DELIVERY_DATE: "subscription-delivery-date",
  PICK_UP_LOCATION: "subscription-pick-up-location",
  PICK_UP_DATE: "subscription-pick-up-date",
  DELIVERY_SUMMARY: "subscription-delivery-summary",
  PAYMENT: "subscription-payment",
  TERMS: "subscription-terms-and-conditions",
  LOGIN_JOURNEY: "subscription-login-journey",
}

/** List of all statuses which can have a subscription box. */
export const SUBSCRIPTION_BOX_STATUSES = {
  OPENED: "opened",
  FULFILLED: "fulfilled",
  FAILED: "failed",
  CANCELED: "canceled",
  LOCKED: "locked",
}

/** List of all statuses which can have a subscription. */
export const SUBSCRIPTION_STATUSES = {
  DRAFT: "draft",
  ACTIVE: "active",
  CANCELED: "canceled",
  PAUSED: "paused",
}

/** Subscription cancellation reason which implies to propose input of custom reason by user. */
export const CUSTOM_SUBSCRIPTION_CANCELLATION_REASONS = "Other"

/** Predefined list of reasons to propose a subscriber when they want to cancel subscription. */
export const SUBSCRIPTION_CANCELLATION_REASONS = [
  "I didn’t enjoy the wines I tried",
  "The boxes didn’t feel like good value for money",
  "The boxes came too regularly",
  "The boxes came too infrequently",
  "It doesn’t fit my lifestyle",
  "Poor customer service",
  "I’m going to be away",
  CUSTOM_SUBSCRIPTION_CANCELLATION_REASONS,
]
