import React, { useMemo } from "react"
import { StyleSheet, Text, View } from "react-native"

import Base from "../../base"

export default function TastingNotesScale(props) {
  const dots = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  const limits = useMemo(
    () => ({
      "Wine (Red)": ["Earthy", "Fruity"],
      "Wine (White)": ["Dry", "Sweet"],
      "Wine (Sparkling)": ["Dry", "Sweet"],
      "Wine (Rose)": ["Dry", "Sweet"],
      "Wine (Orange)": ["Clean", "Fruity"],
      "Wine (Sweet & Fortified)": ["Dry", "Treacle"],
    }),
    [],
  )

  const index = useMemo(() => {
    const scale = props.wine?.scale?.length
      ? props.wine?.scale.replace(/[^0-9]/g, "")
      : "3"
    // eslint-disable-next-line radix
    const scaleInt = parseInt(scale)
    const _index = scaleInt * 2 - 2
    return _index
  }, [props.wine])

  const limit = useMemo(() => {
    return limits[props.wine?.type] ?? limits["Wine (Red)"]
  }, [limits, props.wine])

  return (
    <View style={[styles.main, props.style]}>
      <Text style={styles.title}>{limit[0]}</Text>
      <View style={styles.scale}>
        {dots.map(dot => {
          return (
            <View
              style={[
                styles.dot,
                dot === index && {
                  backgroundColor: Base.colours.appOrange,
                  borderColor: Base.colours.appOrange,
                },
              ]}
              key={dot}
            />
          )
        })}
      </View>
      <Text style={styles.title}>{limit[1]}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    paddingVertical: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },

  title: {
    fontFamily: Base.fonts.appRegular,
    fontSize: 14,
    color: Base.colours.appBrown,
  },

  scale: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    height: "100%",
    marginHorizontal: 24,
  },

  dot: {
    width: 9,
    height: 9,
    borderRadius: 4.5,
    borderWidth: 1,
    borderColor: Base.colours.appBrown,
  },
})
