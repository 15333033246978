import React from "react"
import { Image } from "react-native"

const AutoDimensionImage = React.memo(({ width, height, style, ...props }) => {
  const [dim, setDim] = React.useState(1)
  return (
    <Image
      {...props}
      style={{
        width: width ? width : dim,
        height: height ? height : dim,
        ...style,
        resizeMode: "contain",
      }}
      onLoad={({ nativeEvent }) => {
        if (!nativeEvent?.source) {
          setDim((width || height) * 1.9)
          return
        }
        setDim(
          width
            ? (nativeEvent?.source?.height / nativeEvent?.source?.width) * width
            : (nativeEvent?.source?.width / nativeEvent?.source?.height) *
                height,
        )
      }}
    />
  )
})

export default AutoDimensionImage
