import React, { useLayoutEffect } from "react"
import { Platform, Text, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview"
import StyleSheet from "react-native-media-query"

import Base from "../../base"
import { ImageBG } from "../common"

const navOptions = props => {
  return {
    ...Base.constants.defaultNavOptions(
      props.navigation,
      !props.showBack,
      props.onBackPress,
    ),
    headerTitle: props.pageTitle,
  }
}

export default function SUBaseScreen(props) {
  Base.useNavBarColour(Base.colours.appSemiLightGrey)

  useLayoutEffect(() => {
    props.navigation?.setOptions({
      ...navOptions(props),
      cardStyle: {
        backgroundColor: Base.colours.appWhite,
        flex: 1,
      },
    })
  }, [props])

  return (
    <View style={styles.main}>
      <ImageBG bgID={10} hide={props.bgHide}>
        <KeyboardAwareScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[styles.scrollContainer, props.style]}
          alwaysBounceVertical={false}
        >
          <View style={styles.content} dataSet={{ media: ids.content }}>
            {!!props.title && (
              <Text style={[Base.styles.subtitle, styles.title]}>
                {props.title}
              </Text>
            )}
            {props.children}
          </View>
        </KeyboardAwareScrollView>
      </ImageBG>
    </View>
  )
}

const { ids, styles } = StyleSheet.create({
  main: {
    flex: 1,
  },

  content: {
    paddingHorizontal: 24,
    paddingVertical: 32,
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 930px)": {
      justifyContent: "flex-start",
    },
  },

  title: {
    width: "100%",
    marginBottom: 30,
    textAlign: Platform.OS === "web" ? "center" : "left",
  },

  scrollContainer: { flexGrow: 1 },
})
